import {
  FC,
  useEffect,
  useRef,
  useState,
  DragEvent,
  SyntheticEvent,
} from "react";
import { useForm } from "react-hook-form";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Input,
  Spacer,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Content, ContentType } from "api/model";
import { useProjectChapterId } from "state/localState";
import {
  useMutationCreateContent,
  useMutationUpdateContent,
} from "state/remoteState";
import { IoMusicalNotesSharp } from "react-icons/io5";
import { CreateOrUpdateContentProps } from "../DialogCreateOrUpdateContent";
import { translateText } from "../../../../../../../layout/translatePage";
import { useTranslation } from "../../../../../../../layout/TranslationContext";

type FormData = {
  name?: string;
};

export const TabAudioUpload: FC<CreateOrUpdateContentProps> = (props) => {
  const { projectId, chapterId } = useProjectChapterId();
  const [file, setFile] = useState<File | undefined>(props.file);
  const [dragActive, setDragActive] = useState<boolean>(false);
  const mutationCreateContent = useMutationCreateContent();
  const mutationUpdateContent = useMutationUpdateContent();
  const toast = useToast();
  const { language } = useTranslation();
  const content = props.content ?? ({ type: ContentType.Audio } as Content);
  const mode = props.content ? "update" : "create";

  const {
    register,
    watch,
    formState: { isDirty },
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      name: content.name ?? "",
    },
  });
  const formData = watch();

  const [previewURL, setPreviewURL] = useState<string | undefined>();

  useEffect(() => {
    if (!file) {
      setPreviewURL(content.sources?.main);
      return;
    }
    const url = URL.createObjectURL(file);
    setPreviewURL(url);
    return () => {
      URL.revokeObjectURL(url);
    };
  }, [file, content.sources?.main]);

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleFileUpload = () => {
    hiddenFileInput.current?.click();
  };

  const checkAndSetFile = (f: File | null | undefined) => {
    if (!f) return;
    if (!f.type.startsWith("audio/")) {
      toast({
        status: "error",
        title: "Unsupported file format",
        description: "Please upload an audio file in MP3 format.",
      });
      return;
    }
    setFile(f);
  };

  const handleFileSelected = () => {
    const f = hiddenFileInput.current?.files?.item(0);
    checkAndSetFile(f);
  };

  const handleDrop = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const f = e.dataTransfer.files.item(0);
    checkAndSetFile(f);
  };

  const handleDrag = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(e.type === "dragenter" || e.type === "dragover");
  };

  const showTranslatedToast = async (message: string) => {
    const translatedMessage = await translateText(message, language);
    toast({
      title: translatedMessage,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const handleCreateContent = () => {
    if (!file) return;
    const newContent: Content = {
      name: formData.name,
      description: file.name,
      type: ContentType.Audio,
    };
    mutationCreateContent.mutate(
      { projectId, chapterId, content: newContent, file },
      {
        onSuccess: () => {
          showTranslatedToast(
            `Audio content "${newContent.name ?? "unnamed"}" has been added`,
          );
          props.onSuccess();
        },
        onError: (error) => {
          toast({
            status: "error",
            title: "Error adding content",
            description:
              error.message || "An error occurred while adding the audio.",
          });
        },
      },
    );
  };

  const handleUpdateContent = () => {
    if (!content) {
      throw new Error("Content is not defined");
    }
    content.name = formData.name;
    if (file) {
      content.description = file.name;
    }
    mutationUpdateContent.mutate(
      {
        projectId,
        chapterId,
        contentId: content.id!,
        content,
        file,
      },
      {
        onSuccess: () => {
          showTranslatedToast(
            `Audio content "${content.name ?? "unnamed"}" has been updated`,
          );
          props.onSuccess();
        },
        onError: (error) => {
          toast({
            status: "error",
            title: "Error updating content",
            description:
              error.message || "An error occurred while updating the audio.",
          });
        },
      },
    );
  };

  return (
    <>
      <Grid templateColumns="1fr 1fr" gap="2" minHeight="250px">
        <GridItem>
          <Button
            position="relative"
            onClick={handleFileUpload}
            width="100%"
            height="100%"
            backgroundColor="primary.0"
            borderRadius="6"
            display="flex"
            alignItems="center"
            justifyContent="center"
            _hover={{ backgroundColor: "primary.0" }}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            <Flex direction="column" align="center">
              {!dragActive ? (
                previewURL ? (
                  <Box position="relative" maxHeight="250px" overflow="hidden">
                    <AudioPreview src={previewURL} />
                    <Text pt="6" color="primary.3">
                      Drag & Drop or click here
                    </Text>
                  </Box>
                ) : (
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      backgroundColor="primary.1"
                      w="50px"
                      h="50px"
                      borderRadius="50"
                    >
                      <Icon
                        as={IoMusicalNotesSharp}
                        color="primary.6"
                        boxSize="6"
                      />
                    </Box>
                    <Text color="primary.6" pt="6" pb="2" fontWeight="700">
                      Upload Audio
                    </Text>
                    <Text color="primary.4">Supports: MP3</Text>
                    <Text color="primary.3" pt="4">
                      Drag & Drop or click here
                    </Text>
                  </>
                )
              ) : (
                <Text color="primary.6">Drop here</Text>
              )}
            </Flex>
          </Button>
          <input
            type="file"
            ref={hiddenFileInput}
            style={{ display: "none" }}
            onChange={handleFileSelected}
            accept="audio/mpeg"
          />
        </GridItem>

        <Flex direction="column" gap="4" pl="2">
          <Alert
            status="info"
            color="secondary.2"
            backgroundColor="rgba(77, 136, 140, 0.05)"
            border="1px solid #4D888C"
            borderRadius="8"
          >
            <AlertIcon color="secondary.2" />
            <Text fontWeight="500" fontSize="xs">
              You can add a MP3 audio file here.
            </Text>
          </Alert>

          <Flex align="center" gap="2">
            <Text fontSize="sm" fontWeight="600" color="primary.4" mr="2">
              Content Name
            </Text>
            <Input
              placeholder="Name"
              borderRadius="32"
              size="sm"
              {...register("name")}
            />
          </Flex>
        </Flex>
      </Grid>

      <Flex width="100%" justifyContent="flex-end">
        <Spacer />
        {mode === "create" && (
          <Button
            isDisabled={!file}
            _hover={{ backgroundColor: "primary.3" }}
            variant="primary"
            mt="4"
            onClick={handleSubmit(handleCreateContent)}
            isLoading={mutationCreateContent.isPending}
          >
            Add Content
          </Button>
        )}
        {mode === "update" && (
          <Button
            isDisabled={!file && !isDirty}
            _hover={{ backgroundColor: "primary.3" }}
            variant="primary"
            mt="4"
            onClick={handleSubmit(handleUpdateContent)}
            isLoading={mutationUpdateContent.isPending}
          >
            Update Content
          </Button>
        )}
      </Flex>
    </>
  );
};

// AudioPreview Component
interface AudioPreviewProps {
  src: string;
}

const AudioPreview: FC<AudioPreviewProps> = ({ src }) => {
  return <audio src={src} controls />;
};
