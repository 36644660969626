import { Content, ContentType } from "api/model";
import { useQueryChapter } from "state/remoteState";
import { atom, useRecoilState } from "recoil";
import { useParams } from "react-router-dom";
import { useMemo } from "react";

const stateActiveAnchorId = atom({
  key: "activeAnchorId",
  default: "",
});

const stateTransformControlMode = atom<
  "translate" | "rotate" | "scale" | undefined
>({
  key: "transformControlMode",
  default: undefined,
});

export const useStateActiveAnchor = () => {
  const { projectId, chapterId } = useProjectChapterId();
  const { data: chapter } = useQueryChapter(projectId, chapterId);
  const [activeAnchorId, setActiveAnchorId] =
    useRecoilState(stateActiveAnchorId);
  const anchor =
    chapter?.anchors?.find((anchor) => anchor.id === activeAnchorId) ??
    chapter?.anchors?.at(0) ??
    null;
  return [anchor, setActiveAnchorId] as const;
};

// useStateActiveContent is used to get a content that can be displayed on the canvas.
export const useStateActiveContent = () => {
  const { projectId, chapterId } = useProjectChapterId();
  const { data: chapter } = useQueryChapter(projectId, chapterId);

  const activeContent = useMemo(() => {
    return chapter?.content?.find((content: Content) => {
      return [
        ContentType.Image,
        ContentType.Value3DModel,
        ContentType.Video,
        ContentType.Youtube,
        ContentType.Vimeo,
        ContentType.UrlEndpoint,
        ContentType.EditorJs,
      ].includes(content.type as ContentType);
    });
  }, [chapter?.content]);

  return activeContent;
};

export const useStateTransformControlMode = () => {
  const [transformControl, setTransformControl] = useRecoilState(
    stateTransformControlMode,
  );
  return [transformControl, setTransformControl] as const;
};

export const useProjectChapterId = () => {
  const { id: projectId, chapterId } = useParams();
  if (!projectId || !chapterId) {
    throw new Error("projectId (id) or chapterId are not set in the URL.");
  }
  return { projectId, chapterId };
};

export const useProjectId = () => {
  const { id: projectId } = useParams();
  if (!projectId) {
    throw new Error("projectId (id) is not set in the URL.");
  }
  return projectId;
};
