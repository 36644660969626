import { FC, useEffect } from "react";
import {
  ClampToEdgeWrapping,
  DoubleSide,
  LinearFilter,
  TextureLoader,
} from "three";
import { useLoader } from "@react-three/fiber";
import { Anchor, AnchorType } from "api/model";
import { RiMapPinLine } from "react-icons/ri";
import { Html } from "@react-three/drei";

interface Props {
  marker: Anchor;
}

export const Marker: FC<Props> = ({ marker }) => {
  switch (marker.type) {
    case AnchorType.Image:
      return <ImageMarker marker={marker} />;
    case AnchorType.Environment:
      return <SpatialMarker />;
    default:
      throw new Error("Unsupported marker type");
  }
};

const ImageMarker: FC<Props> = ({ marker }) => {
  const texture = useLoader(TextureLoader, marker.sources!.main);

  useEffect(() => {
    if (!texture) return;
    texture.generateMipmaps = false;
    texture.wrapS = texture.wrapT = ClampToEdgeWrapping;
    texture.minFilter = LinearFilter;
    texture.needsUpdate = true;
  }, [
    texture.generateMipmaps,
    texture.wrapS,
    texture.wrapT,
    texture.minFilter,
    texture.needsUpdate,
  ]);

  return (
    <mesh>
      <planeGeometry attach="geometry" />
      <meshBasicMaterial
        attach="material"
        map={texture}
        transparent={true}
        side={DoubleSide}
      />
    </mesh>
  );
};

const SpatialMarker = () => {
  return (
    <mesh>
      <Html transform distanceFactor={1.33} zIndexRange={[10, 10]}>
        <div style={{ paddingBottom: 300 }}>
          <RiMapPinLine size={300} />
        </div>
      </Html>
    </mesh>
  );
};
