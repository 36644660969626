import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Input,
  Spacer,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { BiSidebar } from "react-icons/bi";
import { AnchorType, Content, ContentType } from "api/model";
import { useProjectChapterId } from "state/localState";
import {
  useMutationCreateContent,
  useMutationUpdateContent,
  useMutationCreateQRCodeForChapter,
  useQueryProject,
} from "state/remoteState";
import { Configurations } from "api/Configurations";
import { ImagePreview, ImagePreviewProps } from "../Preview";
import { CreateOrUpdateContentProps } from "../DialogCreateOrUpdateContent";
import { useDebouncedCallback } from "use-debounce";
import axios from "../../../../../../../api/axios";
import { translateText } from "../../../../../../../layout/translatePage";
import { useTranslation } from "../../../../../../../layout/TranslationContext";

const IMAGE_TYPE = "png";
const IMAGE_MIME_TYPE = "image/png";

type FormData = {
  name?: string;
  url: string;
};

export const TabURL: FC<CreateOrUpdateContentProps & { tabIndex: number }> = (
  props,
) => {
  const { tabIndex } = props;
  const { projectId, chapterId } = useProjectChapterId();
  const project = useQueryProject(projectId).data;
  const mutationCreateContent = useMutationCreateContent();
  const mutationUpdateContent = useMutationUpdateContent();
  const mutationCreateQRCodeForChapter = useMutationCreateQRCodeForChapter();
  const toast = useToast();

  const [previewImage, setPreviewImage] = useState<File>();
  const [previewImageURL, setPreviewImageURL] = useState<string>();
  const [isImageLoading, setImageLoading] = useState<boolean>(false);
  const [isImageLoadingError, setImageLoadingError] = useState<boolean>(false);
  const [configurationsFromPreview, setConfigurationsFromPreview] =
    useState<Configurations>({});

  const content =
    props.content ?? ({ type: ContentType.UrlEndpoint } as Content);
  const mode = props.content ? "update" : "create";

  // Form
  const { register, watch, formState, handleSubmit } = useForm<FormData>({
    defaultValues: {
      name: content?.name ?? "",
      url: content?.description ?? "",
    },
    mode: "onChange",
  });
  const formData = watch();
  const { language } = useTranslation();

  const showTranslatedToast = async (title: string, message: string) => {
    const translatedTitle = await translateText(title, language);
    const translatedMessage = await translateText(message, language);
    toast({
      title: translatedTitle,
      description: translatedMessage,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  useEffect(() => {
    // Clean up when a new preview image is set
    if (previewImageURL) {
      URL.revokeObjectURL(previewImageURL);
    }
    if (previewImage) {
      const url = URL.createObjectURL(previewImage);
      setPreviewImageURL(url);
      return () => {
        URL.revokeObjectURL(url);
      };
    } else {
      setPreviewImageURL(undefined);
    }
  }, [previewImage]);

  const makeWebsiteSnapshot = useDebouncedCallback(async (url: string) => {
    if (!url.match(/^(https?:\/\/)?[\w.-]+(\.[\w.-]+)+[/#?]?.*$/)) {
      return;
    }
    setImageLoadingError(false);
    setImageLoading(true);
    try {
      const urlWithSchema = encodeURI(
        url.startsWith("http") ? url : `https://${url}`,
      );

      const response = await axios.get(
        `/resources/WebScreenshot/?url=${urlWithSchema}`,
        {
          responseType: "blob",
        },
      );

      if (response.status !== 200) {
        throw new Error("Network response was not ok");
      }

      const blob = response.data;
      const file = new File([blob], `preview.${IMAGE_TYPE}`, {
        type: IMAGE_MIME_TYPE,
      });
      setPreviewImage(file);
    } catch (error) {
      console.error("Error fetching image:", error);
      setImageLoadingError(true);
    } finally {
      setImageLoading(false);
    }
  }, 1000);

  // Load preview image
  useEffect(() => {
    if (!formData.url) {
      return;
    }
    makeWebsiteSnapshot(formData.url);
  }, [formData.url, makeWebsiteSnapshot]);

  const handleCreateContent = async (data: FormData) => {
    if (!formData.url.match(/^(https?:\/\/)?[\w.-]+(\.[\w.-]+)+[/#?]?.*$/)) {
      toast({
        status: "error",
        title: "Invalid URL",
        description: "Please provide a valid URL.",
      });
      return;
    }

    const newContent: Content = {
      name: data.name ?? "Untitled Content",
      description: data.url,
      configurations: configurationsFromPreview,
      type: ContentType.UrlEndpoint,
    };
    mutationCreateContent.mutate(
      {
        projectId,
        chapterId,
        content: newContent,
        file: previewImage,
      },
      {
        onSuccess: () => {
          if (project?.anchorType === AnchorType.QrCode) {
            mutationCreateQRCodeForChapter.mutate({
              projectId,
              chapterId,
            });
          }
          showTranslatedToast(
            "Content added",
            `Website content "${data.name || "Untitled Content"}" has been added`,
          );
          props.onSuccess();
        },
        onError: (error) => {
          console.error(error);
          toast({
            status: "error",
            title: "Error adding content",
            description:
              error.message || "An error occurred while adding the content.",
          });
        },
      },
    );
  };

  const handleUpdateContent = async (data: FormData) => {
    if (!content || !content.id) {
      throw new Error("Content is not defined");
    }
    const updatedContent: Content = {
      name: data.name ?? "Untitled Content",
      description: data.url,
      configurations: configurationsFromPreview,
      type: ContentType.UrlEndpoint,
    };

    mutationUpdateContent.mutate(
      {
        projectId,
        chapterId,
        contentId: content.id,
        content: updatedContent,
        file: previewImage,
      },
      {
        onSuccess: () => {
          showTranslatedToast(
            "Content updated",
            `Website "${data.name || "Untitled Content"}" has been updated`,
          );
          props.onSuccess();
        },
        onError: (error) => {
          console.error(error);
          showTranslatedToast(
            "Content update failed",
            "Website could not be updated",
          );
        },
      },
    );
  };

  return (
    <>
      <Grid templateColumns="1fr 1fr" gap="2" minHeight="250px">
        <GridItem>
          <Button
            position="relative"
            width="100%"
            height="100%"
            backgroundColor="primary.0"
            borderRadius="6"
            display="flex"
            alignItems="center"
            justifyContent="center"
            _hover={{ backgroundColor: "primary.0" }}
          >
            <Flex direction="column" align="center">
              <URLPreview
                src={previewImageURL ?? content?.sources?.main ?? ""}
                isLoading={isImageLoading}
                isError={isImageLoadingError}
                setConfigurations={setConfigurationsFromPreview}
              />
            </Flex>
          </Button>
        </GridItem>
        <Flex direction="column" gap="4" pl="2">
          <Flex align="center" gap="2">
            <Text fontSize="sm" fontWeight="600" color="primary.4" mr="2">
              Content&nbsp;Name
            </Text>
            <Input
              placeholder="Name"
              borderRadius="32"
              size="sm"
              {...register("name")}
            />
          </Flex>
          <Flex align="center" gap="2">
            <Text fontSize="sm" fontWeight="600" color="primary.4" mr="2">
              URL
            </Text>
            <Input
              placeholder="Website URL"
              borderRadius="32"
              size="sm"
              {...register("url", {
                required: true,
                pattern: {
                  value: /^(https?:\/\/)?[\w.-]+(\.[\w.-]+)+[/#?]?.*$/,
                  message: "Invalid URL",
                },
              })}
            />
          </Flex>
        </Flex>
      </Grid>
      <Flex width="100%" justifyContent="flex-end">
        <Spacer />
        {mode === "create" && (
          <Button
            isLoading={mutationCreateContent.isPending}
            isDisabled={
              !formState.isValid || isImageLoading || isImageLoadingError
            }
            _hover={{ backgroundColor: "primary.3" }}
            variant="primary"
            mt="4"
            onClick={handleSubmit(handleCreateContent)}
          >
            Add Content
          </Button>
        )}
        {mode === "update" && (
          <Button
            isLoading={mutationUpdateContent.isPending}
            isDisabled={
              !formState.isValid ||
              isImageLoading ||
              isImageLoadingError ||
              !formState.isDirty
            }
            _hover={{ backgroundColor: "primary.3" }}
            variant="primary"
            mt="4"
            onClick={handleSubmit(handleUpdateContent)}
          >
            Update Content
          </Button>
        )}
      </Flex>
    </>
  );
};

interface URLPreviewProps extends ImagePreviewProps {
  isLoading?: boolean;
  isError?: boolean;
}

const URLPreview: FC<URLPreviewProps> = ({
  src,
  isLoading,
  isError,
  setConfigurations,
}) => {
  if (isLoading) {
    return <Spinner color="black" />;
  }

  if (!isLoading && !isError && src !== "") {
    return <ImagePreview src={src} setConfigurations={setConfigurations} />;
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor="primary.1"
        w="50px"
        h="50px"
        borderRadius="50"
      >
        <Icon as={BiSidebar} color="primary.6" boxSize="6" />
      </Box>
      {isError && (
        <Text color="primary.6" mt="1">
          Couldn&apos;t load the Website preview.
          <br />
          Check that the URL is correct.
        </Text>
      )}
      <Text color="primary.6" pt="6">
        Website preview
      </Text>
    </>
  );
};
