import React, { useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Grid,
  Card,
  CardBody,
  Image,
  Flex,
  Text,
  Heading,
  Icon,
} from "@chakra-ui/react";
import { IoQrCode } from "react-icons/io5";
import { BsImage } from "react-icons/bs";
import { AnchorType } from "api/model";
import { translatePage } from "../../../layout/translatePage";
import { useTranslation } from "../../../layout/TranslationContext";

interface ProjectTypeModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleCreateProject: (anchorType: AnchorType) => void;
}

export const ProjectTypeModal: React.FC<ProjectTypeModalProps> = ({
  isOpen,
  onClose,
  handleCreateProject,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const { language } = useTranslation();

  useEffect(() => {
    if (isOpen && modalRef.current) {
      translatePage(modalRef.current, language);
    }
  }, [isOpen, language]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={modalRef}
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent ref={modalRef} p="14" borderRadius="30">
        <ModalHeader p="0" mb="6">
          <Heading color="black">Select project type</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="0">
          <Stack gap="6">
            <Grid
              templateColumns="repeat(auto-fit, minmax(200px,1fr))"
              gap="10"
            >
              <Card
                maxW="sm"
                _hover={{
                  cursor: "pointer",
                  transform: "scale(1.05)",
                  transition: "transform .2s ease-in-out",
                  zIndex: "9999",
                }}
                borderRadius="8"
                onClick={() => handleCreateProject(AnchorType.QrCode)}
              >
                <CardBody p="0">
                  <Image
                    src="./img/ProjectType_qr.png"
                    alt="QR Code Anchors"
                    borderTopLeftRadius="8"
                    borderTopRightRadius="8"
                    loading={"eager"}
                  />
                  <Stack p="4">
                    <Flex align="center" gap="2">
                      <Icon as={IoQrCode} />
                      <Text
                        fontSize="md"
                        fontWeight="semibold"
                        color="black"
                        lineHeight="6"
                        data-nontranslatable="Image Marker"
                      >
                        QR Code
                      </Text>
                    </Flex>
                    <Text
                      fontSize="xs"
                      fontWeight="medium"
                      color="primary.4"
                      lineHeight="4"
                    >
                      Create an On Screen Project with our Web Editor which is
                      triggered by a QR Code
                    </Text>
                  </Stack>
                </CardBody>
              </Card>
              <Card
                maxW="sm"
                _hover={{
                  cursor: "pointer",
                  transform: "scale(1.05)",
                  transition: "transform .2s ease-in-out",
                  zIndex: "9999",
                }}
                borderRadius="8"
                onClick={() => handleCreateProject(AnchorType.Image)}
              >
                <CardBody p="0">
                  <Image
                    src="./img/ProjectType_SpatialAnchors.png"
                    alt="Spatial Anchors"
                    borderTopLeftRadius="8"
                    borderTopRightRadius="8"
                  />
                  <Stack p="4">
                    <Flex align="center" gap="2">
                      <Icon as={BsImage} />
                      <Text
                        fontSize="md"
                        fontWeight="semibold"
                        color="black"
                        lineHeight="6"
                        data-nontranslatable="Image Marker"
                      >
                        Advanced Anchor Project
                      </Text>
                    </Flex>
                    <Text
                      fontSize="xs"
                      fontWeight="medium"
                      color="primary.4"
                      lineHeight="4"
                    >
                      Create an AR or On Screen Project which is triggered by
                      Image Markers or 3D Room Anchors
                    </Text>
                  </Stack>
                </CardBody>
              </Card>
            </Grid>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
