import React, { SyntheticEvent, useState, useEffect } from "react";
import {
  Flex,
  Text,
  Input,
  IconButton,
  Select,
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image,
  useToast,
} from "@chakra-ui/react";
import { FiMoreVertical, FiUpload } from "react-icons/fi";
import { MdOutlineEdit } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import {
  useCurrentChapter,
  useMutationCreateContent,
  useMutationUpdateContent,
  useMutationDeleteContent,
} from "state/remoteState";
import { useProjectChapterId } from "state/localState";
import { Content, ContentType } from "api/model";

export default function OnScreenBackgroundPicker() {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const chapter = useCurrentChapter().data;
  const { projectId, chapterId } = useProjectChapterId();
  const mutationCreateContent = useMutationCreateContent();
  const mutationUpdateContent = useMutationUpdateContent();
  const mutationDeleteContent = useMutationDeleteContent();
  const toast = useToast();

  const [color, setColor] = useState(
    chapter?.content?.find((content) => content.type === ContentType.Background)
      ?.configurations?.color || "#ffffff",
  );
  const [textColor, setTextColor] = useState(
    chapter?.content?.find((content) => content.type === ContentType.Background)
      ?.configurations?.textColor || "#000000",
  );

  const backgroundContent = chapter?.content?.find(
    (content) => content.type === ContentType.Background,
  );

  const hasBackgroundImage = Boolean(backgroundContent?.sourceObjectId);

  useEffect(() => {
    if (backgroundContent?.sourceObjectId) {
      const imageUrl = backgroundContent.sources?.main;
      if (imageUrl) {
        setPreviewUrl(imageUrl);
      } else {
        setPreviewUrl(null);
      }
    } else {
      setPreviewUrl(null);
    }

    return () => {
      if (previewUrl && previewUrl.startsWith("blob:")) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [backgroundContent, previewUrl]);

  const createOrUpdateBackgroundData = async (data: {
    color?: string;
    textColor?: string;
    alignment?: string;
    image?: File;
  }) => {
    if (!chapter) return;

    let content = backgroundContent;

    const newConfigurations = {
      ...(content?.configurations || {}),
      ...data,
    };

    try {
      if (content) {
        await mutationUpdateContent.mutateAsync({
          projectId,
          chapterId,
          contentId: content.id!,
          content: { ...content, configurations: newConfigurations },
          file: data.image,
        });
        toast({
          title: "Background updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        const newContent: Content = {
          type: ContentType.Background,
          configurations: newConfigurations,
        };
        await mutationCreateContent.mutateAsync({
          projectId,
          chapterId,
          content: newContent,
          file: data.image,
        });
        toast({
          title: "Background created successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : "Unknown error";
      console.error("Error creating/updating background:", error);
      toast({
        title: `Error: ${errorMessage}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleBackgroundColorChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setColor(event.target.value);
  };

  const handleBackgroundTextColorChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setTextColor(event.target.value);
    console.log("Background text color changed:", event.target.value);
  };

  const handleAlignmentChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    createOrUpdateBackgroundData({ alignment: event.target.value });
    console.log("Background alignment changed:", event.target.value);
  };

  const handleBackgroundImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const objectUrl = URL.createObjectURL(file);
      setPreviewUrl(objectUrl);
      await createOrUpdateBackgroundData({ image: file });
    } else {
      setPreviewUrl(null);
    }
    console.log("Background image changed:", event.target.files);
  };

  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDeleteContent = async (contentId: string) => {
    try {
      await mutationDeleteContent.mutateAsync({
        projectId: projectId!,
        chapterId: chapterId!,
        contentId: contentId,
      });
      setPreviewUrl(null);
      toast({
        title: "Background deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : "Unknown error";
      console.error("Error deleting background:", error);
      toast({
        title: `Error deleting background: ${errorMessage}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      createOrUpdateBackgroundData({ color, textColor });
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [color, textColor]);

  return (
    <Flex
      direction="column"
      border="1px solid #E2E8F0"
      style={{ backgroundColor: "#FFFFFF", position: "relative" }}
      borderRadius="6"
      p="4"
      mt="4"
      gap="4"
    >
      <Flex gap="2" direction="column">
        <Flex gap="4" align="center">
          <Text fontSize="sm">Background Color Picker:</Text>
          <Input
            p="1"
            width="16"
            type="color"
            border="1px solid #cbd5e0"
            value={color}
            onChange={handleBackgroundColorChange}
          />
        </Flex>
        <Flex gap="4" align="center">
          <Text fontSize="sm">Text Color Picker:</Text>
          <Input
            p="1"
            width="16"
            type="color"
            border="1px solid #cbd5e0"
            value={textColor}
            onChange={handleBackgroundTextColorChange}
          />
        </Flex>
      </Flex>

      <Flex gap="2" direction="column">
        <Text fontSize="sm">Background Image:</Text>
        <Flex gap="4" align="center">
          <Box
            backgroundColor="primary.0"
            width="120px"
            height="60px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="8px"
            position="relative"
            overflow="hidden"
          >
            {previewUrl ? (
              <Image
                src={previewUrl}
                alt="Background Preview"
                objectFit="cover"
                width="100%"
                height="100%"
                borderRadius="8px"
              />
            ) : (
              <IconButton
                aria-label="Upload Background Image"
                icon={<FiUpload />}
                onClick={handleUploadClick}
                variant="outline"
                size="sm"
              />
            )}
            <input
              type="file"
              ref={fileInputRef}
              accept="image/png, image/jpg, image/jpeg"
              onChange={handleBackgroundImageChange}
              style={{ display: "none" }}
            />
          </Box>
          <Flex direction="column" gap="2" width="100%">
            <Select
              fontSize="sm"
              value={backgroundContent?.configurations?.alignment ?? "center"}
              onChange={handleAlignmentChange}
              isDisabled={!previewUrl}
            >
              <option value="top">Top</option>
              <option value="center">Center</option>
              <option value="bottom">Bottom</option>
            </Select>
          </Flex>
          {hasBackgroundImage && (
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<FiMoreVertical />}
                variant="ghost"
                size="sm"
              />
              <MenuList>
                <MenuItem
                  icon={<MdOutlineEdit />}
                  onClick={(e: SyntheticEvent) => {
                    e.stopPropagation();
                    fileInputRef.current?.click();
                  }}
                  isDisabled={!hasBackgroundImage}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  icon={<AiOutlineDelete />}
                  onClick={(e: SyntheticEvent) => {
                    e.stopPropagation();
                    if (backgroundContent?.id) {
                      handleDeleteContent(backgroundContent.id);
                    }
                  }}
                  isDisabled={!hasBackgroundImage}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
