/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum AccessType {
  All = "All",
  MembersOnly = "MembersOnly",
  InvitedOnly = "InvitedOnly",
}

export interface Anchor {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  type?: AnchorType;
  sources?: Record<string, string>;
  sourceObjectId?: string | null;
  scores?: Record<string, number>;
  displayMode?: DisplayMode;
  configurations?: Record<string, any>;
  /** @format date-time */
  dateCreated?: string;
  /** @format date-time */
  dateLastModified?: string;
}

export enum AnchorType {
  Image = "image",
  Environment = "environment",
  QrCode = "qrCode",
}

export enum CameraPosition {
  Default = "default",
  Top = "top",
  Front = "front",
  Side = "side",
}

export interface Container {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  anchors?: Anchor[] | null;
  content?: Content[] | null;
  /** @format int32 */
  sortOrder?: number;
  orientation?: Orientation;
  displayMode?: DisplayMode;
  /** @format date-time */
  dateCreated?: string;
  /** @format date-time */
  dateLastModified?: string;
  cameraPosition?: CameraPosition;
}

export interface Content {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  sources?: Record<string, string>;
  sourceObjectId?: string | null;
  type?: ContentType;
  configurations?: Record<string, any>;
  displayMode?: DisplayMode;
  /** @format date-time */
  dateCreated?: string | null;
  /** @format date-time */
  dateLastModified?: string | null;
}

export interface ContentLibraryBean {
  name?: string | null;
  subscriptionId?: string | null;
  filetype?: string | null;
  tags?: string[] | null;
  /** @format uri */
  sasUri?: string | null;
  filename?: string | null;
  sources?: SourceItem[] | null;
  /** @format int32 */
  connectionCount?: number;
  projectIds?: string[] | null;
  id?: string | null;
  /** @format date-time */
  dateCreated?: string;
  /** @format date-time */
  dateLastModified?: string;
  /** @format double */
  version?: number;
}

export enum ContentType {
  Image = "image",
  UrlEndpoint = "url_endpoint",
  Video = "video",
  Youtube = "youtube",
  Vimeo = "vimeo",
  Value3DModel = "3d_model",
  Audio = "audio",
  Custom = "custom",
  Subtitles = "subtitles",
  Text2Speech = "Text2Speech",
  EditorJs = "editor_js",
  Background = "background",
}

export enum DisplayMode {
  AR = "AR",
  Web = "Web",
}

export interface MeUser {
  name?: string | null;
  email?: string | null;
  status?: string | null;
  roles?: string[] | null;
  subscriptions?: SimpleSubscriptionBean[] | null;
}

export enum Orientation {
  Upright = "Upright",
  Flat = "Flat",
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface ProjectBean {
  configurations?: Record<string, boolean>;
  /**
   * @minLength 5
   * @maxLength 300
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 1500
   */
  description?: string | null;
  type: ProjectType;
  /** @format double */
  minimumAppVersion?: number;
  status?: Status;
  accessType: AccessType;
  anchorType?: AnchorType;
  thumbnailUri?: string | null;
  thumbnailObjectId?: string | null;
  categories?: string[] | null;
  tags?: string[] | null;
  subscriptionId?: string | null;
  userGroupsIds?: string[] | null;
  containers?: Container[] | null;
  /** @format date-time */
  publishedAt?: string | null;
  editors?: SimpleUserBean[] | null;
  qrCodeConfigs?: QrCodeConfig[] | null;
  /** @format date-time */
  dateLastModified?: string;
  shortUniqueHash?: string | null;
  id?: string | null;
  /** @format date-time */
  dateCreated?: string;
  /** @format double */
  version?: number;
}

export interface ProjectListBean {
  configurations?: Record<string, boolean>;
  /** @format int32 */
  containersNum?: number;
  containersContent?: ContentType[] | null;
  /**
   * @minLength 5
   * @maxLength 300
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 1500
   */
  description?: string | null;
  type: ProjectType;
  /** @format double */
  minimumAppVersion?: number;
  status?: Status;
  accessType: AccessType;
  anchorType?: AnchorType;
  thumbnailUri?: string | null;
  thumbnailObjectId?: string | null;
  categories?: string[] | null;
  tags?: string[] | null;
  subscriptionId?: string | null;
  userGroupsIds?: string[] | null;
  containers?: Container[] | null;
  /** @format date-time */
  publishedAt?: string | null;
  editors?: SimpleUserBean[] | null;
  qrCodeConfigs?: QrCodeConfig[] | null;
  /** @format date-time */
  dateLastModified?: string;
  shortUniqueHash?: string | null;
  id?: string | null;
  /** @format date-time */
  dateCreated?: string;
  /** @format double */
  version?: number;
}

export enum ProjectType {
  Single = "single",
  Multiple = "multiple",
  Ordered = "ordered",
}

export interface QrCodeConfig {
  id?: string | null;
  name?: string | null;
  qrLink?: string | null;
  sasUri?: string | null;
}

export interface SimpleSubscriptionBean {
  id?: string | null;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  /** @format int32 */
  maxProjects?: number;
}

export interface SimpleUserBean {
  name?: string | null;
  email?: string | null;
}

export interface SourceItem {
  key?: string | null;
  size?: string | null;
  value?: string | null;
  filename?: string | null;
  /** @format uri */
  sasToken?: string | null;
}

export interface StatisticsBean {
  projectsByStatus?: Record<string, number>;
  usedContentTypes?: Record<string, Record<string, number>>;
  projectsByType?: Record<string, number>;
  usersRoles?: Record<string, number>;
}

export enum Status {
  Created = "Created",
  Draft = "Draft",
  Published = "Published",
  Preview = "Preview",
}

export interface SubscriptionBean {
  name?: string | null;
  publicSubscriptionId?: string | null;
  apiKey?: string | null;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  /** @minLength 1 */
  adminEmail: string;
  /** @format int32 */
  skuId: number;
  /** @format int32 */
  maxAppUsers?: number;
  /** @format int32 */
  maxAuthors?: number;
  /** @format int32 */
  maxAdmins?: number;
  users?: UserBean[] | null;
  userGroups?: UserGroup[] | null;
  /** @format date-time */
  statedIsBusinessCustomerAt?: string;
  /** @format date-time */
  acceptedTermsOfUseAt?: string;
  id?: string | null;
  /** @format date-time */
  dateCreated?: string;
  /** @format date-time */
  dateLastModified?: string;
  /** @format double */
  version?: number;
}

export interface UserBean {
  name?: string | null;
  email?: string | null;
  status?: string | null;
  roles?: string[] | null;
  subscriptions?: string[] | null;
  id?: string | null;
  /** @format date-time */
  dateCreated?: string;
  /** @format date-time */
  dateLastModified?: string;
  /** @format double */
  version?: number;
}

export interface UserGroup {
  id?: string | null;
  name?: string | null;
  users?: SimpleUserBean[] | null;
}
