import { FC, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { UploadFileLibrary } from "./components/UploadFileLibrary";
import {
  ResourceCategory,
  ResourceCategoryData,
  resourceCategoryDetailsByExtension,
} from "./resourceCategoryDetails";
import {
  useProjectCategories,
  useQueryProjects,
  useQueryResources,
} from "state/remoteState";
import { useTranslation } from "../../layout/TranslationContext";
import { translatePage } from "../../layout/translatePage";
import {
  AccessType,
  ContentLibraryBean,
  ProjectListBean,
  ProjectType,
  Status,
} from "api/model";
import { BsChevronDown } from "react-icons/bs";
import { readProject } from "api/calls";
import { useQueries } from "@tanstack/react-query";

const Preview: FC<{ resource: ContentLibraryBean }> = ({ resource }) => {
  const details = resourceCategoryDetailsByExtension(resource.filetype);

  if (
    (details.category === ResourceCategory.Images ||
      details.category == ResourceCategory.SpatialAnchors ||
      details.category == ResourceCategory.Url) &&
    resource.sasUri
  ) {
    return (
      <Image
        src={resource.sasUri}
        alt={resource.name ?? resource.filename ?? "unknown resource"}
        objectFit="cover"
        w="40px"
        h="40px"
        borderRadius="6"
        loading="lazy"
        fallback={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor="primary.1"
            w="40px"
            h="40px"
            borderRadius="6"
          >
            <Icon as={details.icon} color="primary.6" />
          </Box>
        }
      />
    );
  }
  if (
    details.category == ResourceCategory.AudioFiles &&
    resource.sources != null &&
    resource.sources.length > 0
  ) {
    return (
      <Image
        src={resource.sources[0].sasToken ?? ""}
        alt={resource.name ?? resource.filename ?? "unknown resource"}
        objectFit="cover"
        w="40px"
        h="40px"
        borderRadius="6"
        fallback={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor="primary.1"
            w="40px"
            h="40px"
            borderRadius="6"
          >
            <Icon as={details.icon} color="primary.6" />
          </Box>
        }
      />
    );
  } else {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor="primary.1"
        w="40px"
        h="40px"
        borderRadius="6"
      >
        <Icon as={details.icon} color="primary.6" />
      </Box>
    );
  }
};

const TableRow: FC<{ item: ContentLibraryBean; isMobile: boolean }> = ({
  item,
  isMobile,
}) => {
  if (isMobile) {
    return (
      <Box borderBottom="1px" borderColor="gray.200" py="3">
        <Flex alignItems="center" mb="2">
          <Preview resource={item} />
          <Text fontWeight="500" ml="3" isTruncated flex="1">
            {item.name}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" fontSize="xs" color="gray.600">
          <Text>Connections: {item.connectionCount}</Text>
          <Text>{new Date(item.dateCreated!).toLocaleDateString()}</Text>
        </Flex>
      </Box>
    );
  }

  return (
    <Tr>
      <Td>
        <Flex alignItems="center">
          <Preview resource={item} />
          <Text fontWeight="500" ml="3" isTruncated>
            {item.name}
          </Text>
        </Flex>
      </Td>
      <Td isNumeric>{item.connectionCount}</Td>
      <Td isNumeric>{new Date(item.dateCreated!).toLocaleDateString()}</Td>
    </Tr>
  );
};

export const Library = () => {
  const { data: projects } = useQueryProjects();
  //const [ProjectName, setProjectName] = useState<string>("");
  const [activeContentCategory, setActiveContentCategories] =
    useState<ResourceCategory>(ResourceCategory.All);
  const [activeProjectCategory, setActiveProjectCategory] =
    useState<string>("All");
  const [activeProjectName, setActiveProjectName] =
    useState<string>("Select a project");
  const { data: resources, isLoading } =
    useResourcesByProjectCategoryAndResourceCategory(
      activeProjectCategory,
      activeContentCategory,
      activeProjectName,
    );
  const projectCategories = useProjectCategories();
  const onContentCategoriesSelected = (category: ResourceCategory) => {
    setActiveContentCategories(category);
  };
  const onProjectCategoriesSelected = (category: string) => {
    setActiveProjectCategory(category);
  };
  const onProjectNameSelected = (name: string) => {
    setActiveProjectName(name);
  };
  const { language, setLanguage } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [hasTranslatedModal, setHasTranslatedModal] = useState(false);

  const isMobile = useBreakpointValue({ base: true, md: false }) ?? true;
  const [selectedResources, setSelectedResources] =
    useState<ContentLibraryBean[]>();
  const noProject: ProjectListBean = projects
    ? { ...projects[0], name: "Select a project" }
    : {
        name: "Select a project",
        id: "some-id",
        type: ProjectType.Multiple,
        accessType: AccessType.All,
      };
  const ps = [noProject, ...(projects || [])];
  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  useEffect(() => {
    const sessionKey = `translated_${language}`;
    if (!sessionStorage.getItem(sessionKey)) {
      translatePage(document.body, language);
      sessionStorage.setItem(sessionKey, "true");
    } else {
      translatePage(document.body, language);
    }
  }, [language]);

  useEffect(() => {
    if (isOpen && modalRef.current && !hasTranslatedModal) {
      translatePage(modalRef.current!, language).then(() => {
        setHasTranslatedModal(true);
      });
    }
  }, [isOpen, modalRef, language, hasTranslatedModal]);

  return (
    <Box mt="2rem" mb="50px">
      <Grid gap="6">
        <GridItem>
          <Heading size="xl">Library</Heading>
        </GridItem>
        <GridItem>
          <Grid
            width="100%"
            justifyContent="space-between"
            templateColumns="auto 152px"
            alignItems="center"
            gap={4}
          >
            <Flex alignItems="center" gap="8">
              <Flex alignItems="center" gap="4">
                <Text fontWeight="500" fontSize="sm">
                  Content Types
                </Text>
                <Menu>
                  <MenuButton
                    as={Button}
                    variant="outline"
                    rightIcon={<BsChevronDown />}
                    borderRadius="10"
                  >
                    <Text fontWeight="400" fontSize="sm">
                      {activeContentCategory}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    {Object.values(ResourceCategory).map((category) => (
                      <MenuItem
                        key={category}
                        onClick={() => onContentCategoriesSelected(category)}
                      >
                        {category}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Flex>
              <Flex alignItems="center" gap="4">
                <Text fontWeight="500" fontSize="sm">
                  Project Categories
                </Text>
                <Menu>
                  <MenuButton
                    as={Button}
                    variant="outline"
                    rightIcon={<BsChevronDown />}
                    borderRadius="10"
                  >
                    <Text fontWeight="400" fontSize="sm">
                      {activeProjectCategory}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    {projectCategories.map((category) => (
                      <MenuItem
                        key={category}
                        onClick={() => onProjectCategoriesSelected(category)}
                      >
                        {category}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Flex>
              <Flex alignItems="center" gap="4">
                <Text fontWeight="500" fontSize="sm">
                  Project Name
                </Text>
                <Menu>
                  <MenuButton
                    as={Button}
                    variant="outline"
                    rightIcon={<BsChevronDown />}
                    borderRadius="10"
                  >
                    <Text fontWeight="400" fontSize="sm">
                      {activeProjectName}
                    </Text>
                  </MenuButton>
                  <MenuList>
                    {ps?.map((project, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          onProjectNameSelected(project.name);
                          const selectedResources = resources.filter((r) =>
                            r.projectIds?.find((id) => id === project.id!),
                          );
                          setSelectedResources(selectedResources);
                        }}
                        data-nontranslatable={project.name}
                      >
                        {project.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Flex>
            </Flex>

            <Button width="152px" onClick={onOpen}>
              Upload File
            </Button>
          </Grid>
        </GridItem>
        <GridItem>
          <Box overflowX="auto">
            {" "}
            {isLoading ? (
              <Flex justify="center" align="center" height="300px">
                <Spinner
                  thickness="5px"
                  speed="1.5s"
                  emptyColor="gray.200"
                  color="primary.4"
                  size="xl"
                  opacity="0.8"
                />
              </Flex>
            ) : isMobile ? (
              <VStack spacing="0" align="stretch">
                {resources.map((item) => (
                  <TableRow key={item.id} item={item} isMobile={true} />
                ))}
              </VStack>
            ) : (
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th isNumeric>Connections</Th>
                    <Th isNumeric>Date</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {selectedResources?.length! > 0 &&
                  activeProjectName != "Select a project"
                    ? selectedResources?.map((item) => (
                        <TableRow key={item.id} item={item} isMobile={false} />
                      ))
                    : resources.map((item) => (
                        <TableRow key={item.id} item={item} isMobile={false} />
                      ))}
                </Tbody>
              </Table>
            )}
          </Box>
        </GridItem>
      </Grid>
      {isOpen && <UploadFileLibrary isOpen={isOpen} onClose={onClose} />}
    </Box>
  );
};

export const useResourcesByProjectCategory = (projectCategory: string) => {
  const { data: projects, isLoading: isProjectsLoading } = useQueryProjects();
  const { data: resources, isLoading: isResourcesLoading } =
    useQueryResources();
  const filteredProjects = useMemo(() => {
    if (!projects) return [];
    if (projectCategory === "Published") {
      return projects.filter((project) => project.status === Status.Published);
    } else if (projectCategory === "Draft") {
      return projects.filter((project) => project.status === Status.Draft);
    } else {
      return projects.filter((project) =>
        project.categories?.includes(projectCategory),
      );
    }
  }, [projects, projectCategory]);
  const projectQueries = useQueries({
    queries: filteredProjects.map((project) => ({
      queryKey: ["projects", project.id],
      queryFn: () => readProject(project.id!),
      enabled: !!project.id,
    })),
  });
  const isLoading =
    isProjectsLoading ||
    isResourcesLoading ||
    projectQueries.some((query) => query.isLoading);

  const containers = useMemo(() => {
    return projectQueries
      .filter((query) => query.data && query.data.containers)
      .flatMap((query) => query.data!.containers!);
  }, [projectQueries]);

  const filteredResources = useMemo(() => {
    if (!resources) return [];
    if (projectCategory === "All") {
      return resources;
    }

    return containers.flatMap(
      (container) =>
        container.content?.flatMap((content) => {
          if (!content.sourceObjectId) return [];
          return (
            resources.find(
              (resource) => resource.id === content.sourceObjectId,
            ) || []
          );
        }) || [],
    );
  }, [containers, resources, projectCategory]);

  if (isProjectsLoading || isResourcesLoading)
    return { data: [] as ContentLibraryBean[], isLoading: true };
  if (!resources) return { data: [] as ContentLibraryBean[], isLoading: false };
  if (projectCategory === "All") return { data: resources, isLoading: false };
  if (!projects) return { data: [] as ContentLibraryBean[], isLoading: false };

  return {
    data: filteredResources,
    isLoading,
  };
};

export const useResourcesByProjectCategoryAndResourceCategory = (
  projectCategory: string,
  resourceCategory: ResourceCategory,
  projectName: string,
) => {
  const { data: projectResources, isLoading: isProjectResourcesLoading } =
    useResourcesByProjectCategory(projectCategory);

  const filteredResources = useMemo(() => {
    if (!projectResources || resourceCategory === ResourceCategory.All) {
      return projectResources || [];
    }

    return projectResources.filter((resource) =>
      ResourceCategoryData[resourceCategory].extensions.includes(
        resource.filetype?.toLowerCase() ?? "",
      ),
    );
  }, [projectResources, resourceCategory]);

  return {
    data: filteredResources,
    isLoading: isProjectResourcesLoading,
  };
};
