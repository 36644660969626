import { FC, useRef, useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";

import { Content, ContentType } from "api/model";
import { TabTextToSpeech } from "./TabTextToSpeech";
import { TabAudioUpload } from "./TabAudioUpload";
import { CreateOrUpdateContentProps } from "../DialogCreateOrUpdateContent";
import { useTranslation } from "../../../../../../../layout/TranslationContext";
import { translatePage } from "../../../../../../../layout/translatePage";
import { ResourceLibrary } from "../ResourceLibrary";
import { ResourceCategory } from "pages/Library/resourceCategoryDetails";
export const contentEditorAudioSupportedContentTypes = [
  ContentType.Audio,
  ContentType.Text2Speech,
];

export const DialogContentEditorAudio: FC<CreateOrUpdateContentProps> = (
  props,
) => {
  const onClose = props.onClose;
  const mode = props.contentType ? "create" : "update";
  const content = props.content ?? ({ type: ContentType.Audio } as Content);

  let tabIndex = 0;
  switch (content.type) {
    case ContentType.Audio:
      tabIndex = 0;
      break;
    case ContentType.Text2Speech:
      tabIndex = 1;
      break;
    default:
      throw new Error("Unknown content type");
  }
  const { language, setLanguage } = useTranslation();
  const hasTranslated = useRef(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  useEffect(() => {
    console.log(props.isOpen);
    if (props.isOpen) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (modalIsOpen && modalRef.current) {
      translatePage(modalRef.current!, language);
    }
  }, [modalIsOpen]);

  return (
    <Modal {...props} size="3xl" initialFocusRef={modalRef}>
      <ModalOverlay />
      <ModalContent p="2" ref={modalRef}>
        <ModalHeader fontSize="36px" fontWeight="700" pt="10">
          {mode === "create" ? "Add Audio" : "Update Audio"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs variant="soft-rounded" defaultIndex={tabIndex}>
            <TabList
              backgroundColor="primary.0"
              p="1"
              borderRadius="50"
              gap="4"
              width="fit-content"
              height="40px"
            >
              <Tab
                _selected={{ backgroundColor: "primary.6", color: "white" }}
                width="150px"
              >
                Upload
              </Tab>
              <Tab
                _selected={{ backgroundColor: "primary.6", color: "white" }}
                width="150px"
              >
                Text&#8209;to&#8209;Speech
              </Tab>
              <Tab
                _selected={{ backgroundColor: "primary.6", color: "white" }}
                width="150px"
              >
                Library
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel px="0" py="6">
                <TabAudioUpload {...props} />
              </TabPanel>
              <TabPanel px="0" py="6">
                <TabTextToSpeech
                  content={content}
                  mode={mode}
                  onClose={onClose}
                />
              </TabPanel>
              <TabPanel px="0" py="6">
                <ResourceLibrary
                  content={content}
                  category={ResourceCategory.AudioFiles}
                  onSuccess={onClose}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
