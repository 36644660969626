import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useMediaQuery,
} from "@chakra-ui/react";
import { PanelContent } from "./PanelContent/PanelContent";
import { PanelChapterDetails } from "./PanelChapterDetails/PanelChapterDetails";
import { PanelMarker } from "./PanelMarker/PanelMarker";
import { useQueryChapter, useQueryProject } from "state/remoteState";
import { useProjectChapterId } from "state/localState";
import { AnchorType } from "api/model";
import { PanelContentQRCode } from "./PanelContent/PanelContentQRCode";
import { PanelQRCodes } from "./PanelMarker/PanelQRCodes";
import React from "react";
interface ControlPanelProps {
  isChanges: (isChanged: boolean) => void;
}

export const ControlPanel: React.FC<ControlPanelProps> = ({ isChanges }) => {
  const { projectId, chapterId } = useProjectChapterId();
  const project = useQueryProject(projectId).data;
  const chapter = useQueryChapter(projectId, chapterId).data;
  const [isLargerThan1000] = useMediaQuery("(min-width: 1000px)");

  if (!chapter || !project) return null;

  return (
    <Tabs
      variant="soft-rounded"
      size="sm"
      border="1px solid #E2E8F0"
      borderRadius="6px"
      overflow="auto"
      h="100%"
    >
      <TabList
        gap={isLargerThan1000 ? "2" : "1"}
        justifyContent="center"
        position="sticky"
        pt="24px"
        pb="12px"
        backgroundColor="var(--chakra-colors-chakra-body-bg)"
        zIndex="1"
      >
        <Tab
          _selected={{ backgroundColor: "primary.1" }}
          px={isLargerThan1000 ? 4 : 2}
        >
          Chapter&nbsp;details
        </Tab>
        <Tab
          _selected={{ backgroundColor: "primary.1" }}
          px={isLargerThan1000 ? 4 : 2}
        >
          {project.anchorType === AnchorType.Image && <>Trigger&nbsp;Marker</>}
          {project.anchorType === AnchorType.QrCode && <>QR&nbsp;Code</>}
        </Tab>
        <Tab
          _selected={{ backgroundColor: "primary.1" }}
          px={isLargerThan1000 ? 4 : 2}
        >
          Content
        </Tab>
      </TabList>
      <TabPanels height="auto">
        <TabPanel>
          <PanelChapterDetails
            project={project}
            chapter={chapter}
            isChanges={isChanges}
          />
        </TabPanel>
        <TabPanel>
          {project.anchorType === AnchorType.Image && (
            <PanelMarker
              project={project}
              chapter={chapter}
              isChanges={isChanges}
            />
          )}
          {project.anchorType === AnchorType.QrCode && <PanelQRCodes />}
        </TabPanel>
        <TabPanel>
          {project.anchorType === AnchorType.Image && (
            <PanelContent isChanges={isChanges} />
          )}
          {project.anchorType === AnchorType.QrCode && (
            <PanelContentQRCode isChanges={isChanges} />
          )}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
