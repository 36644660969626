import axios from "api/axios";
import {
  Anchor,
  ContentLibraryBean,
  ProjectBean,
  ProjectListBean,
  UserBean,
  Container,
  Content,
  StatisticsBean,
  UserGroup,
  MeUser,
} from "./model";

// =============== users/me ===============
export const readMe = async (): Promise<MeUser> => {
  try {
    return (await axios.get("users/me")).data;
  }catch (e: any) {
    console.error(e);
    if(e.response?.status === 404){
      if (window.location.hostname == "localhost") {
        window.location.href = "https://admin.stage.nextcreate.com/Home/Register";
        throw e;
      }
      window.location.href =
          window.location.href = "https://admin.nextcreate.com/Home/Register";
    }
    throw e;
  }
};

// =============== Resources ===============
export const createResource = async (
  file: File,
  filename?: string,
): Promise<ContentLibraryBean> => {
  return (
    await axios.postForm(`resources`, {
      file: file,
      name: filename ?? file.name,
    })
  ).data;
};

export const readResources = async (): Promise<ContentLibraryBean[]> => {
  return (await axios.get(`resources`)).data;
};

export const deleteResource = async (
  id: string,
): Promise<ContentLibraryBean> => {
  return (await axios.delete(`resources/${id}`)).data;
};

export const readResource = async (id: string): Promise<ContentLibraryBean> => {
  return (await axios.get(`resources/${id}`)).data;
};

// =============== Projects ===============
export const createProject = async (
  project: ProjectBean,
): Promise<ProjectBean> => {
  return (await axios.post("/projects", project)).data;
};

export const readProjects = async (): Promise<ProjectListBean[]> => {
  return (await axios.get("/projects")).data;
};

export const readProject = async (id: string): Promise<ProjectBean> => {
  const project = (await axios.get(`/projects/${id}`)).data as ProjectBean;
  // For some reason, the API returns subscriptionId as null for some users.
  // Check if all editedProject.customer.users have customerId set. If not, set it.
  // TODO: discuss with backend team
  /*if (project.subscription?.users) {
    project.customer.users = project.customer.users.map((user) => {
      if (!user.customerId) {
        return {
          ...user,
          customerId: process.env.REACT_APP_CUSTOMER_ID,
        };
      }
      return user;
    });
  }*/
  return project;
};

export const updateProject = async (
  id: string,
  project: ProjectBean,
): Promise<ProjectBean> => {
  return (await axios.put(`/projects/${id}`, project)).data;
};

export const deleteProject = async (id: string): Promise<string> => {
  return (await axios.delete(`/projects/${id}`)).data;
};

export const publishProject = async (id: string): Promise<ProjectBean> => {
  return (await axios.get(`/projects/${id}/publish`)).data;
};

// =================== Chapters ===================
export const createChapter = async (
  projectId: string,
  chapter: Container,
): Promise<Container> => {
  return (await axios.post(`/projects/${projectId}/chapters`, chapter)).data;
};

export const readChapter = async (
  projectId?: string,
  chapterId?: string,
): Promise<Container> => {
  return (await axios.get(`projects/${projectId}/chapters/${chapterId}`)).data;
};

export const updateChapter = async (
  projectId: string,
  chapter: Container,
): Promise<Container> => {
  return (
    await axios.put<Container>(
      `projects/${projectId}/chapters/${chapter.id}`,
      chapter,
    )
  ).data;
};

export const deleteChapter = async (
  projectId: string,
  chapterId: string,
): Promise<Container> => {
  return (await axios.delete(`/projects/${projectId}/chapters/${chapterId}`))
    .data;
};

// =================== Anchors ===================
export const createAnchor = async (
  projectId: string,
  chapterId: string,
  anchor: Anchor,
): Promise<Anchor> => {
  return (
    await axios.post(
      `projects/${projectId}/chapters/${chapterId}/anchors`,
      anchor,
    )
  ).data;
};

export const readAnchor = async (
  projectId: string,
  chapterId: string,
  anchorId: string,
): Promise<Anchor> => {
  return (
    await axios.get(
      `projects/${projectId}/chapters/${chapterId}/anchors/${anchorId}`,
    )
  ).data;
};

export const updateAnchor = async (
  projectId: string,
  chapterId: string,
  anchor: Anchor,
): Promise<Anchor> => {
  return (
    await axios.put(
      `projects/${projectId}/chapters/${chapterId}/anchors/${anchor.id}`,
      anchor,
    )
  ).data;
};

export const deleteAnchor = async (
  projectId: string,
  chapterId: string,
  anchorId: string,
): Promise<Anchor> => {
  return (
    await axios.delete(
      `projects/${projectId}/chapters/${chapterId}/anchors/${anchorId}`,
    )
  ).data;
};

// ======================== Content ========================
export const createContent = async (
  projectId: string,
  chapterId: string,
  content: Content,
): Promise<Content> => {
  return (
    await axios.post(
      `projects/${projectId}/chapters/${chapterId}/content`,
      content,
    )
  ).data;
};

export const updateContent = async (
  projectId: string,
  chapterId: string,
  contentId: string,
  content:Content
): Promise<Content> => {
  console.log(contentId)
  return (
    await axios.put(
      `projects/${projectId}/chapters/${chapterId}/content/${contentId}`,
      content,
    )
  ).data;
};

export const deleteContent = async (
  projectId: string,
  chapterId: string,
  contentId: string,
): Promise<Content> => {
  return (
    await axios.delete(
      `projects/${projectId}/chapters/${chapterId}/content/${contentId}`,
    )
  ).data;
};

// ======================== QR Code ========================
export const createQRCode = async (projectId: string) => {
  (
    await axios.post(`/projects/${projectId}/qr-code`, {
      name: "DeepLink",
    })
  ).data;
};

export const createQRCodeForChapter = async (
  projectId: string,
  chapterId: string,
) => {
  (await axios.post(`/projects/${projectId}/qr-code/${chapterId}`)).data;
};

// ======================== statistics ========================
export const readStatistics = async (): Promise<StatisticsBean> => {
  return (await axios.get(`statistics`)).data;
};

export const readFilteredStatistics = async (
  startDate: Date,
  endDate: Date,
): Promise<StatisticsBean> => {
  return (
    await axios.get(`statistics`, {
      params: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
    })
  ).data;
};

export const readAllStatistics = async (): Promise<StatisticsBean> => {
  return (await axios.get(`statistics/all-subscriptions`)).data;
};
export const readFilteredAllStatistics = async (
  startDate: Date,
  endDate: Date,
): Promise<StatisticsBean> => {
  return (
    await axios.get(`statistics/all-subscriptions`, {
      params: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
    })
  ).data;
};
// ======================== users ========================

export const readUsers = async (
  subscriptionId?: string,
): Promise<UserBean[]> => {
  return (await axios.get(`subscriptions/${subscriptionId}/users`)).data;
};

export const readUser = async (
  subscriptionId?: string,
  userId?: string,
): Promise<UserBean> => {
  return (await axios.get(`subscriptions/${subscriptionId}/users/${userId}`))
    .data;
};
export const updateUser = async (
  subscriptionId: string,
  userId: string,
  user: UserBean,
): Promise<UserBean> => {
  if (!user.name || !user.email) {
    throw new Error("Missing required fields");
  }

  try {
    const response = await axios.put(
      `subscriptions/${subscriptionId}/users/${userId}`,
      user,
    );
    return response.data;
  } catch (error) {
    console.error("Error updating user:", error);
    // if (error?.response?) {
    //   console.error("Response data:", error?.response?.data);
    // }
    throw error;
  }
};
export const deleteUser = async (
  subscriptionId?: string,
  userId?: string,
): Promise<UserBean> => {
  return (await axios.delete(`subscriptions/${subscriptionId}/users/${userId}`))
    .data;
};
// ======================== usersGroup ========================
export const readUsersGroups = async (
  subscriptionId: string,
): Promise<UserGroup[]> => {
  return (await axios.get(`subscriptions/${subscriptionId}/userGroups`)).data;
};
export const readUsersGroup = async (
  subscriptionId: string,
  userGroupId: string,
): Promise<UserGroup> => {
  return (
    await axios.get(`subscriptions/${subscriptionId}/userGroups/${userGroupId}`)
  ).data;
};

export const createUserGroup = async (
  subscriptionId: string,
  userGroup: UserGroup,
): Promise<UserGroup> => {
  return (
    await axios.post(`subscriptions/${subscriptionId}/userGroups`, userGroup)
  ).data;
};
export const updateUsersGroup = async (
  subscriptionId: string,
  userGroupId: string,
  userGroup: UserGroup,
): Promise<UserGroup> => {
  return (
    await axios.put(
      `subscriptions/${subscriptionId}/userGroups/${userGroupId}`,
      userGroup,
    )
  ).data;
};
export const deleteUsersGroup = async (
  subscriptionId: string,
  userGroupId: string,
): Promise<UserGroup> => {
  return (
    await axios.delete(
      `subscriptions/${subscriptionId}/userGroups/${userGroupId}`,
    )
  ).data;
};
