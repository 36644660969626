import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Input,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  IconButton,
} from "@chakra-ui/react";
import { BsImage } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";

interface ThumbnailUploaderProps {
  onImageUpload: (file: File) => void;
  currentThumbnail?: string | null;
  localImagePreview?: string | null;
  onDeleteImage: () => void;
}

export const ThumbnailUploader: React.FC<ThumbnailUploaderProps> = ({
  onImageUpload,
  currentThumbnail,
  localImagePreview,
  onDeleteImage,
}) => {
  const [dragActive, setDragActive] = useState<boolean>(false);
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleFileUpload = () => {
    hiddenFileInput.current?.click();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      onImageUpload(file);
    }
  };

  const handleDrag = (e: React.DragEvent, active: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(active);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      onImageUpload(file);
    }
  };

  const confirmDeleteImage = () => {
    onDeleteImage();
    onClose();
  };

  const imageSrc = localImagePreview || currentThumbnail || "";

  return (
    <Box position="relative" width="100%" height="100%">
      {imageSrc ? (
        <Box position="relative" width="100%" height="100%">
          <Image
            src={imageSrc}
            width="100%"
            // height="100%"
            // objectFit="cover"
            onClick={handleFileUpload}
            cursor="pointer"
            _hover={{ opacity: 0.8 }}
          />
          <IconButton
            aria-label="Delete image"
            icon={<AiOutlineDelete />}
            position="absolute"
            top="10px"
            right="10px"
            backgroundColor="primary.0"
            color="black"
            borderRadius="50%"
            _hover={{ backgroundColor: "primary.0" }}
            onClick={onOpen}
          />
        </Box>
      ) : (
        <Button
          onClick={handleFileUpload}
          width="100%"
          height="100%"
          backgroundColor="primary.0"
          borderRadius="6"
          display="flex"
          alignItems="center"
          justifyContent="center"
          _hover={{ backgroundColor: "primary.0" }}
          onDragEnter={(e) => handleDrag(e, true)}
          onDragLeave={(e) => handleDrag(e, false)}
          onDragOver={(e) => handleDrag(e, true)}
          onDrop={handleDrop}
        >
          <Flex direction="column" align="center">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              backgroundColor="primary.1"
              w="50px"
              h="50px"
              borderRadius="50%"
            >
              <Icon as={BsImage} color="primary.6" boxSize="6" />
            </Box>

            <Text color="primary.6" pt="3">
              {dragActive ? "Drop here" : "Add thumbnail"}
            </Text>
            <Text color="primary.4">Supports: JPG, JPEG, PNG</Text>
          </Flex>
        </Button>
      )}

      <Input
        type="file"
        style={{ display: "none" }}
        ref={hiddenFileInput}
        accept="image/png, image/jpg, image/jpeg"
        onChange={handleChange}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete this image?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to delete this image? This action cannot be
              undone.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" ml={3} onClick={confirmDeleteImage}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
