import { useNavigate } from "react-router-dom";
import { HStack, VStack, IconButton, Text, Box, Heading, useBreakpointValue, useMediaQuery } from "@chakra-ui/react";
import { BiArrowBack } from "react-icons/bi";
import { useQueryChapter, useQueryProject } from "state/remoteState";
import { Grid, Spacer } from "@chakra-ui/react";
import { useState, useEffect, useRef } from "react";
import { CanvasARViewer } from "./components/Canvas/CanvasARViewer";
import { ControlPanel } from "./components/ControlPanel/ControlPanel";
import { useProjectChapterId } from "state/localState";
import { DisplayMode } from "api/model";
import { CanvasOnscreenViewer } from "./components/Canvas/CanvasOnScreenViewer";
import { HelpIconButton } from "pages/HelpIconButton";
import { useTranslation } from "../../layout/TranslationContext";
import { translatePage } from "../../layout/translatePage";
import { Status } from "api/model";
import { PublishPopover } from "../Project/PublishPopover";

export const Chapter = () => {
  const { projectId, chapterId } = useProjectChapterId();
  const { data: project } = useQueryProject(projectId);
  const { data: chapter } = useQueryChapter(projectId, chapterId);
  const navigate = useNavigate();
  const { language, setLanguage } = useTranslation();
  const hasTranslated = useRef(false);
  const [isPublished] = useState(project?.status === Status.Published);
  const [changes, isChanges] = useState(false);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  useEffect(() => {
    const sessionKey = `translated_${language}`;
    if (!sessionStorage.getItem(sessionKey)) {
      translatePage(document.body, language);
      sessionStorage.setItem(sessionKey, "true");
      hasTranslated.current = true;
    } else {
      translatePage(document.body, language);
    }
  }, [language, chapter]);

  const isMobile = useBreakpointValue({ base: true, md: false });
  const [isLargerThan1000] = useMediaQuery("(min-width: 1000px)");
  const gridTemplateColumns = useBreakpointValue({
    base: "1fr",
    md: "minmax(300px, 2fr) minmax(300px, 1fr)",
    lg: "minmax(400px, 3fr) minmax(400px, 1fr)",
  });

  return (
    <>
      <HStack py="6">
        <IconButton
          aria-label="Go Back"
          icon={<BiArrowBack />}
          onClick={() => navigate(-1)}
          variant="outline"
        /> 
           { isLargerThan1000 ? (
          <HStack spacing={8}>
              <Box ml="5px" shadow='sm' borderWidth='1px' p={2}>
             <HStack>
              <Heading fontSize='l'>Project</Heading>
              <Text data-nontranslatable={project?.name}>{project?.name}</Text>
             </HStack>
              </Box>
              <Box shadow='sm' borderWidth='1px' p={2}>
               <HStack>
              <Heading fontSize='l'>Chapter</Heading>
              <Text data-nontranslatable={chapter?.name}>{chapter?.name}</Text>
              </HStack>
             </Box>
           </HStack> 
             ) : (
            <HStack spacing={2}>
              <Box ml="5px" shadow='sm' borderWidth='1px' p={2}>
                <VStack>
                <Heading fontSize='s'>Project</Heading>
                <Text data-nontranslatable={project?.name}>{project?.name}</Text>
               </VStack>
              </Box>
             <Box shadow='sm' borderWidth='1px' p={2}>
              <VStack>
                <Heading fontSize='s'>Chapter</Heading>
                <Text data-nontranslatable={chapter?.name}>{chapter?.name}</Text>
              </VStack>
            </Box>
           </HStack>
            )} 
        <Spacer />
        <div>
          <PublishPopover changes={changes} />
        </div>
      </HStack>
      <Grid
      marginLeft="5px"
        templateColumns={gridTemplateColumns}
        templateRows={
          isMobile ? "auto 1fr" : "minmax(200px, calc(100vh - 168px))"
        }
        gap={4}
        data-nontranslatable
      >
        {chapter?.displayMode === DisplayMode.AR && <CanvasARViewer />}
        {chapter?.displayMode === DisplayMode.Web && <CanvasOnscreenViewer />}
        <ControlPanel isChanges={isChanges} />
      </Grid>
      <HelpIconButton
        link="https://nextcreate.com/hilfe/kapitel-editor/"
        isFloating={true}
      />
    </>
  );
};
