import React, { FC, useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  Text,
  PopoverTrigger,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  useMutationPublishProject,
  useMutationUpdateProject,
  useQueryProject,
} from "../../state/remoteState";
import { useProjectId } from "../../state/localState";
import { AccessType, Status } from "../../api/model";
import { translatePage, translateText } from "../../layout/translatePage";
import { useTranslation } from "../../layout/TranslationContext";

interface PublishPopoverProps {
  changes: boolean;
}

export const PublishPopover: FC<PublishPopoverProps> = ({ changes }) => {
  const projectId = useProjectId();
  const { data: project } = useQueryProject(projectId);
  const mutationPublishProject = useMutationPublishProject();
  const mutationUpdateProject = useMutationUpdateProject();
  const [newChanges, setNewChanges] = useState<boolean>(changes);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const unpublishModal = useDisclosure();
  const toast = useToast();
  const { language, setLanguage } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);

  const showTranslatedToast = async (
    title: string,
    message: string,
    language: string,
  ) => {
    const translatedTitle = await translateText(title, language);
    const translatedMessage = await translateText(message, language);
    toast({
      title: translatedTitle,
      description: translatedMessage,
    });
  };

  // Load saved language from localStorage
  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  // Translate page content when new changes are detected
  useEffect(() => {
    if (newChanges) {
      translatePage(document.body, language);
      if (modalRef.current) {
        translatePage(modalRef.current, language);
      }
    }
  }, [newChanges, language]);

  // Determine if there are new changes compared to published date
  useEffect(() => {
    const dateLastModified = project?.dateLastModified
      ? new Date(project.dateLastModified)
      : null;
    const publishedAt = project?.publishedAt
      ? new Date(project.publishedAt)
      : null;

    if (!dateLastModified || !publishedAt || dateLastModified > publishedAt) {
      setNewChanges(true);
    } else {
      setNewChanges(false);
    }
  }, [changes, project]);

  // Handle publishing project
  const handlePublishProject = () => {
    if (!project) return;

    mutationPublishProject.mutate(project, {
      onSuccess: () => {
        showTranslatedToast(
          "Published",
          "New changes were published!",
          language,
        );
        onClose();
      },
      onError: (e: any) => {
        const problems: string[] = Array.from(
          new Set(e.response?.data.map((x: any) => x.errorMessage)),
        );
        problems.forEach((x) => {
          showTranslatedToast("Publish Validation Failed", x, language);
        });
      },
    });
  };

  // Handle unpublishing project
  const handleUnpublishProject = () => {
    console.log("Unpublish Project");
    project!.status = Status.Draft;
    mutationUpdateProject.mutate(project!, {
      onSuccess: () => {
        showTranslatedToast(
          "Unpublished",
          "The project was unpublished!",
          language,
        );
        unpublishModal.onClose();
      },
    });
  };

  return (
    <Flex>
      {project?.status === Status.Published && (
        <Popover
          isOpen={unpublishModal.isOpen}
          onOpen={unpublishModal.onOpen}
          onClose={unpublishModal.onClose}
        >
          <Flex ref={modalRef}>
            <PopoverTrigger>
              <Button
                variant="outline"
                ml="3"
                onClick={() => {
                  console.log("Unpublish");
                }}
              >
                Unpublish
              </Button>
            </PopoverTrigger>
          </Flex>
          <PopoverContent p="4" mr="4" boxShadow="md">
            <PopoverHeader fontWeight="600" color="primary.3">
              Are you sure?
            </PopoverHeader>
            <PopoverBody my="2">
              <Flex alignItems="center" gap="3">
                <Text fontWeight="500" color="primary.6" fontSize="sm">
                  Unpublish for
                </Text>
                <Box
                  px="4"
                  py="1"
                  bg="blue.50"
                  borderRadius="full"
                  my="2"
                  fontSize="sm"
                  fontWeight="600"
                >
                  {project.accessType === AccessType.All
                    ? "Anyone"
                    : "All Company Users"}{" "}
                </Box>
              </Flex>
              <Text fontWeight="400" color="primary.4" fontSize="sm">
                {project.status === Status.Published
                  ? "Published"
                  : "Not Published"}
              </Text>
            </PopoverBody>
            <PopoverFooter
              display="flex"
              justifyContent="center"
              gap="4"
              borderTop="none"
            >
              <Button
                variant="outline"
                px="6"
                fontSize="sm"
                onClick={() => {
                  console.log("onclose");
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button px="6" fontSize="sm" onClick={handleUnpublishProject}>
                Unpublish
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
      )}

      {newChanges && (
        <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
          <Flex>
            <PopoverTrigger>
              <Button variant="outline" ml="3">
                {project?.status === Status.Published && newChanges
                  ? "Publish Changes"
                  : "Publish"}
              </Button>
            </PopoverTrigger>
          </Flex>
          <PopoverContent p="4" mr="4" boxShadow="md">
            <PopoverHeader fontWeight="600" color="primary.3">
              Are you sure?
            </PopoverHeader>
            <PopoverBody my="2">
              <Flex alignItems="center" gap="3">
                <Text fontWeight="500" color="primary.6" fontSize="sm">
                  {project?.status === Status.Published && newChanges
                    ? "Publish Changes"
                    : "Publish"}
                  &nbsp;for
                </Text>
                <Box
                  px="4"
                  py="1"
                  bg="blue.50"
                  borderRadius="full"
                  my="2"
                  fontSize="sm"
                  fontWeight="600"
                  style={{
                    hyphens: "auto",
                    wordBreak: "break-word",
                    WebkitHyphens: "auto",
                    MozHyphens: "auto",
                  }}
                >
                  {project?.accessType === AccessType.All
                    ? "Anyone"
                    : "All Company Users"}{" "}
                </Box>
              </Flex>
              <Text fontWeight="400" color="primary.4" fontSize="sm">
                {project?.status === Status.Published
                  ? "Published"
                  : "Not Published"}
              </Text>
            </PopoverBody>
            <PopoverFooter
              display="flex"
              justifyContent="center"
              gap="4"
              borderTop="none"
            >
              <Button
                variant="outline"
                px="6"
                fontSize="sm"
                onClick={() => {
                  console.log("onclose");
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                px="6"
                fontSize="sm"
                onClick={handlePublishProject}
                whiteSpace="normal"
              >
                {project?.status === Status.Published && newChanges
                  ? "Publish Changes"
                  : "Publish"}
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
      )}
    </Flex>
  );
};
