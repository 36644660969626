import { FC } from "react";
import { Content, ContentType } from "api/model";
import { ContentImage } from "pages/Chapter/components/Canvas/CanvasContent/ContentImage";
import { ContentVideo } from "pages/Chapter/components/Canvas/CanvasContent/ContentVideo";
import { ContentThreeDModel } from "pages/Chapter/components/Canvas/CanvasContent/ContentThreeDModel";
import { ContentWebsite } from "./ContentWebsite";
import { ContentAudio } from "./ContentAudio";
import { ContentVideoURL } from "./ContentVideoURL";
import { ContentSubtitles } from "./ContentSubtitles";
import { ContentWebEditor } from "./ContentWebEditor";
import { useMutationUpdateContent } from "state/remoteState";
import { useProjectChapterId } from "state/localState";

interface SelectorProps {
  content: Content;
  isOnScreen: boolean;
}

// CanvasContent is a wrapper for content of any type on the canvas.
export const Selector: FC<SelectorProps> = ({ content, isOnScreen }) => {
  const mutationUpdateContent = useMutationUpdateContent();
  const { projectId, chapterId } = useProjectChapterId();
  const isSourcesEmpty =
    !content?.sources || Object.keys(content.sources).length === 0;

  if (content?.type === ContentType.Image && isSourcesEmpty) {
    const updatedContent = {
      ...content,
      type: ContentType.EditorJs,
    };
    console.log(updatedContent);
    mutationUpdateContent.mutate({
      projectId,
      chapterId,
      contentId: content.id!,
      content: updatedContent,
    });
  }

  switch (content.type) {
    case ContentType.Image:
      return <ContentImage content={content} />;
    case ContentType.UrlEndpoint:
      return <ContentWebsite content={content} />;
    case ContentType.EditorJs:
      return <ContentWebEditor content={content} isOnScreen={isOnScreen} />;
    case ContentType.Video:
      return <ContentVideo content={content} />;
    case ContentType.Youtube:
    case ContentType.Vimeo:
      return <ContentVideoURL content={content} />;
    case ContentType.Value3DModel:
      return <ContentThreeDModel content={content} />;
    case ContentType.Audio:
      return <ContentAudio />;
    case ContentType.Text2Speech:
      return <ContentAudio />;
    case ContentType.Subtitles:
      return <ContentSubtitles content={content} />;
    default:
      throw new Error("Unknown content type");
  }
};
