import {
  FC,
  useEffect,
  useRef,
  useState,
  DragEvent,
  SyntheticEvent,
} from "react";
import { useForm } from "react-hook-form";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Input,
  Spacer,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Content, ContentType } from "api/model";
import { useProjectChapterId } from "state/localState";
import {
  useMutationCreateContent,
  useMutationUpdateContent,
} from "state/remoteState";
import { Configurations } from "api/Configurations";
import { CreateOrUpdateContentProps } from "../DialogCreateOrUpdateContent";
import { translateText } from "../../../../../../../layout/translatePage";
import { useTranslation } from "../../../../../../../layout/TranslationContext";
import { IoTextSharp } from "react-icons/io5";

// Form data type
type FormData = {
  name?: string;
};

export const TabSubtitlesUpload: FC<CreateOrUpdateContentProps> = (props) => {
  const { projectId, chapterId } = useProjectChapterId();
  const [file, setFile] = useState<File | undefined>(props.file);
  const [dragActive, setDragActive] = useState<boolean>(false);
  const mutationCreateContent = useMutationCreateContent();
  const mutationUpdateContent = useMutationUpdateContent();
  const [configurationsFromPreview, setConfigurationsFromPreview] =
    useState<Configurations>({});
  const content = props.content ?? ({ type: ContentType.Subtitles } as Content);
  const mode = props.content ? "update" : "create";
  const toast = useToast();

  // Initialize form with react-hook-form
  const {
    register,
    watch,
    formState: { isDirty },
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      name: content?.name ?? "",
    },
  });
  const formData = watch();

  // Track if a new file has been added in update mode
  const [fileChanged, setFileChanged] = useState<boolean>(false);
  const isNewFileAdded = mode === "update" && fileChanged;

  // State for subtitle preview
  const [subtitlesContent, setSubtitlesContent] = useState<string>();
  useEffect(() => {
    if (!file) {
      if (content?.sources?.main) {
        fetch(content.sources.main)
          .then((response) => response.text())
          .then((text) => setSubtitlesContent(text))
          .catch(() => setSubtitlesContent(""));
      }
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target?.result;
      setSubtitlesContent(typeof text === "string" ? text : "");
    };
    reader.readAsText(file);
  }, [file, content?.sources?.main]);

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  // Trigger the hidden file input when the upload area is clicked
  const handleFileUpload = () => {
    hiddenFileInput.current?.click();
  };

  // Validate and set the selected file
  const checkAndSetFile = (f: File | null | undefined) => {
    if (!f) return;
    const validExtensions = [".srt", ".vtt"];
    const fileExtension = f.name.slice(
      ((f.name.lastIndexOf(".") - 1) >>> 0) + 2,
    );
    if (!validExtensions.includes(`.${fileExtension.toLowerCase()}`)) {
      toast({
        status: "error",
        title: "Unsupported file format",
        description: "Please upload subtitles in SRT or VTT format.",
      });
      return;
    }
    setFile(f);
    if (mode === "update") {
      setFileChanged(true);
    }
  };

  // Handle file selection from the input
  const handleFileSelected = () => {
    const f = hiddenFileInput.current?.files?.item(0);
    checkAndSetFile(f);
  };

  // Handle file drop event
  const handleDrop = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const f = e.dataTransfer.files.item(0);
    checkAndSetFile(f);
  };

  // Handle drag events to toggle dragActive state
  const handleDrag = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const { language } = useTranslation();

  // Display translated toast messages
  const showTranslatedToast = async (message: string, language: string) => {
    const translatedMessage = await translateText(message, language);
    toast({
      title: translatedMessage,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  // Handle content creation
  const handleCreateContent = () => {
    if (!file) return;
    const newContent: Content = {
      name: formData.name,
      description: file.name,
      type: ContentType.Subtitles,
      configurations: configurationsFromPreview,
    };
    mutationCreateContent.mutate(
      { projectId, chapterId, content: newContent, file },
      {
        onSuccess: () => {
          showTranslatedToast(
            `Subtitles "${content.name ?? "unnamed"}" have been added successfully`,
            language,
          );
          props.onSuccess();
        },
        onError: (error) => {
          toast({
            status: "error",
            title: "Error adding content",
            description:
              error.message || "An error occurred while adding the subtitles.",
          });
        },
      },
    );
  };

  // Handle content update
  const handleUpdateContent = () => {
    if (!content) {
      throw new Error("Content is not defined");
    }
    content.name = formData.name;
    if (file) {
      content.description = file.name;
    }
    content.configurations = {
      ...content.configurations,
      ...configurationsFromPreview,
    };

    mutationUpdateContent.mutate(
      {
        projectId,
        chapterId,
        contentId: content.id!,
        content,
        file: isNewFileAdded ? file : undefined,
      },
      {
        onSuccess: () => {
          showTranslatedToast(
            `Subtitles "${content.name ?? "unnamed"}" have been updated successfully.`,
            language,
          );
          props.onSuccess();
        },
        onError: (error: any) => {
          toast({
            status: "error",
            title: "Error updating content",
            description:
              error.message ||
              "An error occurred while updating the subtitles.",
          });
        },
      },
    );
  };

  return (
    <>
      <Grid templateColumns="1fr 1fr" gap="2" minHeight="250px">
        <GridItem>
          <Button
            position="relative"
            onClick={handleFileUpload}
            width="100%"
            height="100%"
            backgroundColor="primary.0"
            borderRadius="6"
            display="flex"
            alignItems="center"
            justifyContent="center"
            _hover={{ backgroundColor: "primary.0" }}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            <Flex direction="column" align="center">
              {!dragActive ? (
                subtitlesContent ? (
                  <Box
                    position="relative"
                    maxHeight="220px"
                    overflow="auto"
                    pr="4"
                  >
                    <Text
                      whiteSpace="pre-wrap"
                      fontSize="sm"
                      color="primary.4"
                      textAlign="left"
                    >
                      {subtitlesContent}
                    </Text>
                    <Text pt="4" color="primary.3">
                      Drag & Drop or click here
                    </Text>
                  </Box>
                ) : (
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      backgroundColor="primary.1"
                      w="50px"
                      h="50px"
                      borderRadius="50"
                    >
                      <Icon as={IoTextSharp} color="primary.6" boxSize="6" />
                    </Box>
                    <Text color="primary.6" pt="6" pb="2" fontWeight="700">
                      Upload Subtitles
                    </Text>
                    <Text color="primary.4">Supports: SRT, VTT</Text>
                    <Text color="primary.3" pt="4">
                      Drag & Drop or click here
                    </Text>
                  </>
                )
              ) : (
                <Text color="primary.6">Drop here</Text>
              )}
            </Flex>
          </Button>
          <input
            type="file"
            ref={hiddenFileInput}
            style={{ display: "none" }}
            onChange={handleFileSelected}
            accept=".srt, .vtt"
          />
        </GridItem>

        <Flex direction="column" gap="4" pl="2">
          <Alert
            status="info"
            color="secondary.2"
            backgroundColor="rgba(77, 136, 140, 0.05)"
            border="1px solid #4D888C"
            borderRadius="8"
          >
            <AlertIcon color="secondary.2" />
            <Text fontWeight="500" fontSize="xs">
              You can add a SRT or VTT subtitle file here.
            </Text>
          </Alert>

          <Flex align="center" gap="2">
            <Text fontSize="sm" fontWeight="600" color="primary.4" mr="2">
              Content&nbsp;Name
            </Text>
            <Input
              placeholder="Name"
              borderRadius="32"
              size="sm"
              {...register("name")}
            />
          </Flex>
        </Flex>
      </Grid>

      <Flex width="100%" justifyContent="flex-end">
        <Spacer />
        {mode === "create" && (
          <Button
            isDisabled={!file}
            _hover={{ backgroundColor: "primary.3" }}
            variant="primary"
            mt="4"
            onClick={handleSubmit(handleCreateContent)}
            isLoading={mutationCreateContent.isPending}
          >
            Add Content
          </Button>
        )}
        {mode === "update" && (
          <Button
            isDisabled={(!file && !isDirty) || (!isNewFileAdded && !isDirty)}
            _hover={{ backgroundColor: "primary.3" }}
            variant="primary"
            mt="4"
            onClick={handleSubmit(handleUpdateContent)}
            isLoading={mutationUpdateContent.isPending}
          >
            Update Content
          </Button>
        )}
      </Flex>
    </>
  );
};
