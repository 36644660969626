import React, { FC, useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { AnimationAction, Group, Box3, Vector3 } from "three";
import { Content } from "api/model";

interface Props {
  content: Content;
}

export const ContentThreeDModel: FC<Props> = ({ content }) => {
  const groupRef = useRef<Group>(null);
  const { scene, animations } = useGLTF(content.sources!.main);
  const { actions, mixer } = useAnimations(animations, groupRef);

  // Effect for initial scaling
  useEffect(() => {
    //Resizes model on load
    const boxx = new Box3();
    const bbox = boxx.setFromObject(scene);
    const size = bbox.getSize(new Vector3());

    //Rescale the object to normalized space
    let maxAxis = Math.max(size.x, size.y, size.z);
    // scene.scale.multiplyScalar(1.0 / maxAxis);
  }, [scene]);

  // Effect for animation update
  useEffect(() => {
    const selectedAnimation =
      content?.configurations?.threeD?.selectedAnimation;
    if (selectedAnimation && actions[selectedAnimation]) {
      console.log("Playing animation:", selectedAnimation);
      // Stop all current animations
      Object.values(actions).forEach((action) => action?.stop());
      // Play the selected animation
      const action = actions[selectedAnimation] as AnimationAction;
      action.reset().play();
    }

    // Cleanup function
    return () => {
      Object.values(actions).forEach((action) => action?.stop());
      mixer.stopAllAction();
    };
  }, [
    content?.configurations?.threeD?.selectedAnimation,
    actions,
    mixer,
    content.sources,
  ]);

  // We use useFrame because the drei way of playing animations is not working
  // in combination with old versions of three.js and newver versions of some of the libraries
  // TODO: make it a drei way after updating the trhee.js, fiber, and drei libraries.

  useFrame((state, delta) => {
    mixer.update(delta);
  });

  return (
    <group ref={groupRef} key={`${content.sources?.main}-${content.name}`}>
      <primitive object={scene} />
    </group>
  );
};
