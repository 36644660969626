import React, { FC, SyntheticEvent } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FiMoreVertical, FiUpload } from "react-icons/fi";
import { useQueryChapter, useMutationDeleteContent, useMutationUpdateContent, updateChapterLocally, useMutationUpdateChapter } from "state/remoteState";
import { AiOutlineDelete } from "react-icons/ai";
import { Content, ContentType, Container } from "api/model";
import { MdOutlineEdit } from "react-icons/md";
import { contentTypeDetails } from "./contentTypeDetails";
import { ContentListContentDetails } from "./ContentList/ContentListContentDetails";
import { useProjectChapterId } from "state/localState";
import { DialogContentEditorWebsite } from "./ContentEditorWizard/Website/DialogContentEditorWebsite";
import { useDebouncedCallback } from "use-debounce";
import { useQueryClient } from "@tanstack/react-query";
import { Portal } from "@chakra-ui/react";


interface PanelContentQRCodeProps {
  isChanges: (isChanged: boolean) => void;
}
export const PanelContentQRCode: FC<PanelContentQRCodeProps> = ({ isChanges }) => {
  const { projectId, chapterId } = useProjectChapterId();
  const chapter = useQueryChapter(projectId, chapterId).data;
  const mutationDeleteContent = useMutationDeleteContent();
  const mutationUpdateContent = useMutationUpdateContent();

  const [contentType, setContentType] = React.useState<ContentType | undefined>(
    ContentType.UrlEndpoint,
  );
  const [content, setContent] = React.useState<Content | undefined>(undefined);
  const queryClient = useQueryClient();
  const mutationUpdateChapter = useMutationUpdateChapter();
  const updateChapterDebounced = useDebouncedCallback((chapter: Container) => {
    mutationUpdateChapter.mutate({ projectId, chapter });
  }, 50);
  const {
    onOpen: openContentEditor,
    onClose: closeContentEditor,
    isOpen: isOpenContentEditor,
  } = useDisclosure();

  const create = () => {
    setContentType(ContentType.UrlEndpoint);
    setContent(undefined);
    openContentEditor();
  };

  const update = (content: Content) => {
    setContentType(undefined);
    setContent(content);
    openContentEditor();
    mutationUpdateContent.mutate({
      projectId: projectId!,
      chapterId: chapterId!,
      contentId: content.id!,
      content,
    })
    const updatedChapter = { ...chapter};
    updateChapterLocally(queryClient, projectId, updatedChapter);
    updateChapterDebounced(updatedChapter);
    isChanges(true);
  };

  const handleDeleteContent = async (contentId: string) => {
    mutationDeleteContent.mutate({
      projectId: projectId!,
      chapterId: chapterId!,
      contentId: contentId,
    });
    const updatedChapter = { ...chapter};
    updateChapterLocally(queryClient, projectId, updatedChapter);
    updateChapterDebounced(updatedChapter);
    isChanges(true);
  };

  return (
    <>
      <DialogContentEditorWebsite
        contentType={contentType}
        content={content}
        onClose={closeContentEditor}
        isOpen={isOpenContentEditor}
        onSuccess={closeContentEditor}
      />
      {(chapter?.content?.length ?? 0) > 0 ? null : (
        <Box
          onClick={create}
          style={{
            display: "flex",
            flexDirection: "column",
            border: "1px dashed #E2E8F0",
            borderRadius: "4px",
            padding: "3px",
            backgroundColor: "white",
            position: "relative",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <Flex
            align="center"
            justify="center"
            gap="4"
            p="4"
            direction="column"
            width="100%"
          >
            <Box
              backgroundColor="primary.0"
              width="45px"
              height="45px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="50%"
            >
              <Icon as={FiUpload} />
            </Box>
            <Flex direction="column" align="center">
              <Text
                textAlign="left"
                fontWeight="700"
                fontSize="md"
                color="black"
              >
                Upload Content
              </Text>
            </Flex>
          </Flex>
        </Box>
      )}

      {chapter?.content
        ?.filter((content) => content.type !== ContentType.Background)
        .map((content, i) => (
          <div
            key={i + "div"}
            style={{
              display: "flex",
              flexDirection: "column",
              border: `1px solid ${"#E2E8F0"}`,
              borderRadius: "4px",
              padding: "3px",
              marginTop: "10px",
              backgroundColor: "white",
              position: "relative",
              width: "100%",
            }}
          >
            <Accordion
              key={i + "acc"}
              width={"100%"}
              allowToggle
              defaultIndex={[0]}
            >
              <AccordionItem
                key={i + "accordeoon"}
                borderColor="transparent"
                p="2"
              >
                <AccordionButton
                  key={i + "accordeoonBUtton"}
                  width="100%"
                  justifyContent="space-between"
                  p="1"
                >
                  <Flex align="center" gap="3" w="100%">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      backgroundColor="primary.1"
                      w="40px"
                      h="40px"
                      borderRadius="50"
                      flexShrink="0"
                    >
                      <Icon
                        as={contentTypeDetails(content.type!).icon}
                        color="primary.6"
                      />
                    </Box>
                    <Flex direction="column">
                      <Text
                        fontWeight="700"
                        fontSize="sm"
                        color="black"
                        align="left"
                      >
                        {content.name}
                      </Text>
                      <Text
                        fontSize="xs"
                        fontWeight="500"
                        color="primary.4"
                        align="left"
                        mt="1"
                        lineHeight="1.2"
                      >
                        {content.type === ContentType.Background
                          ? "Background"
                          : content.description}
                      </Text>
                    </Flex>
                    <Spacer />
                  </Flex>
                </AccordionButton>
                <AccordionPanel p="2">
                  <ContentListContentDetails content={content} />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <Menu key={i + "menu"} isLazy>
              <MenuButton
                key={i + "menuButton"}
                as={IconButton}
                aria-label="Options"
                icon={<FiMoreVertical />}
                variant="ghost"
                position="absolute"
                top="1.5vh"
                right="1vh"
              />
              <Portal>
                <MenuList key={i + "menuList"} zIndex="popover">
                  <MenuItem
                    key={i + "menuItem"}
                    icon={<MdOutlineEdit />}
                    onClick={(e: SyntheticEvent) => {
                      e.stopPropagation();
                      update(content);
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    icon={<AiOutlineDelete />}
                    onClick={(e: React.SyntheticEvent) => {
                      e.stopPropagation();
                      handleDeleteContent(content.id!);
                    }}
                  >
                    Delete
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          </div>
        ))}
    </>
  );
};
