import { FC } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from "@chakra-ui/react";
import { FiMoreVertical } from "react-icons/fi";
import {
  useQueryChapter,
  useMutationDeleteContent,
  useMutationCreateContent,
  useMutationUpdateContent,
} from "state/remoteState";
import { AiOutlineDelete } from "react-icons/ai";
import { ContentUploadButton } from "./ContentUploadButton";
import { Content, ContentType, DisplayMode } from "api/model";
import { MdOutlineEdit } from "react-icons/md";
import { contentTypeDetails } from "./contentTypeDetails";
import { ContentListContentDetails } from "./ContentList/ContentListContentDetails";
import { useProjectChapterId } from "state/localState";
import OnScreenBackgroundPicker from "./OnScreenBackgroundPicker";
import {
  ContentEditorWizard,
  useContentEditorWizard,
} from "./ContentEditorWizard/ContentEditorWizard";

interface PanelContentProps {
  isChanges: (isChanged: boolean) => void;
}

const hasContentDetails = (contentType: ContentType): boolean => {
  return (
    contentType === ContentType.Audio ||
    contentType === ContentType.Text2Speech ||
    contentType === ContentType.Video ||
    contentType === ContentType.Value3DModel ||
    contentType === ContentType.Subtitles
  );
};

const ContentItem: FC<{
  content: Content;
  onUpdate: (content: Content) => void;
  onDelete: (contentId: string) => void;
}> = ({ content, onUpdate, onDelete }) => {
  if (!content.type || !content.id) {
    console.error("Content type or id is missing");
    return null;
  }

  const hasDetails = hasContentDetails(content.type);

  const headerContent = (
    <Flex align="center" gap="3" flex="1">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        bg="primary.1"
        width="40px"
        height="40px"
        borderRadius="full"
        flexShrink="0"
      >
        <Icon as={contentTypeDetails(content.type!).icon} color="primary.6" />
      </Box>
      <Flex direction="column" flex="1">
        <Text fontWeight="700" fontSize="sm" color="black" align="left" className="doNotTranslate">
          {content.name}
        </Text>
        <Text
          fontSize="xs"
          fontWeight="500"
          color="primary.4"
          align="left"
          mt="1"
          lineHeight="1.2"
        >
          {content.type === ContentType.Background
            ? "Background"
            : content.description}
        </Text>
      </Flex>
    </Flex>
  );

  const menuButton = (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<FiMoreVertical />}
        variant="ghost"
        size="sm"
      />
      <MenuList zIndex="1">
        <MenuItem icon={<MdOutlineEdit />} onClick={() => onUpdate(content)}>
          Edit
        </MenuItem>
        <MenuItem
          icon={<AiOutlineDelete />}
          onClick={() => onDelete(content.id!)}
        >
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  );

  return (
    <Box
      borderWidth="1px"
      borderStyle="solid"
      borderColor="gray.200"
      borderRadius="md"
      mt="2.5"
      bg="white"
      width="100%"
      overflow="hidden"
    >
      {hasDetails ? (
        <Accordion width="100%" allowToggle defaultIndex={[0]}>
          <AccordionItem border="none">
            <AccordionButton p="3">
              <Flex align="center" width="100%">
                {headerContent}
                {menuButton}
              </Flex>
            </AccordionButton>
            <AccordionPanel p="3">
              <ContentListContentDetails content={content} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ) : (
        <Flex align="center" p="3">
          {headerContent}
          {menuButton}
        </Flex>
      )}
    </Box>
  );
};

export const PanelContent: FC<PanelContentProps> = ({ isChanges }) => {
  const { projectId, chapterId } = useProjectChapterId();
  const { data: chapter } = useQueryChapter(projectId, chapterId);
  const updateContentMutation = useMutationUpdateContent();
  const deleteContentMutation = useMutationDeleteContent();
  const createContentMutation = useMutationCreateContent();
  const toast = useToast();

  const { contentEditorWizardProps, create, update } = useContentEditorWizard();

  const showToast = (title: string, status: "success" | "error") => {
    toast({
      title,
      status,
      duration: 3000,
      isClosable: true,
    });
  };

  const handleUpdateContent = async (updatedContent: Content) => {
    try {
      await updateContentMutation.mutateAsync({
        projectId: projectId!,
        chapterId: chapterId!,
        contentId: updatedContent.id!,
        content: updatedContent,
      });
      isChanges(true);
      showToast("Content updated", "success");
    } catch (error: unknown) {
      const errorMessage =
        error instanceof Error ? error.message : "Unknown error";
      console.error("Error updating content:", error);
      showToast(`Error updating content: ${errorMessage}`, "error");
    }
  };

  const handleDeleteContent = async (contentId: string) => {
    try {
      await deleteContentMutation.mutateAsync({
        projectId: projectId!,
        chapterId: chapterId!,
        contentId,
      });
      isChanges(true);
      showToast("Content deleted", "success");
    } catch (error) {
      console.error("Error deleting content:", error);
      showToast("Error deleting content", "error");
    }
  };

  const handleCreateContent = async (file: File) => {
    try {
      await createContentMutation.mutateAsync({
        projectId: projectId!,
        chapterId: chapterId!,
        content: {
          type: ContentType.Image,
          name: file.name,
          description: "",
        },
      });
      isChanges(true);
      showToast("Content created", "success");
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "Unknown error";
      showToast(`Error creating content: ${errorMessage}`, "error");
    }
  };

  return (
    <>
      <ContentUploadButton onDrop={handleCreateContent} onClick={create} />
      <ContentEditorWizard {...contentEditorWizardProps} />

      {chapter?.displayMode === DisplayMode.Web && <OnScreenBackgroundPicker />}
      {chapter?.content
        ?.filter((content) => content.type !== ContentType.Background)
        .map((content) => (
          <ContentItem
            key={content.dateLastModified}
            content={content}
            onUpdate={(updatedContent) => {
              update(updatedContent);
            }}
            onDelete={handleDeleteContent}
          />
        ))}
    </>
  );
};
