import { extendTheme } from "@chakra-ui/react";
import { Button } from "./components/Button";

export const theme = extendTheme({
  styles: {
    global: {
      html: {
        boxSizing: "border-box",
        fontSize: "16px",
        height: "100%",
      },
      "* *:before *:after": {
        boxSizing: "inherit",
      },
      body: {
        height: "100%",
        margin: "0",
      },
      main: {
        width: "100%",
        margin: "0 0",
      },
      "#root": {
        height: "100%",
      },
      "h2.ce-header": {
        fontSize: "24px",
      },
      "h3.ce-header": {
        fontSize: "20px",
      },
      "h4.ce-header": {
        fontSize: "16px",
      },
      ".ce-header": {
        fontWeight: "bold",
      },
      ".chakra-modal__content-container": {
        zIndex: "9999999999999 !important",
      },
      ".ce-toolbar__plus": {
        left: "auto !important",
        right: "-20px",
      },
      ".ce-toolbar__actions": {
        right: "-20px",
        left: "auto !important",
      },
      ".ce-toolbar__settings-btn": {
        left: "auto !important",
        right: "0",
      },
      ".ce-popover": {
        right: "0",
        left: "auto !important",
      },
      ".ce-popover__items": {
        right: "0",
        left: "auto !important",
      },
      ".ce-popover--opened": {
        transform: "translateX(0) scale(1) !important",
      },
      ".codex-editor__redactor": {
        paddingBottom: "20px !important",
      },
    },
  },
  colors: {
    primary: {
      0: "#F5F6F6",
      1: "#EAECED",
      2: "#D5DADC",
      3: "#97A2A7",
      4: "#6D7D84",
      5: "#3D5B66",
      6: "#2E464F",
      7: "#172B32",
      8: "#D6DD88",
      9: "#4D888C",
      10: "#FFC85A",
      11: "#6D7D84",
    },
    secondary: {
      0: "#E6EEEF",
      1: "#C0D5D6",
      2: "#82ACAE",
      3: "#4D888C",
    },
  },
  components: {
    Button,
  },
});
