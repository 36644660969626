import { FC, useEffect, useRef, useState } from "react";
import * as THREE from "three";
import {
  ClampToEdgeWrapping,
  DoubleSide,
  LinearFilter,
  TextureLoader,
  Vector3,
} from "three";
import { useLoader } from "@react-three/fiber";
import { Content, ContentType } from "api/model";

interface Props {
  content: Content;
}

export const ContentImage: FC<Props> = ({ content }) => {
  const texture = useLoader(TextureLoader, content.sources!.main);
  const [scale, setScale] = useState<Vector3>(new Vector3(1, 1, 1));
  const meshRef = useRef<THREE.Mesh>(null);

  useEffect(() => {
    if (!texture) return;
    texture.generateMipmaps = false;
    texture.wrapS = texture.wrapT = ClampToEdgeWrapping;
    texture.minFilter = LinearFilter;
    texture.needsUpdate = true;

    if (content.type === ContentType.Background) {
      const aspectRatio = texture.image.width / texture.image.height;
      if (aspectRatio > 1) {
        setScale(new Vector3(1, 1 / aspectRatio, 1));
      } else {
        setScale(new Vector3(aspectRatio, 1, 1));
      }
    } else {
      setScale(new Vector3(1, 1, 1));
    }
  }, [
    texture.generateMipmaps,
    texture.wrapS,
    texture.wrapT,
    texture.minFilter,
    texture.needsUpdate,
  ]);

  return (
    <mesh ref={meshRef} scale={scale}>
      <planeGeometry attach="geometry" />
      <meshBasicMaterial
        attach="material"
        map={texture}
        transparent={true}
        side={DoubleSide}
      />
    </mesh>
  );
};
