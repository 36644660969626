import React, { FC } from "react";
import {
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Text,
} from "@chakra-ui/react";
import { ResourceLibrary } from "./ResourceLibrary";
import { ResourceCategory } from "pages/Library/resourceCategoryDetails";
import { Anchor, AnchorType } from "api/model";

interface SpatialAnchorTabProps {
  onSuccess: () => void;
  anchor?: Anchor | null;
}

export const SpatialAnchorTab: FC<SpatialAnchorTabProps> = ({
  onSuccess,
  anchor,
}) => {
  if (!anchor) {
    anchor = {};
  }
  anchor.type = AnchorType.Environment;
  return (
    <>
      <ModalHeader>
        Set a Spatial Anchor
        <Text fontSize="md" fontWeight="500">
          Choose your spatial anchor from the content library
        </Text>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <ResourceLibrary
          anchor={anchor}
          category={ResourceCategory.SpatialAnchors}
          onSuccess={onSuccess}
        />
      </ModalBody>
    </>
  );
};
