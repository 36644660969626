import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Html } from "@react-three/drei";
import { Euler, Vector3 } from "three";
import { Content } from "api/model";
import { EDITOR_JS_TOOLS } from "../../ControlPanel/PanelContent/ContentEditorWizard/Website/reactEditorJSTools";
import { createReactEditorJS } from "react-editor-js";
import { EditorCore } from "@react-editor-js/core";

interface Props {
  content: Content;
  isOnScreen?: boolean;
}

export const ContentWebEditor: FC<Props> = ({
  content,
  isOnScreen = false,
}) => {
  const [data, setData] = useState(
    content.configurations?.editorJS?.data || {},
  );
  const EditorJs = createReactEditorJS();
  const editorInstance = useRef<EditorCore | null>(null);
  const editorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setData(content.configurations?.editorJS?.data);
  }, [content.configurations]);

  useEffect(() => {
    if (editorInstance.current) {
      const editor = editorInstance.current;

      const editorMethods = (editor as any)._editorJS;

      if (editorMethods && typeof editorMethods.isReady === "function") {
        editorMethods
          .isReady()
          .then(() => {
            console.log("Editor is ready");
            if (typeof editorMethods.render === "function") {
              editorMethods.render(data);
            } else {
              console.warn("Editor render method is not available");
            }
          })
          .catch((error: any) => {
            console.error("Editor initialization failed:", error);
          });
      } else {
        console.warn("Editor isReady method is not available");
      }
    }
  }, [data]);

  const handleResize = useCallback(() => {
    if (editorRef.current) {
      const canvas = editorRef.current.closest("canvas");
      if (!canvas) return 1;

      const availableWidth = canvas.clientWidth - 20;
      const availableHeight = canvas.clientHeight - 20;

      const contentWidth = editorRef.current.scrollWidth;
      const contentHeight = editorRef.current.scrollHeight;

      const widthScale = availableWidth / contentWidth;
      const heightScale = availableHeight / contentHeight;

      return Math.min(widthScale, heightScale, 1);
    }
    return 1;
  }, []);

  const handleInitialize = useCallback(
    (instance: EditorCore) => {
      editorInstance.current = instance;
      handleResize();
    },
    [handleResize],
  );

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  const rotation = isOnScreen
    ? new Euler(-Math.PI / 2, 0, Math.PI / 2)
    : new Euler(-Math.PI / 2, 0, 0);
  const position = isOnScreen
    ? new Vector3(0, 0, 0.01)
    : ([0, 0.2, 0] as [number, number, number]);
  const scale = isOnScreen ? 0.8 : 0.6;
  const distanceFactor = isOnScreen ? 1.33 : undefined;

  return (
    <Html
      center={true}
      rotation={rotation}
      position={position}
      scale={scale}
      distanceFactor={distanceFactor}
      transform={true}
    >
      <div
        ref={editorRef}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          padding: "10px",
          overflowX: "hidden",
        }}
      >
        <EditorJs
          key={JSON.stringify(data)}
          tools={EDITOR_JS_TOOLS}
          onInitialize={handleInitialize}
          defaultValue={data}
          readOnly={true}
        />
      </div>
    </Html>
  );
};
