import React from "react";
import { IconButton, useBreakpointValue } from "@chakra-ui/react";
import { IoHelpSharp } from "react-icons/io5";

interface HelpIconButtonProps {
  link: string;
  isFloating?: boolean;
}

export const HelpIconButton: React.FC<HelpIconButtonProps> = ({
  link,
  isFloating = false,
}) => {
  const openLinkInNewWindow = () => {
    const width = 700;
    const height = 620;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;
    const windowFeatures = `width=${width},height=${height},left=${left},top=${top}`;

    window.open(link, "HelpWindow", windowFeatures);
  };

  const buttonSize = useBreakpointValue({ base: "md", md: "lg" });
  const bottomPosition = useBreakpointValue({ base: "10px", md: "20px" });
  const rightPosition = useBreakpointValue({ base: "10px", md: "20px" });
  const iconSize = useBreakpointValue({ base: "20px", md: "24px" });

  return (
    <IconButton
      aria-label="Help"
      icon={<IoHelpSharp size={iconSize} />}
      onClick={openLinkInNewWindow}
      position={isFloating ? "fixed" : "relative"}
      bottom={isFloating ? bottomPosition : "12px"}
      right={isFloating ? rightPosition : "16px"}
      bgColor={isFloating ? "#4D888C" : "transparent"}
      color={isFloating ? "white" : "primary.6"}
      borderRadius="50%"
      boxShadow={
        isFloating
          ? "0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.10)"
          : undefined
      }
      size={buttonSize}
      _hover={{ bg: isFloating ? "#367A7C" : "primary.0" }}
      _active={{ bg: isFloating ? "#2A5D5F" : "primary.1" }}
      zIndex={isFloating ? 9999 : undefined}
      transition="all 0.2s"
    />
  );
};
