import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { FiCrop, FiDownload } from "react-icons/fi";
import { BsImage } from "react-icons/bs";
import { FC, useRef, useState, useEffect } from "react";
import {
  useMutationCreateAnchor,
  useMutationDeleteAnchor,
  useMutationUpdateAnchor,
  useQueryChapter,
  useQueryProject,
} from "state/remoteState";
import { useProjectChapterId, useStateActiveAnchor } from "state/localState";
import {
  Configurations,
  initialMarkerConfigurations,
} from "api/Configurations";
import piexif from "piexifjs";
import { AnchorType } from "api/model";
import { useTranslation } from "../../../../../layout/TranslationContext";
import { translatePage } from "../../../../../layout/translatePage";
export const PanelQRCodes = () => {
  //console.log("Rendering ImageMarker");
  const { projectId, chapterId } = useProjectChapterId();
  const chapter = useQueryChapter(projectId, chapterId).data;
  const project = useQueryProject(projectId).data;
  const [activeAnchor, setActiveAnchorId] = useStateActiveAnchor();
  const mutationCreateAnchor = useMutationCreateAnchor();
  const mutationUpdateAnchor = useMutationUpdateAnchor();
  const mutationDeleteAnchor = useMutationDeleteAnchor();
  const {
    isOpen: isOpenUploadImageMarkerModal,
    onOpen: onOpenUploadImageMarkerModal,
    onClose: onCloseUploadImageMarkerModal,
  } = useDisclosure();
  const [selectedAcnorImageURL, setSelectedAcnorImageURL] = useState<any>(null);
  const [file, setFile] = useState<File | null>(null);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [dragActive, setDragActive] = useState(false);
  const [editingMarker, setEditingMarker] = useState(false);
  const [selectedTargetName, setSelectedTargetName] = useState("");
  const [selectedTargetDescription, setSelectedTargetDescription] =
    useState("");
  const [configurationsFromPreview, setConfigurationsFromPreview] =
    useState<Configurations>({});
  const { language, setLanguage } = useTranslation();
  //const hasTranslated = useRef(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  useEffect(() => {
    if (isOpenUploadImageMarkerModal) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  }, [isOpenUploadImageMarkerModal]);

  useEffect(() => {
    if (modalIsOpen && modalRef.current) {
      translatePage(modalRef.current!, language);
    }
  }, [modalIsOpen, language]);
  const handleSelectedTargetNameChange = (e) => {
    setSelectedTargetName(e.target.value);
  };

  const handleSelectedTargetDescriptionChange = (e) => {
    setSelectedTargetDescription(e.target.value);
  };

  const downloadQrCode = (qrCodeUrl) => {
    const link = document.createElement("a");
    link.href = qrCodeUrl;
    link.download = "QRCode_" + chapter?.name + ".png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFileUpload = () => {
    // @ts-ignore
    hiddenFileInput.current.click();
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setSelectedAcnorImageURL(URL.createObjectURL(e.dataTransfer.files[0]));
    }

    setDragActive(false);
  };

  const getBase64 = (file) =>
    new Promise(function (resolve, reject) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleSelecteTargetImageChange = async () => {
    if (
      hiddenFileInput.current === null ||
      hiddenFileInput.current.files === null
    ) {
      return;
    }

    const dataURL = await getBase64(hiddenFileInput.current.files[0]);
    const removedDataUrl = piexif.remove(dataURL);
    setSelectedAcnorImageURL(removedDataUrl);
    setFile(hiddenFileInput.current.files[0]);
  };

  const handleAnchorChange = async () => {
    onCloseUploadImageMarkerModal();
    if (!editingMarker && file) {
      let anchorType = AnchorType.Image;
      if (project?.anchorType === AnchorType.QrCode) {
        anchorType = AnchorType.QrCode;
      }
      mutationCreateAnchor.mutate({
        projectId: projectId!,
        chapterId: chapter!.id!,
        anchor: {
          name: selectedTargetName,
          description: selectedTargetDescription,
          configurations: configurationsFromPreview,
          type: anchorType,
        },
        file: file,
      });
    } else {
      const anchorUpdate = {
        ...activeAnchor,
        name: selectedTargetName,
        description: selectedTargetDescription,
      };
      anchorUpdate.configurations = {
        ...activeAnchor?.configurations,
        ...configurationsFromPreview,
      };
      mutationUpdateAnchor.mutate({
        projectId: projectId!,
        chapterId: chapter!.id!,
        anchor: anchorUpdate,
        file: file ?? undefined,
      });
    }
  };

  const removeSelectedTargetAction = async (targetIdToRemove: string) => {
    mutationDeleteAnchor.mutate({
      projectId: projectId!,
      chapterId: chapter!.id!,
      anchorId: targetIdToRemove,
    });
  };

  const hansleSetConfigurations = (configurations) => {
    setConfigurationsFromPreview(configurations);
  };

  return (
    <>
      <Modal
        isOpen={isOpenUploadImageMarkerModal}
        onClose={onCloseUploadImageMarkerModal}
        size="3xl"
        initialFocusRef={modalRef}
      >
        <ModalOverlay />
        <ModalContent ref={modalRef} p="8">
          <ModalHeader>
            {project?.anchorType === AnchorType.Image && (
              <>Set an Image Marker</>
            )}
            {project?.anchorType === AnchorType.QrCode && <>Set a QR Code</>}
            <Text fontSize="md" fontWeight="500">
              Upload your image and fill in the information
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns="1fr 1fr" gap="4">
              <GridItem>
                {selectedAcnorImageURL ? (
                  <Box
                    position="relative"
                    onClick={handleFileUpload}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                    cursor={"pointer"}
                  >
                    <Box
                      borderRadius="6"
                      width="100%"
                      height="100%"
                      objectFit="cover"
                    >
                      <ImageMarkerPreview
                        src={selectedAcnorImageURL}
                        setConfigurations={hansleSetConfigurations}
                      />
                    </Box>
                    <IconButton
                      aria-label="delete image"
                      icon={<FiCrop />}
                      position="absolute"
                      top="10px"
                      right="10px"
                      backgroundColor="primary.0"
                      color="black"
                      borderRadius="50%"
                      _hover={{ backgroundColor: "primary.0" }}
                      onClick={() => console.log("crop image")}
                    />
                    <Input
                      type="file"
                      style={{ display: "none" }}
                      ref={hiddenFileInput}
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={handleSelecteTargetImageChange}
                    />
                  </Box>
                ) : (
                  <>
                    <Button
                      position="relative"
                      width="100%"
                      height="100%"
                      backgroundColor="primary.0"
                      borderRadius="6"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      _hover={{ backgroundColor: "primary.0" }}
                      onClick={handleFileUpload}
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={handleDrop}
                    >
                      <Flex direction="column" align="center">
                        {!dragActive ? (
                          <>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              backgroundColor="primary.1"
                              w="50px"
                              h="50px"
                              borderRadius="50"
                            >
                              <Icon
                                as={BsImage}
                                color="primary.6"
                                boxSize="6"
                              />
                            </Box>
                            <Text color="primary.6" mt="2">
                              Upload Image
                            </Text>
                            <Text color="primary.4" mt="1">
                              Supports: JPG, JPEG, PNG
                            </Text>
                          </>
                        ) : (
                          <Text color="primary.6">Drop here</Text>
                        )}
                      </Flex>
                    </Button>
                    <Input
                      type="file"
                      style={{ display: "none" }}
                      ref={hiddenFileInput}
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={handleSelecteTargetImageChange}
                    />
                  </>
                )}
              </GridItem>
              <Flex direction="column" gap="4">
                <Alert
                  status="info"
                  color="secondary.2"
                  backgroundColor="rgba(77, 136, 140, 0.1)"
                  border="1px solid #4D888C"
                  borderRadius="8"
                >
                  <AlertIcon color="secondary.2" />
                  <Text fontWeight="500" fontSize="xs">
                    You can upload one or more image markers, which will lead to
                    the same content.
                  </Text>
                </Alert>
                <Flex align="center">
                  <Text
                    fontSize="sm"
                    fontWeight="600"
                    width="40%"
                    color="primary.4"
                  >
                    Marker Name
                  </Text>
                  <Input
                    placeholder="Image Marker 01"
                    borderRadius="32"
                    size="sm"
                    onChange={handleSelectedTargetNameChange}
                    value={selectedTargetName}
                  />
                </Flex>
                <Flex direction="column" gap="2">
                  <Text fontSize="sm" fontWeight="600" color="primary.4">
                    Description
                  </Text>
                  <Textarea
                    placeholder="Is there something special about the marker?"
                    onChange={handleSelectedTargetDescriptionChange}
                    value={selectedTargetDescription}
                  />
                </Flex>
              </Flex>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="primary"
              onClick={handleAnchorChange}
              isDisabled={!selectedAcnorImageURL}
            >
              Next
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex direction="column" gap="2" textAlign="center">
        {!chapter?.anchors || chapter!.anchors!.length == 0 ? (
          <Flex
            align="center"
            border="1px dashed #E2E8F0"
            p="4"
            borderRadius="4px"
            width="100%"
          >
            <Text textAlign="center" fontWeight="500" color="primary.4">
              Your QR Code will be generated after you have created content
            </Text>
          </Flex>
        ) : null}
        {chapter?.anchors &&
          chapter?.anchors.map((anchor, i) => {
            return (
              <Flex
                key={anchor.id}
                borderRadius="10"
                border="1px solid #E2E8F0"
                p="4"
                direction="row"
                align="center"
                width="100%"
                gap="4"
              >
                <Box flex="0 0 auto" alignSelf="start">
                  <div>
                    <Image
                      src={anchor.sources!.main!}
                      width="100px"
                      height="100%"
                      objectFit="cover"
                    />
                  </div>
                </Box>
                <Box flex="1" textAlign="left">
                  <Text color="primary.6" fontWeight="600" fontSize="sm">
                    Download QR Code
                  </Text>
                  <Text color="primary.4" fontWeight="600" fontSize="sm">
                    {anchor.description}
                  </Text>
                </Box>

                <Box flex="0 0 auto">
                  <Flex direction="column" align="center">
                    <div key={anchor.id}>
                      <IconButton
                        aria-label="Download QR code"
                        icon={<FiDownload />}
                        variant="outline"
                        size="md"
                        onClick={() => downloadQrCode(anchor.sources!.main)}
                      />
                    </div>
                  </Flex>
                </Box>
              </Flex>
            );
          })}
      </Flex>
    </>
  );
};

interface ImageMarkerPreviewProps {
  src: string;
  setConfigurations: (configurations: Configurations) => void;
}

const ImageMarkerPreview: FC<ImageMarkerPreviewProps> = ({
  src,
  setConfigurations,
}) => {
  return (
    <Image
      src={src}
      onLoad={(e) => {
        const img = e.target as HTMLImageElement;
        const size = { x: img.naturalWidth, y: img.naturalHeight };
        setConfigurations(initialMarkerConfigurations(size));
      }}
    />
  );
};
