import {
  Box,
  Button,
  Grid,
  InputGroup,
  InputLeftAddon,
  NumberInput,
  NumberInputField,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import {
  FieldPath,
  UseFormSetValue,
  UseFormWatch,
  useForm,
} from "react-hook-form";
import {
  updateContentLocally,
  useCurrentChapter,
  useMutationUpdateContent,
} from "state/remoteState";
import { FC, useMemo, useState } from "react";
import { Content } from "api/model";
import { useProjectChapterId } from "state/localState";
import { Configurations, XYZ } from "api/Configurations";
import { useQueryClient } from "@tanstack/react-query";
import { useDebouncedCallback } from "use-debounce";

interface TransformInputsProps {
  content: Content;
}

interface TransformationMatrix {
  position: XYZ;
  rotation: XYZ;
  scale: XYZ;
}

export const TransformInputs: FC<TransformInputsProps> = ({ content }) => {
  const { projectId, chapterId } = useProjectChapterId();
  const { data: chapter } = useCurrentChapter();
  const queryClient = useQueryClient();
  const mutationUpdateContent = useMutationUpdateContent();
  const updateContentDebounced = useDebouncedCallback((content: Content) => {
    const contentId = content.id!; 

    mutationUpdateContent.mutate({ projectId, chapterId, contentId, content });
  }, 500);

  const [isLargerThan1000] = useMediaQuery("(min-width: 1000px)");

  const transformationMatrix = useMemo(() => {
    if (!chapter) return;
    const c = content.configurations! as Configurations;
    return {
      position: { ...c.position! },
      rotation: { ...c.rotation! },
      scale: { ...c.scale! },
    };
  }, [chapter?.orientation, content]);

  const { watch, setValue, reset, getValues } = useForm<TransformationMatrix>({
    values: transformationMatrix ?? ({} as TransformationMatrix),
  });

  const handleResetClick = () => {
    if (!chapter) return;
    const c = content.configurations! as Configurations;
    reset(c.initial);
    onChange();
  };

  const onChange = () => {
    if (!content) return;
    const updatedContent = { ...content };
    let data = getValues();
    updatedContent.configurations!.position = data.position;
    updatedContent.configurations!.scale = data.scale;
    updatedContent.configurations!.rotation = data.rotation;
    updateContentLocally(queryClient, projectId, chapterId, updatedContent);
    updateContentDebounced(updatedContent);
  };

  return (
    <VStack align="left" spacing={isLargerThan1000 ? "4" : "3"} mt="5">
      <Box>
        <Text fontSize="sm" pb="2">
          Position
        </Text>
        <Grid
          templateColumns={
            isLargerThan1000 ? "repeat(3, minmax(50px, 1fr))" : "repeat(3, 1fr)"
          }
          gap={isLargerThan1000 ? "2" : "1"}
        >
          {[
            { caption: "X", name: "position.x" },
            { caption: "Y", name: "position.y" },
            { caption: "Z", name: "position.z" },
          ].map((x) => (
            <NumberInputGroup
              key={x.name}
              watch={watch}
              setValue={setValue}
              onChange={onChange}
              caption={x.caption}
              name={x.name as FieldPath<TransformationMatrix>}
              step={0.1}
            />
          ))}
        </Grid>
      </Box>
      <Box>
        <Text fontSize="sm" pb="2">
          Rotation
        </Text>
        <Grid
          templateColumns={
            isLargerThan1000 ? "repeat(3, minmax(50px, 1fr))" : "repeat(3, 1fr)"
          }
          gap={isLargerThan1000 ? "2" : "1"}
        >
          {" "}
          {[
            { caption: "X", name: "rotation.x" },
            { caption: "Y", name: "rotation.y" },
            { caption: "Z", name: "rotation.z" },
          ].map((x) => (
            <NumberInputGroup
              key={x.name}
              watch={watch}
              setValue={setValue}
              onChange={onChange}
              caption={x.caption}
              name={x.name as FieldPath<TransformationMatrix>}
              step={10}
            />
          ))}
        </Grid>
      </Box>
      <Box>
        <Text fontSize="sm" pb="2">
          Scale
        </Text>
        <Grid
          templateColumns={
            isLargerThan1000 ? "repeat(3, minmax(50px, 1fr))" : "repeat(3, 1fr)"
          }
          gap={isLargerThan1000 ? "2" : "1"}
        >
          {" "}
          {[
            { caption: "W", name: "scale.x" },
            { caption: "H", name: "scale.y" },
            { caption: "D", name: "scale.z" },
          ].map((x) => (
            <NumberInputGroup
              key={x.name}
              watch={watch}
              setValue={setValue}
              onChange={onChange}
              caption={x.caption}
              name={x.name as FieldPath<TransformationMatrix>}
              step={0.2}
            />
          ))}
        </Grid>
      </Box>
      <Button
        variant="link"
        fontWeight="600"
        color="#4D888C"
        _hover={{ color: "gray.400" }}
        alignSelf="flex-start"
        px="0"
        onClick={handleResetClick}
      >
        Reset
      </Button>
      {/* TODO: add bounding box checkbox */}
      {/* <Checkbox colorScheme="gray">Show bounding box</Checkbox> */}
    </VStack>
  );
};

// The component is a bit complicated. It requires the setValue function
// to be passed because we cannot register the field on the
// NumberInput component due to some props incompatibility.
const NumberInputGroup = ({
  name,
  watch,
  setValue,
  onChange,
  caption,
  step,
}: {
  name: FieldPath<TransformationMatrix>;
  watch: UseFormWatch<TransformationMatrix>;
  setValue: UseFormSetValue<TransformationMatrix>;
  onChange: any;
  caption: string;
  step: number;
}) => {
  const [invalidValue, setInvalidValue] = useState<string>();

  const handleChange = (valueAsString: string, valueAsNumber: number) => {
    console.log(isNaN(valueAsNumber), valueAsNumber, valueAsString);
    if (!canBeConvertedToNumber(valueAsString)) {
      setInvalidValue(valueAsString);
      return;
    }
    setInvalidValue(undefined);
    setValue(name, valueAsNumber);
    onChange();
  };

  return (
    <>
      <InputGroup>
        <InputLeftAddon p="2" backgroundColor="white">
          {caption}
        </InputLeftAddon>
        <NumberInput
          name={name}
          onChange={handleChange}
          step={step}
          value={invalidValue ?? (watch(name) as number)}
          isInvalid={!!invalidValue}
        >
          <NumberInputField
            borderTopLeftRadius="0"
            borderBottomLeftRadius="0"
            p="0"
            paddingLeft="2"
            paddingRight="30px"
          />
        </NumberInput>
      </InputGroup>
    </>
  );
};

function canBeConvertedToNumber(value: string): boolean {
  // Check if the string is empty
  if (value.trim() === "") {
    return false;
  }
  // Check if the trimmed string ends with a dot
  if (value.trim().endsWith(".")) {
    return false;
  }

  // Check if the string can be converted to a number
  return !isNaN(parseFloat(value)) && isFinite(parseFloat(value));
}
