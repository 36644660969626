import React, { useState, useEffect, forwardRef } from "react";
import { VStack, Flex, Select, Box, useMediaQuery } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import { useBreakpointValue } from "@chakra-ui/react";
import { useTranslation } from '../../layout/TranslationContext';
import { translatePage } from '../../layout/translatePage';
interface CustomInputProps {
  value: Date | string;
  onClick: () => void;
  placeholder: string;
  isWidthBetween1000And1200: boolean;
  isWidthBetween900And1000: boolean;
}

interface DateRangePickerProps {
  timeRange: string;
  start: Date | null;
  end: Date | null;
  handleChangeTimeRange: (time: string) => void;
  handleChangeStart: (date: Date) => void;
  handleChangeEnd: (date: Date) => void;
}

const CustomDatePicker: React.FC<DateRangePickerProps> = ({
  timeRange,
  start,
  end,
  handleChangeTimeRange,
  handleChangeStart,
  handleChangeEnd,
}) => {
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(
    start,
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(end);
  const [selectedTimeRange, setSelectedTimeRange] = useState<string>(timeRange);

  const [isWidthBetween1000And1200] = useMediaQuery("(min-width: 1000px) and (max-width: 1200px)");//or maybe 1000 is inbetween
  const [isWidthBetween900And1000] = useMediaQuery("(min-width: 800px) and (max-width: 990px)");
  const [isLargerThan1300] = useMediaQuery("(min-width: 1300px)")
  const handleStartDateChange = (date: Date) => {
    setSelectedStartDate(date);
    handleChangeStart(date);
  };
  const handleEndDateChange = (date: Date) => {
    setSelectedEndDate(date);
    handleChangeEnd(date);
  };
  const handleTimeRangeChange = (time: string) => {
    setSelectedTimeRange(time);
    handleChangeTimeRange(time);
  };
  const { language, setLanguage } = useTranslation();
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  useEffect(() => {
    translatePage(document.body, language);
  }, []);
  // const isSmallScreen = useBreakpointValue({ base: true, md: false });
  // console.log(isSmallScreen)
  return (
<VStack
  style={{
    marginLeft: "50px",
    maxHeight: "5px",
    marginTop: "-10px",
    marginBottom: "20px",
  }}
  alignItems="flex-start"
  justifyContent="space-between"
>
  <Flex
    justifyContent="flex-start"
    alignItems="center"
    gap={isWidthBetween1000And1200 ? "100px" : isWidthBetween900And1000 ? "40px" : "120px"} 
    flexWrap="wrap"
  >
    <Box
      position="relative"
      width={isWidthBetween900And1000 ? "90px": isWidthBetween1000And1200 ? "100px" : "150px"}
      marginLeft={isWidthBetween900And1000 ? "30px" : isWidthBetween1000And1200 ? "70px" : "150px"}
      marginRight={isWidthBetween900And1000 ? "30px": isWidthBetween1000And1200 ? "80px" : "120px"}
    >
      <Select
        value={selectedTimeRange || ""}
        onChange={(e) => handleTimeRangeChange(e.target.value)}
        style={{
          width: isWidthBetween1000And1200 ? "130px" : "150px",
          height: "25px",
          borderRadius: "4px",
          fontSize: "1rem",
          marginLeft: isWidthBetween900And1000 || isWidthBetween1000And1200 ? "-40px" : "10px",
          marginRight: "10px",
          padding: "0 20px 0 10px",
          borderColor: "black",
          boxShadow: "none",
        }}
        focusBorderColor="black"
      >
        <option value="All Statistics">All Statistics</option>
        <option value="Last Week">Last Week</option>
        <option value="Last Month">Last Month</option>
        <option value="Last 3 Months">Last 3 Months</option>
        <option value="Last 6 Months">Last 6 Months</option>
        <option value="Last Year">Last Year</option>
      </Select>
    </Box>

    {!timeRange || timeRange === "All Statistics" ? (
      <Flex
      marginRight="30px"
      gap={isWidthBetween900And1000 ? "0px": "20px"}
      flexWrap="wrap"
      >
        <Box
          position="relative"
          width={isWidthBetween900And1000 ? "50px" : isWidthBetween1000And1200 ? "80px" : "130px"}
          //marginRight={isWidthBetween1000And1200 ? "100px" : "50px" }
          // marginRight="50px"
        >
          <DatePicker
            selected={selectedStartDate}
            onChange={handleStartDateChange}
            placeholderText="Start Date"
            dateFormat="MMMM d, yyyy"
            customInput={
              <CustomInput
                value={selectedStartDate || ""}
                onClick={() => {}}
                placeholder={selectedStartDate?.toString() || ""}
                isWidthBetween1000And1200={isWidthBetween1000And1200}
                isWidthBetween900And1000={isWidthBetween900And1000}
              />
            }
          />
        </Box>

        <Box
          position="relative"
          width={isWidthBetween1000And1200 ? "80px" : "150px"}
          marginLeft={isWidthBetween1000And1200 ? "30px" : "50px"}
          marginRight="-50px"
        >
          <DatePicker
            selected={selectedEndDate}
            onChange={handleEndDateChange}
            placeholderText="End Date"
            dateFormat="MMMM d, yyyy"
            customInput={
              <CustomInput
                value={selectedEndDate || ""}
                onClick={() => {}}
                placeholder={selectedEndDate?.toString() || ""}
                isWidthBetween1000And1200={isWidthBetween1000And1200}
                isWidthBetween900And1000={isWidthBetween900And1000}
              />
            }
          />
        </Box>
      </Flex>
    ) : null}
  </Flex>
</VStack>
  );
};

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
  (props, ref) => {
    const { isWidthBetween1000And1200, isWidthBetween900And1000, ...rest } = props;

    return (
      <input
        {...rest}
        ref={ref}
        value={
          props.value instanceof Date
            ? props.value.toLocaleDateString()
            : props.value.toString()
        }
        onClick={props.onClick}
        style={{
          width: isWidthBetween900And1000 ? "90px" : isWidthBetween1000And1200 ? "110px" : "150px",
          height: "25px",
          border: "1px solid black",
          borderRadius: "4px",
          padding: "0.5rem",
        }}
        placeholder={props.placeholder}
      />
    );
  }
);

CustomInput.displayName = "CustomInput";

export default CustomDatePicker;
