import {
  Flex,
  Grid,
  GridItem,
  Icon,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";

import { AiFillCheckCircle } from "react-icons/ai";
import { BiCamera } from "react-icons/bi";
import { MdPhoneIphone } from "react-icons/md";
import {
  updateChapterLocally,
  useMutationUpdateChapter,
  useQueryChapter,
  useQueryProject,
} from "state/remoteState";
import { AnchorType, Container, DisplayMode, ProjectBean } from "api/model";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useProjectChapterId } from "state/localState";
import { useQueryClient } from "@tanstack/react-query";
import { useDebouncedCallback } from "use-debounce";

type ChapterDetailsForm = {
  name?: string | null;
  description?: string | null;
};
interface PanelChapterDetailsProps {
  project: ProjectBean;
  chapter: Container;
  isChanges: (isChanged: boolean) => void;
}

export const PanelChapterDetails: FC<PanelChapterDetailsProps> = ({
  isChanges,
}) => {
  const { projectId, chapterId } = useProjectChapterId();
  const chapter = useQueryChapter(projectId, chapterId).data;
  const project = useQueryProject(projectId).data;
  const queryClient = useQueryClient();
  const mutationUpdateChapter = useMutationUpdateChapter();
  const updateChapterDebounced = useDebouncedCallback((chapter: Container) => {
    mutationUpdateChapter.mutate({ projectId, chapter });
  }, 300);

  const { register, watch } = useForm<ChapterDetailsForm>({
    defaultValues: {
      name: chapter?.name ?? "",
      description: chapter?.description ?? "",
    },
  });

  useEffect(() => {
    const subscription = watch((formValues) => {
      const updatedChapter = { ...chapter, ...formValues };
      updateChapterLocally(queryClient, projectId, updatedChapter);
      updateChapterDebounced(updatedChapter);
      isChanges(true);
    });
    return () => subscription.unsubscribe();
  }, [watch, chapter, isChanges]);

  const updateDisplayMode = (displayMode: DisplayMode) => {
    if (!chapter || chapter.displayMode === displayMode) return;
    mutationUpdateChapter.mutate({
      projectId,
      chapter: { ...chapter, displayMode: displayMode },
    });

    isChanges(true);
  };

  if (!chapter) return null;

  return (
    <Flex direction="column" gap="3">
      <Flex gap="8" align="center">
        <Text whiteSpace="nowrap" fontSize="sm" fontWeight="medium">
          Chapter name
        </Text>
        <Input
          placeholder="New Chapter"
          borderRadius="full"
          size="sm"
          {...register("name")}
        />
      </Flex>
      <Flex direction="column" gap="2">
        <Text whiteSpace="nowrap" fontSize="sm" fontWeight="medium">
          Description
        </Text>
        <Textarea
          placeholder="What is this chapter about?"
          {...register("description")}
        />
      </Flex>
      {project?.anchorType === AnchorType.Image && (
        <Flex direction="column" gap="2">
          <Text whiteSpace="nowrap" fontSize="sm" fontWeight="medium">
            Display mode
          </Text>
          <Grid width="100%" gap="2" templateColumns="1fr 1fr">
            <GridItem>
              <button
                onClick={() => updateDisplayMode(DisplayMode.AR)}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  border: "1px solid #2E464F",
                  borderRadius: "4px",
                  padding: "3px",
                  backgroundColor: "#F5F6F6",
                  position: "relative",
                  width: "100%",
                  height: "120px",
                }}
              >
                {chapter.displayMode === DisplayMode.AR && (
                  <Icon
                    as={AiFillCheckCircle}
                    position="absolute"
                    right="5%"
                    top="5%"
                    color="primary.6"
                  />
                )}
                <Flex
                  direction="column"
                  align="flex-start"
                  gap="2"
                  p="16px 8px"
                >
                  <Flex align="center" gap="2">
                    <Icon as={BiCamera} w={6} h={6} color="primary.6" />
                    <Text
                      whiteSpace="nowrap"
                      fontSize="sm"
                      fontWeight="bold"
                      data-nontranslatable="AR"
                    >
                      AR
                    </Text>
                  </Flex>
                  <Text
                    fontWeight="medium"
                    fontSize="sm"
                    color="primary.4"
                    textAlign="left"
                  >
                    See content via camera in a room
                  </Text>
                </Flex>
              </button>
            </GridItem>
            <GridItem>
              <button
                onClick={() => {
                  updateDisplayMode(DisplayMode.Web);
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  border: "1px solid #2E464F",
                  borderRadius: "4px",
                  padding: "3px",
                  backgroundColor: "#F5F6F6",
                  position: "relative",
                  width: "100%",
                  height: "120px",
                }}
              >
                {chapter.displayMode === DisplayMode.Web && (
                  <Icon
                    as={AiFillCheckCircle}
                    position="absolute"
                    right="5%"
                    top="5%"
                    color="primary.6"
                  />
                )}
                <Flex
                  direction="column"
                  align="flex-start"
                  gap="2"
                  p="16px 8px"
                >
                  <Flex align="center" gap="2">
                    <Icon as={MdPhoneIphone} w={6} h={6} color="primary.6" />
                    <Text
                      whiteSpace="nowrap"
                      fontSize="sm"
                      fontWeight="bold"
                      data-nontranslatable="On Screen"
                    >
                      On Screen
                    </Text>
                  </Flex>
                  <Text
                    fontWeight="medium"
                    fontSize="sm"
                    color="primary.4"
                    textAlign="left"
                  >
                    See content in a screen layout
                  </Text>
                </Flex>
              </button>
            </GridItem>
          </Grid>
        </Flex>
      )}
    </Flex>
  );
};
