import React, { useEffect, useState, useRef } from "react";
import {
  UserBean,
  SimpleUserBean,
  UserGroup,
  ProjectListBean,
} from "api/model";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  SimpleGrid,
} from "@chakra-ui/react";
import {
  Table,
  TableContainer,
  Thead,
  Th,
  Tr,
  Td,
  Tbody,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Heading,
  Box,
  useDisclosure,
  Text,
  IconButton,
  useToast,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  ModalCloseButton,
  ModalBody,
  Grid,
  FormControl,
  FormLabel,
  Input,
  Select,
  Link,
  useMediaQuery,
} from "@chakra-ui/react";
import { BsThreeDotsVertical, BsPencil, BsTrash } from "react-icons/bs";
import {
  useQueryMe,
  useQueryProjects,
  useQueryUser,
  useQueryUsers,
  useMutationDeleteUser,
  useMutationCreateUserGroup,
  useQueryUsersGroups,
  useMutationUpdateUserGroup,
  useMutationDeleteUserGroup,
  useMutationUpdateUsers,
} from "state/remoteState";
import { theme } from "theme/theme";
import { useTranslation } from "../../layout/TranslationContext";
import { translatePage, translateText } from "../../layout/translatePage";
export const Users = () => {
  const {
    isOpen: isNewUserGroupOpen,
    onOpen: onNewUserGroupOpen,
    onClose: onNewUserGroupClose,
  } = useDisclosure();
  const {
    isOpen: isEditUserGroupOpen,
    onOpen: onEditUserGroupOpen,
    onClose: onEditUserGroupClose,
  } = useDisclosure();
  const {
    isOpen: isDeletUserGroupOpen,
    onOpen: onDeleteUserGroupOpen,
    onClose: onDeleteUserGroupClose,
  } = useDisclosure();
  const {
    isOpen: isManageGroupUsersOpen,
    onOpen: onManageGroupUsersOpen,
    onClose: onManageGroupUsersClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteUserInGroupOpen,
    onOpen: onDeleteUserInGroupOpen,
    onClose: onDeleteUserInGroupClose,
  } = useDisclosure();
  const { data: me } = useQueryMe();
  const { data: projects } = useQueryProjects();
  const [editUser, setEditUser] = useState<UserBean | undefined>();
  const [newUserGroup, setNewUserGroup] = useState<UserGroup>();
  const [newUserGroupName, setNewUserGroupName] = useState<string>("");
  const [userGroupMember, setUserGroupMember] = useState<SimpleUserBean>({});
  const [userToUpdate, setUserToUpdate] = useState<UserBean | undefined>();
  const [invalidCharacters, setInvalidCharacters] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [exist, isExist] = useState(false);
  const [only, isOnly] = useState(false);
  const [editUsers, setEditUsers] = useState<UserBean[]>([]);
  const [created, setCreated] = useState(false);
  const [projectsPublishedToGroups, setProjectsPublishedToGroups] = useState<
    ProjectListBean[]
  >([]);
  const toast = useToast();
  //const [selectedRole, setSelectedRole] = useState<string>(""); may be used after
  const { data: users, refetch: refetchUsers } = useQueryUsers(
    me!.subscriptions!.at(0)!.id!,
  );
  const { data: user } = useQueryUser(
    me!.subscriptions!.at(0)!.id!,
    editUser?.id!,
  );
  const [toUpdate, setToUpdate] = useState(false);
  const { data: userGroups, refetch: refetchUserGroups } = useQueryUsersGroups(
    me!.subscriptions!.at(0)!.id!,
  );
  const deleteUserMutation = useMutationDeleteUser();
  const mutationCreateUserGroup = useMutationCreateUserGroup();
  const mutationUpdateUserGroup = useMutationUpdateUserGroup();
  const mutationDeleteUserGroup = useMutationDeleteUserGroup();
  const mutationUpdateUsers = useMutationUpdateUsers();
  const subscriptionId = me!.subscriptions!.at(0)!.id!;
  const { language, setLanguage } = useTranslation();
  const hasTranslated = useRef(false);

  const modalRef = useRef<HTMLDivElement>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  useEffect(() => {
    const sessionKey = `translated_${language}`;
    if (!sessionStorage.getItem(sessionKey)) {
      translatePage(document.body, language);
      sessionStorage.setItem(sessionKey, "true");
      hasTranslated.current = true;
    } else {
      translatePage(document.body, language);
    }
  }, [language]);

  useEffect(() => {
    if (
      isDeleteUserInGroupOpen ||
      isManageGroupUsersOpen ||
      isDeletUserGroupOpen ||
      isEditUserGroupOpen ||
      isNewUserGroupOpen
    ) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
    if (modalIsOpen && modalRef.current) {
      translatePage(modalRef.current!, language);
    }
  }, [
    isDeleteUserInGroupOpen,
    isManageGroupUsersOpen,
    isDeletUserGroupOpen,
    isEditUserGroupOpen,
    isNewUserGroupOpen,
    modalIsOpen,
    language,
  ]);

  //translate Notif
  const showTranslatedToast = async (message: string, language: string) => {
    const translatedMessage = await translateText(message, language);
    toast({
      title: translatedMessage,
    });
  };

  refetchUsers();
  refetchUserGroups();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const invalidRegex = /[=!@#$%^&*(),.?":{}|<>]/;
    const containsInvalidCharacters = invalidRegex.test(newValue);
    const isEmpty = newValue.trim() === "";

    setNewUserGroupName(newValue);
    setInvalidCharacters(containsInvalidCharacters || isEmpty);
    invalidCharacters === isEmpty
      ? showTranslatedToast("User Group name cannot be empty", language)
      : invalidCharacters === containsInvalidCharacters
        ? showTranslatedToast(
            "User Group name contains unacceptable characters",
            language,
          )
        : null;
  };
  const [isLargerThan1000] = useMediaQuery("(min-width: 1000px)");
  const [isLongerThan1000] = useMediaQuery("(min-height: 1000px)");
  const [isWidthBetween950And1200] = useMediaQuery("(min-width: 950px) and (max-width: 1190px)");
  const [isLargerThan1300]  =  useMediaQuery("(min-width: 1300px)");

  // const handleUpdateUser = () => {
  //   if (editUser) {
  //     updateUserMutation.mutate({
  //       user: { ...editUser },
  //     });
  //   }
  //   onEditUserClose();
  //   setUpdated(true);
  // };

  // const handleDeleteUser = () => {
  //   if (editUser) {
  //     deleteUserMutation.mutate({
  //       subscriptionId: me!.subscriptions![0],
  //       userId: editUser.id!,
  //     });
  //   }
  // };
  useEffect(() => {
    if (newUserGroup?.users?.length === 1) {
      isOnly(true);
    } else {
      isOnly(false);
    }
  }, [newUserGroup?.users]);

  const handleCreateUserGroup = () => {
    setCreated(true);
    if (!newUserGroupName) {
      showTranslatedToast("User Group name cannot be empty.", language);
      return;
    }

    if (!newUserGroup) {
      showTranslatedToast(
        "User Group information is incomplete. Cannot create.",
        language,
      );
      return;
    }

    const subscriptionId = me!.subscriptions!.at(0)?.id;
    if (!subscriptionId) {
      showTranslatedToast(
        "Subscription ID is missing. Cannot create user group.",
        language,
      );
      return;
    }

    if (!newUserGroup.users || newUserGroup.users.length === 0) {
      showTranslatedToast(
        "User Group must contain at least one user.",
        language,
      );
      return;
    }

    mutationCreateUserGroup.mutate(
      {
        subscriptionId,
        userGroup: {
          name: newUserGroupName,
          users: newUserGroup.users,
        },
      },
      {
        onSuccess: () => {
          showTranslatedToast("User group created successfully.", language);
          onNewUserGroupClose();
        },
        onError: (error) => {
          showTranslatedToast("Failed to create user group", language);
        },
      },
    );
  };

  const handleSaveMember = () => {
    if (!userGroupMember || userGroupMember.email === undefined) {
      showTranslatedToast(
        "UserGroupMember is undefined. Cannot add.",
        language,
      );
      return;
    }

    setNewUserGroup((prev) => {
      if (!prev) {
        return {
          name: newUserGroupName,
          users: [userGroupMember],
        };
      }

      setEditUser(userGroupMember);
      const existingUsers = prev.users ?? [];

      if (existingUsers.find((u) => u.email === userGroupMember.email)) {
        showTranslatedToast(
          "User already belongs to this User Group and cannot be re-added.",
          language,
        );
        return {
          ...prev,
        };
      }
      const usersEmails = [...existingUsers, userGroupMember].map(
        (user) => user.email,
      );

      const usersToUpdate = users!.filter((user) =>
        usersEmails.includes(user.email),
      );

      setEditUsers(
        usersToUpdate.map((user) => ({
          ...user,
        })),
      );

      return {
        ...prev,
        users: [...existingUsers, userGroupMember],
      };
    });

    setUserGroupMember({});
  };

  const handleUserChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedUserName = e.target.value;

    const selectedUser = users?.find((user) => user.name === selectedUserName);

    if (selectedUser) {
      setUserGroupMember(selectedUser);
    }
  };

  const onEditUserGroup = (userGroup: UserGroup) => {
    !userGroup.name ? setNewUserGroupName(userGroup.name!) : null;
    setNewUserGroup(userGroup);
    onEditUserGroupOpen();
  };
  const handleUpdateUserGroupName = () => {
    if (!newUserGroupName) {
      showTranslatedToast(
        "User Group information is incomplete. Cannot update.",
        language,
      );
      return;
    }
    if (newUserGroup) {
      mutationUpdateUserGroup.mutate({
        subscriptionId,
        userGroupId: newUserGroup.id!,
        userGroup: { ...newUserGroup },
      });
    }

    onEditUserGroupClose();
  };
  const handleUpdateUserGroup = () => {
    if (newUserGroup) {
      mutationUpdateUserGroup.mutate({
        subscriptionId,
        userGroupId: newUserGroup.id!,
        userGroup: { ...newUserGroup },
      });
    }

    onEditUserGroupClose();
    onDeleteUserInGroupClose();
    onManageGroupUsersClose();
    setUserGroupMember({});
    newUserGroup!.users = [];
    setUserToUpdate(undefined);
  };

  const onDeleteUserGroup = (userGroup: UserGroup) => {
    setNewUserGroup(userGroup);
    setToUpdate(true);
    onDeleteUserGroupOpen();
  };

  useEffect(() => {
    if (newUserGroup?.id && projects) {
      const exists = projects.some((project) =>
        project.userGroupsIds?.includes(newUserGroup.id!),
      );
      isExist(exists);
      if (exists) {
        const projectsPublishedToThisUG = projects.filter((project) =>
          project.userGroupsIds?.includes(newUserGroup.id!),
        );
        setProjectsPublishedToGroups(projectsPublishedToThisUG);
        console.log(
          "Projects that include this user group:",
          projectsPublishedToThisUG,
        );
      }
    }
  }, [newUserGroup, projects]);

  // useEffect(() => {
  //   if (newUserGroup && toUpdate && users) {
  //     const updatedUsers = users.map((user) => {
  //       if (newUserGroup.users?.some((u) => u.email === user.email)) {
  //         const ugs =
  //           user.userGroups?.filter((item) => item !== newUserGroup.id) || [];
  //         return { ...user, userGroups: ugs };
  //       }
  //       return user;
  //     });
  //     setEditUsers(
  //       updatedUsers.filter((user) =>
  //         newUserGroup.users?.some((u) => u.email === user.email),
  //       ),
  //     );
  //   }
  // }, [newUserGroup, toUpdate, users]);

  const handleDeleteUserGroup = () => {
    if (newUserGroup) {
      mutationDeleteUserGroup.mutate({
        subscriptionId,
        userGroupId: newUserGroup.id!,
      });
    }
    setToUpdate(true);
    onDeleteUserGroupClose();
  };

  const onEditUserGroupUsers = (userGroup: UserGroup) => {
    setNewUserGroup(userGroup);
    setNewUserGroupName(userGroup.name!);
    onManageGroupUsersOpen();
  };

  const onDeleteUserFromGroup = (user: UserBean) => {
    setEditUser(user);
    const updatedUsers = newUserGroup?.users?.filter(
      (u) => u.email !== user?.email,
    );

    setNewUserGroup((prevUserGroup) => ({
      ...prevUserGroup,
      users: updatedUsers,
    }));

    onDeleteUserInGroupClose();
  };
  const thWidth = isLongerThan1000 && !isLargerThan1300 ? "30%" : isLargerThan1300 && isLongerThan1000 ? "20%" : "auto";
  return (
    <Grid ml={isLongerThan1000 ? "-28px" : "-30px"} mr="-30px" mt="2rem">
      <Flex justifyContent="space-between" alignItems="center">
        <Tabs
          isFitted
          width="150%"
          marginTop="-35px"
          variant="line"
          color={theme.colors.primary[5]}
          colorScheme={"white"}
          onChange={(index) => setTabIndex(index)}
        >
          <TabList>
            <Tab
              style={{
                fontWeight: "bold",
                fontSize: "20",
                color: "#2E464F",
                transition: "color 0.3s",
                cursor: "pointer",
              }}
              onMouseEnter={(e) => {
                const target = e.target as HTMLElement;
                target.style.backgroundColor = `${theme.colors.primary[5]}`;
                target.style.color = "white";
              }}
              onMouseLeave={(e) => {
                const target = e.target as HTMLElement;
                target.style.backgroundColor = "white";
                target.style.color = `${theme.colors.primary[5]}`;
              }}
            >
              Registered Users
            </Tab>
            <Tab
              style={{
                fontWeight: "bold",
                color: "#2E464F",
                transition: "color 0.3s",
                cursor: "pointer",
              }}
              onMouseEnter={(e) => {
                const target = e.target as HTMLElement;
                target.style.backgroundColor = "#2E464F";
                target.style.color = "white";
              }}
              onMouseLeave={(e) => {
                const target = e.target as HTMLElement;
                target.style.backgroundColor = "";
                target.style.color = "#2E464F";
              }}
            >
              User Groups
            </Tab>
          </TabList>
        </Tabs>
      </Flex>

      <Box marginLeft="30px" marginTop="30px" width={isLongerThan1000 ? "88%" : "93%"}>
        <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
          <TabPanels>
            <TabPanel>
              <Heading size="m" marginBottom="15px">
                User management
              </Heading>
              <Text>
                Please note: You can create new users, change user roles, and
                delete users in the{" "}
                <Link
                  href="https://admin.nextcreate.com/"
                  isExternal
                  style={{ fontWeight: "bold", color: "#3D5B66" }}
                >
                  Nextcreate Admin Portal
                </Link>
                .
              </Text>

              <TableContainer
                border="1px solid"
                borderColor="gray.200"
                borderRadius="10px"
                style={{
                  marginTop: "20px",
                  maxHeight: "600px",
                  overflowY: "auto",
                  overflowX: "auto",
                }}
                // maxWidth={isLargerThan1000 ? "95%" : "100%"}
              >
                 <Table
                    variant="simple"
                    minWidth={isLongerThan1000 && !isLargerThan1000 ? "195%" : isWidthBetween950And1200 ? "150%" : "100%"}
                  >
                  <Thead>
                    <Tr
                      style={{
                        backgroundColor: "#3D5B66",
                      }}
                    >
                      <Th
                        isNumeric
                        style={{
                          position: "sticky",
                          top: "0",
                          zIndex: 1,
                          color: "white",
                          backgroundColor: "#3D5B66",
                          width: "30px",
                        }}
                      >
                        No.
                      </Th>
                      <Th
                        style={{
                          position: "sticky",
                          top: "0",
                          zIndex: 1,
                          color: "white",
                          backgroundColor: "#3D5B66",
                          minWidth: thWidth,
                        }}
                      >
                        Name
                      </Th>
                      <Th
                        style={{
                          position: "sticky",
                          top: "0",
                          zIndex: 1,
                          color: "white",
                          backgroundColor: "#3D5B66",
                          minWidth: thWidth,
                        }}
                      >
                        Email
                      </Th>
                      <Th
                        style={{
                          position: "sticky",
                          top: "0",
                          zIndex: 1,
                          color: "white",
                          backgroundColor: "#3D5B66",
                          minWidth: thWidth,
                        }}
                      >
                        Roles
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {users?.map((user, index) => (
                      <Tr key={index}>
                        <Td isNumeric style={{ width: "30px" }}>
                          {index + 1}
                        </Td>
                        <Td style={{ maxWidth: "70px" }} data-nontranslatable={user.name}>
                          <Avatar
                            name={user.name ?? ""}
                            size="sm"
                            mr="2"
                            style={{ position: "static" }}
                          />
                          {user.name}
                        </Td>
                        <Td style={{ maxWidth: "70px" }} data-nontranslatable={user.email}>{user.email}</Td>
                        <Td style={{ maxWidth: "70px" }}>
                          {user.roles?.join(", ")}
                          {/* <Menu> //will be done in the ADMIN PORTAL
                            <MenuButton
                              as={IconButton}
                              icon={<BsThreeDotsVertical />}
                              variant="ghost"
                              size="sm"
                              float="right"
                            />
                            <MenuList style={{ maxWidth: "150px !important" }}>
                              <MenuItem
                                icon={<BsPencil />}
                                onClick={() => onEditUser(user)}
                              >
                                Edit User
                              </MenuItem>
                              <MenuItem
                                icon={<BsTrash />}
                                onClick={() => onDeleteUser(user)}
                              >
                                Delete User
                              </MenuItem>
                            </MenuList>
                          </Menu> */}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                </TableContainer>
            </TabPanel>

            <TabPanel>
              <Flex
                width="100%"
                alignItems="center"
                justifyContent="space-between"
                marginBottom="20px"
                position="static"
              >
                <Heading size="m" marginBottom="15px">
                  User groups management
                </Heading>
                <Button
                  onClick={() => {
                    setNewUserGroupName("");
                    if (newUserGroup) {
                      newUserGroup.users = [];
                    }
                    onNewUserGroupOpen();
                  }}
                  minWidth="150px"
                  marginRight="70px"
                  padding="8px"
                >
                  New User Group
                </Button>
              </Flex>

              <SimpleGrid
                templateColumns={
                  !isLargerThan1000 ? "repeat(2, 1fr)" :  "repeat(4, 1fr)"
                }
                spacing={8}
                style={{ marginRight: "70px", marginTop: "20px" }}
              >
                {userGroups?.map((userGroup, index) => {
                  return (
                    <Card key={index} bg="#E6EEEF">
                      <CardHeader display="flex" justifyContent="space-between">
                        <Heading size="sm" color="#3D5B66" data-nontranslatable={userGroup.name}>
                          {userGroup.name}
                        </Heading>
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            icon={<BsThreeDotsVertical />}
                            variant="ghost"
                            size="sm"
                            backgroundColor="#D5DADC"
                            color="gray.700"
                            _hover={{ color: "#E6EEEF", bg: "#3D5B66" }}
                          />
                          <MenuList>
                            <MenuItem
                              icon={<BsPencil />}
                              onClick={() => onEditUserGroup(userGroup)}
                            >
                              Edit User Group
                            </MenuItem>
                            <MenuItem
                              icon={<BsTrash />}
                              onClick={() => onDeleteUserGroup(userGroup)}
                            >
                              Delete User Group
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </CardHeader>
                      <CardFooter>
                        <Button
                          style={{ color: "white", backgroundColor: "D5DADC"
                          }}
                          fontSize={!isLargerThan1000 && isLongerThan1000 ? "11px" : "bold"}
                          onClick={() => onEditUserGroupUsers(userGroup)}
                        >
                          Manage Users
                        </Button>
                      </CardFooter>
                    </Card>
                  );
                })}
              </SimpleGrid>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      <Modal
        isOpen={isNewUserGroupOpen}
        onClose={onNewUserGroupClose}
        size="2xl"
        initialFocusRef={modalRef}
      >
        <ModalOverlay />
        <ModalContent ref={modalRef} borderRadius="20" mt="52">
          <ModalHeader>
            <Heading color="black" ml="30">
              Add a new User Group
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box padding="10px" marginLeft={30}>
              <Flex direction="column" gap={4}>
                <FormControl>
                  <FormLabel>User Group Name</FormLabel>
                  <Input
                    value={newUserGroupName ?? ""}
                    onChange={handleChange}
                  />
                </FormControl>
                <div> Add at least one user</div>
                <FormControl>
                  {/* <FormLabel>Name</FormLabel>
                  <Input
                    value={userGroupMember?.name ?? ""}
                    onChange={(e) =>
                      setUserGroupMember({ ...userGroupMember, name: e.target.value })
                    }
                  />
                   <FormLabel>Email</FormLabel>
                  <Input
                    value={userGroupMember?.email ?? ""}
                    onChange={(e) =>
                      setUserGroupMember({ ...userGroupMember, email: e.target.value })
                    }
                  /> */}
                  <FormLabel>Select a user</FormLabel>
                  <Select
                    value={userGroupMember?.name ?? ""}
                    onChange={handleUserChange}
                    placeholder="Select a user"
                  >
                    {users?.map((user) => (
                      <option key={user.id} value={user.name!} data-nontranslatable={user.name}>
                        {user.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  style={{
                    width: "200px",
                    fontSize: "15px",
                    marginLeft: "350px",
                  }}
                  onClick={handleSaveMember}
                >
                  Add the New Member
                </Button>
                {newUserGroup?.users?.map((userGroup, index) => {
                  return (
                    <Card key={index} bg="#FFF4DE" marginBottom="-5px">
                      <CardBody>
                        <Text fontSize="bold">{userGroup.name}</Text>
                      </CardBody>
                    </Card>
                  );
                })}
                <Button
                  style={{
                    width: "200px",
                    fontSize: "15px",
                    marginLeft: "350px",
                  }}
                  onClick={handleCreateUserGroup}
                >
                  Save
                </Button>
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isManageGroupUsersOpen}
        onClose={onManageGroupUsersClose}
        size="xl"
        initialFocusRef={modalRef}
      >
        <ModalOverlay />
        <ModalContent ref={modalRef} borderRadius="20" mt="52">
          <ModalHeader>
            <Heading color="black" ml="30">
              Manage Users
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Add a new user to this group</FormLabel>
              <Select
                value={userGroupMember?.name ?? ""}
                onChange={handleUserChange}
                placeholder="Select a user"
              >
                {users?.map((user, index) => (
                  <option
                    key={index}
                    value={user.name!}
                    data-nontranslatable={user.name}
                  >
                    {user.name}
                  </option>
                ))}
              </Select>
              <Button
                style={{
                  width: "200px",
                  fontSize: "15px",
                  marginLeft: "310px",
                  marginTop: "15px",
                  marginBottom: "15px",
                  alignItems: "flex-end",
                }}
                onClick={handleSaveMember}
              >
                Add a New Member
              </Button>
            </FormControl>
            {newUserGroup?.users?.map((userGroup, index) => {
              return (
                <Card key={index} bg="#FFF4DE" marginBottom="8px">
                  <CardBody>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Text
                        fontSize="bold"
                        data-nontranslatable={userGroup.name}
                      >
                        {userGroup.name}
                      </Text>
                      <IconButton
                        aria-label="Delete User Group"
                        icon={<BsTrash />}
                        onClick={() => {
                          onDeleteUserInGroupOpen();
                          setUserToUpdate(
                            users?.find((u) => u.email === userGroup.email),
                          );
                          // setEditUser(userGroup);
                        }}
                        variant="ghost"
                        color="black.500"
                      />
                    </Flex>
                  </CardBody>
                </Card>
              );
            })}
            <Button
              style={{
                marginLeft: "380px",
                marginTop: "15px",
                marginBottom: "15px",
              }}
              onClick={handleUpdateUserGroup}
            >
              Update Users
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isEditUserGroupOpen}
        onClose={onEditUserGroupClose}
        size="xl"
        initialFocusRef={modalRef}
      >
        <ModalOverlay />
        <ModalContent ref={modalRef} borderRadius="20" mt="52">
          <ModalHeader>
            <Heading color="black" ml="30">
              Edit this User Group
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Flex alignItems="center" justifyContent="space-between">
                <Input
                  value={newUserGroup?.name ?? ""}
                  onChange={(e) => {
                    setNewUserGroupName(e.target.value);
                    setNewUserGroup({
                      ...newUserGroup,
                      name: e.target.value,
                    });
                  }}
                  placeholder="Change User Group Name"
                />
                <Button
                  style={{ marginLeft: "10px" }}
                  onClick={handleUpdateUserGroupName}
                >
                  Update
                </Button>
              </Flex>
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isDeletUserGroupOpen}
        onClose={onDeleteUserGroupClose}
        size="2xl"
        initialFocusRef={modalRef}
      >
        <ModalOverlay />
        <ModalContent ref={modalRef} borderRadius="20" mt="52">
          <ModalHeader>
            <Heading color="black" ml="30">
              Remove this User Group
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {exist ? (
              <div style={{ marginLeft: "30px" }}>
                <span style={{ fontWeight: "bold" }}>ATTENTION</span>: This
                group is used in the following project(s) : <span> </span>
                {projectsPublishedToGroups.map((p, index) => (
                  <span key={index} style={{ fontWeight: "bold" }}>
                    {p.name}
                    {index < projectsPublishedToGroups.length - 1 ? ", " : " "}
                  </span>
                ))}{" "}
                and cannot be deleted. <br /> Please assign a different group
                first in your project settings before deleting this one.
              </div>
            ) : (
              <div style={{ marginLeft: "30px" }}>
                Are you sure, you want to remove{" "}
                <span style={{ fontWeight: "bold" }}>{newUserGroup?.name}</span>{" "}
                ?
              </div>
            )}
            {!exist ? (
              <Flex mt="30px" ml="150px">
                <Button
                  style={{
                    width: "200px",
                    fontSize: "15px",
                    marginRight: "20px",
                    backgroundColor: "white",
                    color: "#2E464F",
                    border: "1px solid #2E464F",
                  }}
                  onClick={onDeleteUserGroupClose}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    width: "200px",
                    fontSize: "15px",
                    color: "red",
                    border: "1px solid red",
                    backgroundColor: "white",
                  }}
                  onClick={handleDeleteUserGroup}
                >
                  Yes, Remove
                </Button>
              </Flex>
            ) : (
              <Button
                style={{
                  width: "200px",
                  fontSize: "15px",
                  marginTop: "30px",
                  marginLeft: "380px",
                  backgroundColor: "white",
                  color: "#2E464F",
                  border: "1px solid #2E464F",
                }}
                onClick={onDeleteUserGroupClose}
              >
                Cancel
              </Button>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isDeleteUserInGroupOpen}
        onClose={onDeleteUserInGroupClose}
        size="2xl"
        initialFocusRef={modalRef}
      >
        <ModalOverlay />
        <ModalContent ref={modalRef} borderRadius="20" mt="52">
          <ModalHeader>
            <Heading color="black" ml="30">
              Remove this user from the User Group
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {!only ? (
              <div style={{ marginLeft: "30px" }}>
                Are you sure, you want to remove{" "}
                <span style={{ fontWeight: "bold" }}>{userToUpdate?.name}</span>{" "}
                ?
                <Flex mt="30px" ml="150px">
                  <Button
                    style={{
                      width: "200px",
                      fontSize: "15px",
                      marginRight: "20px",
                      backgroundColor: "white",
                      color: "#2E464F",
                      border: "1px solid #2E464F",
                    }}
                    onClick={onDeleteUserGroupClose}
                  >
                    Cancel
                  </Button>

                  <Button
                    style={{
                      width: "200px",
                      fontSize: "15px",
                      color: "red",
                      border: "1px solid red",
                      backgroundColor: "white",
                    }}
                    onClick={() => onDeleteUserFromGroup(userToUpdate!)}
                  >
                    Yes, Remove
                  </Button>
                </Flex>
              </div>
            ) : (
              <div>
                <div style={{ marginLeft: "30px" }}>
                  <span style={{ fontWeight: "bold" }}>ATTENTION</span>: You
                  cannot remove the last user from a group. <br />
                  If you want to remove this user you can add another user first
                  (like yourself) or delete the whole group.
                </div>
                <Button
                  style={{
                    width: "200px",
                    fontSize: "15px",
                    marginLeft: "380px",
                    marginTop: "20px",
                    backgroundColor: "white",
                    color: "#2E464F",
                    border: "1px solid #2E464F",
                  }}
                  onClick={onDeleteUserInGroupClose}
                >
                  Cancel
                </Button>
              </div>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Grid>
  );
};
