import { resourceCategoryDetailsByExtension } from "pages/Library/resourceCategoryDetails";
import { ContentLibraryBean, ContentType } from "./model";

export interface Configurations {
  position?: XYZ;
  scale?: XYZ;
  rotation?: XYZ;

  initial?: {
    position?: XYZ;
    scale?: XYZ;
    rotation?: XYZ;
  };

  size?: XYZ;

  threeD?: {
    animations: string[];
    selectedAnimation: string;
  };
  videoURL?: {
    url: string;
    embedUrl: string;
    aspectRatio?: { x: number; y: number };
  };
}

export interface XYZ {
  x: number;
  y: number;
  z: number;
}

export const initial2DConfigurations = (size: { x: number; y: number }) => {
  const configurations: Configurations = {};
  const maxD = Math.max(size.x, size.y);
  // For 2D content we need to set proper proportions, as three.js makes every dimenstion 1.
  // three.js makes sure that the max dimension is 4.
  const scale = {
    x: 6 * (size.x / maxD),
    y: 6 * (size.y / maxD),
    z: 0,
  };

  configurations.position = { x: 0, y: 0.1, z: 0 };
  configurations.scale = scale;
  configurations.rotation = { x: -90, y: 0, z: 0 };

  configurations.initial = {
    position: { ...configurations.position },
    scale: { ...configurations.scale },
    rotation: { ...configurations.rotation },
  };
  configurations.size = { x: size.x, y: size.y, z: 0 };

  return configurations;
};

const loadImage = (src: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = (error) => reject(error);
  });
};

const loadVideo = (src: string): Promise<HTMLVideoElement> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.src = src;
    video.onloadedmetadata = () => resolve(video);
    video.onerror = (error) => reject(error);
  });
};

export const initialConfigurationsFromResource = async (
  resource: ContentLibraryBean,
) => {
  const contentType = resourceCategoryDetailsByExtension(
    resource.filetype!,
  ).contentOrAnchorType;
  if (contentType === ContentType.Image) {
    try {
      const img = await loadImage(resource.sasUri!);
      const size = { x: img.naturalWidth, y: img.naturalHeight };
      return initial2DConfigurations(size);
    } catch (error) {
      console.error("Error loading image:", error);
    }
  }
  if (contentType === ContentType.Video) {
    try {
      const video = await loadVideo(resource.sasUri!);
      const size = { x: video.videoWidth, y: video.videoHeight };
      return initial2DConfigurations(size);
    } catch (error) {
      console.error("Error loading video:", error);
    }
  }
  return {};
};

export const initialVideoURLConfigurations = () => {
  const configurations: Configurations = {};

  const scale = {
    x: 6,
    y: 6,
    z: 1,
  };

  configurations.position = { x: 0, y: 0.1, z: 0 };
  configurations.scale = scale;
  configurations.rotation = { x: -90, y: 0, z: 0 };

  configurations.initial = {
    position: { ...configurations.position },
    scale: { ...configurations.scale },
    rotation: { ...configurations.rotation },
  };

  return configurations;
};

export const initialMarkerConfigurations = (size: { x: number; y: number }) => {
  const configurations: Configurations = {};
  const maxD = Math.max(size.x, size.y);
  const scale = {
    x: 6 * (size.x / maxD),
    y: 6 * (size.y / maxD),
    z: 0,
  };

  configurations.position = { x: 0, y: 0, z: 0 };
  configurations.scale = scale;
  configurations.rotation = { x: -90, y: 0, z: 0 };

  configurations.initial = {
    position: { ...configurations.position },
    scale: { ...configurations.scale },
    rotation: { ...configurations.rotation },
  };

  configurations.size = { x: size.x, y: size.y, z: 0 };

  return configurations;
};

export const initial3DConfigurations = (
  size: XYZ,
  animations: string[] = [],
  prescale: boolean,
) => {
  console.log("size", size);
  const configurations: Configurations = {};
  let scale = 1.0;
  if (prescale) {
    // For 3D content we should make sure that the max dimension is 1.
    // by setting it explicitly.
    // three.js keeps the proportions of the model.

    //const maxD = Math.max(size.x, size.y, size.z);
    scale = 1;

    // Adjust scale to fit within the range 0.1 to 0.25
    // const scaleFactor = Math.min(0.25 / scale, 0.01);
    // console.log(scaleFactor)
    // scale *= scaleFactor;
    // console.log(scale);
  }

  // three.js places 3D content on the ground by default,
  // which is not what we want, so we need to lift it up a bit for
  // the flat orientation.
  configurations.position = { x: 0, y: 0, z: 0 }; //inside the 3d content ground
  configurations.scale = { x: scale, y: scale, z: scale };
  configurations.rotation = { x: 0, y: 0, z: 0 };

  configurations.initial = {
    position: { ...configurations.position },
    scale: { ...configurations.scale },
    rotation: { ...configurations.rotation },
  };
  configurations.size = { ...size };
  console.log(
    configurations.size,
    configurations.position,
    configurations.scale,
    configurations.rotation,
  );
  configurations.threeD = {
    animations,
    selectedAnimation: "",
  };

  return configurations;
};
