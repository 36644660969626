import * as React from "react";
import { Box, Button } from "@chakra-ui/react";

interface CategoriesProps {
  categories: string[];
  onCategorySelected: (category: string) => void;
  activeCategory: string;
}

const STATIC_CATEGORIES = ["All", "Published", "Draft"];

export const Categories: React.FC<CategoriesProps> = ({
  categories,
  onCategorySelected,
  activeCategory,
}) => {
  return (
    <Box>
      {STATIC_CATEGORIES.map((category) => (
        <Button
          key={category}
          minWidth="auto"
          className="item"
          size="sm"
          borderRadius="50"
          variant="ghost"
          isActive={activeCategory === category.toLowerCase()}
          padding="4px 16px"
          marginRight="10px"
          onClick={() => onCategorySelected(category.toLowerCase())}
        >
          {category}
        </Button>
      ))}
      {categories
        .filter((category) => !STATIC_CATEGORIES.includes(category))
        .map((category) => (
          <Button
            key={category}
            minWidth="auto"
            className="item doNotTranslate"
            size="sm"
            borderRadius="50"
            variant="ghost"
            isActive={activeCategory === category}
            padding="4px 16px"
            marginRight="10px"
            onClick={() => onCategorySelected(category)}
          >
            {category}
          </Button>
        ))}
    </Box>
  );
};
