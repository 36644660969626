import { FC, useRef, useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { Content, ContentType } from "api/model";
import { CreateOrUpdateContentProps } from "../DialogCreateOrUpdateContent";
import { useTranslation } from "../../../../../../../layout/TranslationContext";
import { translatePage } from "../../../../../../../layout/translatePage";
import { ResourceLibrary } from "../ResourceLibrary";
import { ResourceCategory } from "pages/Library/resourceCategoryDetails";
import { TabImageUpload } from "./TabImageUpload";

export const contentEditorImageSupportedContentTypes = [ContentType.Image];

export const DialogContentEditorImage: FC<CreateOrUpdateContentProps> = (
  props,
) => {
  const onClose = props.onClose;

  // Initialize content or default to a new image content type
  const content =
    props.content ??
    ({
      type: ContentType.Image,
    } as Content);

  // Determine mode based on presence of content
  const mode = props.content ? "update" : "create";

  let tabIndex = 0;
  const { language, setLanguage } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  // Load saved language on mount
  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  // Update modal open state based on props
  useEffect(() => {
    setModalIsOpen(props.isOpen);
  }, [props.isOpen]);

  // Translate modal content when open or language changes
  useEffect(() => {
    if (modalIsOpen && modalRef.current) {
      translatePage(modalRef.current, language);
    }
  }, [modalIsOpen, language]);

  // Set default active tab based on content type
  switch (content.type) {
    case ContentType.Image:
      tabIndex = 0;
      break;
    default:
      throw new Error("Unknown content type");
  }

  return (
    <Modal {...props} size="3xl" initialFocusRef={modalRef}>
      <ModalOverlay />
      <ModalContent p="2" ref={modalRef}>
        {" "}
        <ModalHeader fontSize="36px" fontWeight="700" pt="10">
          {mode === "create" ? "Add Image" : "Update Image"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Tabs for Upload and Library */}
          <Tabs variant="soft-rounded" defaultIndex={tabIndex}>
            <TabList
              backgroundColor="primary.0"
              p="1"
              borderRadius="50"
              gap="4"
              width="fit-content"
              height="40px"
            >
              <Tab
                _selected={{ backgroundColor: "primary.6", color: "white" }}
                width="150px"
              >
                Upload
              </Tab>
              <Tab
                _selected={{ backgroundColor: "primary.6", color: "white" }}
                width="150px"
              >
                Library
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel px="0" py="6">
                <TabImageUpload {...props} />
              </TabPanel>
              <TabPanel px="0" py="6">
                <ResourceLibrary
                  content={content}
                  category={ResourceCategory.Images}
                  onSuccess={onClose}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
