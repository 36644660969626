import React, { FC, useEffect, useRef } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import { OrbitControls, useGLTF, Center } from "@react-three/drei";
import { Box3, Vector3, Sphere } from "three";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { Configurations, initial3DConfigurations } from "api/Configurations";

interface GLBPreviewProps {
  src: string;
  setConfigurations: (configurations: Configurations) => void;
  prescale?: boolean;
}

const Model: FC<GLBPreviewProps> = ({
  src,
  setConfigurations,
  prescale = false,
}) => {
  const { scene, animations } = useGLTF(src, true);
  const controlsRef = useRef<any>();
  const { camera } = useThree();

  useEffect(() => {
    if (!scene) return;

    // Reset the current scale of the scene
    scene.scale.set(1, 1, 1);

    // Calculate the bounding box of the model
    const box = new Box3().setFromObject(scene);
    const sphere = box.getBoundingSphere(new Sphere());

    // Scale the model
    const targetSize = 1;
    let scale = targetSize / sphere.radius;

    // Set minimum and maximum scale
    const minScale = 0.25;
    const maxScale = 2.0;
    scale = Math.max(minScale, Math.min(scale, maxScale));

    // Apply the scale
    scene.scale.set(scale, scale, scale);

    // Recalculate bounding sphere after scaling
    const newBox = new Box3().setFromObject(scene);
    const newSphere = newBox.getBoundingSphere(new Sphere());

    // Position the camera
    const cameraDistance = newSphere.radius * 3;
    camera.position
      .copy(newSphere.center)
      .add(new Vector3(0, 0, cameraDistance));
    camera.near = cameraDistance / 100;
    camera.far = cameraDistance * 100;
    camera.updateProjectionMatrix();

    // Center the camera on the model
    camera.lookAt(newSphere.center);

    // Update OrbitControls
    if (controlsRef.current) {
      controlsRef.current.target.copy(newSphere.center);
      controlsRef.current.update();
    }

    const animationNames = animations.map((animation) => animation.name);

    setConfigurations(
      initial3DConfigurations(
        newBox.getSize(new Vector3()),
        animationNames,
        prescale,
      ),
    );
  }, [scene, animations, setConfigurations, prescale, camera]);

  return (
    <Center>
      <primitive object={scene} />
      <OrbitControls
        ref={controlsRef}
        enablePan={true}
        enableZoom={true}
        enableRotate={true}
      />
    </Center>
  );
};

export const ThreeDModelPreview: FC<GLBPreviewProps> = ({
  src,
  setConfigurations,
}) => {
  useEffect(() => {
    const loader = new DRACOLoader();
    loader.setDecoderPath("/draco/");
    loader.preload();
    return () => {
      loader.dispose();
    };
  }, [src]);

  return (
    <Canvas
      style={{ border: "1px solid #E2E8F0" }}
      onClick={(e: React.SyntheticEvent) => {
        e.stopPropagation();
      }}
      camera={{ fov: 40, near: 0.1, far: 1000 }}
    >
      <ambientLight intensity={1} />
      <Model src={src} setConfigurations={setConfigurations} prescale={true} />
    </Canvas>
  );
};
