import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import {
  Flex,
  Box,
  Image,
  Divider,
  Button,
  Icon,
  Tooltip,
  useMediaQuery,
  IconButton,
} from "@chakra-ui/react";
import { NavLink as ReachLink } from "react-router-dom";
import { MdOutlineDashboard, MdOutlineQuestionMark } from "react-icons/md";
import { IoBarChartSharp, IoSettingsOutline } from "react-icons/io5";
import { FiUsers } from "react-icons/fi";
import { BiLibrary } from "react-icons/bi";
import { BsChevronLeft } from "react-icons/bs";

const ICON_SIZE = "20px";

type NavButtonProps = {
  icon: React.ElementType;
  label: string;
  to: string;
  onClick?: () => void;
  isDisabled?: boolean;
};

const NavButton: React.FC<NavButtonProps> = ({
  icon,
  label,
  to,
  onClick,
  isDisabled,
}) => (
  <Button
    variant="ghost"
    leftIcon={<Icon as={icon} boxSize={ICON_SIZE} />}
    as={ReachLink}
    to={to}
    width="200px"
    height="44px"
    color="primary.4"
    fontSize="sm"
    fontWeight="600"
    padding="10px 10px 10px 15px"
    css={{ justifyContent: "flex-start", alignItems: "center" }}
    _activeLink={{ color: "primary.6" }}
    onClick={onClick}
    isDisabled={isDisabled}
  >
    {label}
  </Button>
);

const CollapsedNav: React.FC = () => {
  const openSupport = useCallback(() => {
    const windowFeatures = "width=800,height=840,left=200,top=200";
    window.open(
      "https://nextcreate.com/kontakt/",
      "newwindow",
      windowFeatures,
    );
  }, []);

  return (
    <>
      <Box
        as={ReachLink}
        to="/"
        width="100%"
        height="90px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Image
          boxSize="30px"
          objectFit="contain"
          alt="nextCreate logo"
          src={`${process.env.PUBLIC_URL}/icons/next_small.png`}
        />
      </Box>
      <Divider color="gray.300" borderBottomWidth="2px" />
      <Flex
        mt="2.5rem"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        width="60px"
        gap="15"
      >
        <IconButton
          aria-label="My Projects"
          icon={<Icon as={MdOutlineDashboard} boxSize={ICON_SIZE} />}
          as={ReachLink}
          to="/"
          variant="outline"
          color="primary.4"
          border="0px"
          _hover={{ color: "secondary.3" }}
          _activeLink={{ color: "primary.6", backgroundColor: "#D5DADC" }}
        />
        <IconButton
          aria-label="Analytics"
          icon={<Icon as={IoBarChartSharp} boxSize={ICON_SIZE} />}
          as={ReachLink}
          to="/analytics"
          variant="outline"
          color="primary.4"
          border="0px"
          _hover={{ color: "secondary.3" }}
          _activeLink={{ color: "primary.6", backgroundColor: "#D5DADC" }}
        />
        <IconButton
          aria-label="Users"
          icon={<Icon as={FiUsers} boxSize={ICON_SIZE} />}
          as={ReachLink}
          to="/users"
          variant="outline"
          color="primary.4"
          border="0px"
          _hover={{ color: "secondary.3" }}
          _activeLink={{ color: "primary.6", backgroundColor: "#D5DADC" }}
        />
        <IconButton
          aria-label="Library"
          icon={<Icon as={BiLibrary} boxSize={ICON_SIZE} />}
          as={ReachLink}
          to="/library"
          variant="outline"
          color="primary.4"
          border="0px"
          _hover={{ color: "secondary.3" }}
          _activeLink={{ color: "primary.6", backgroundColor: "#D5DADC" }}
        />
        <IconButton
          aria-label="Settings"
          icon={<Icon as={IoSettingsOutline} boxSize={ICON_SIZE} />}
          as={ReachLink}
          to="#"
          variant="outline"
          color="primary.4"
          border="0px"
          _hover={{ color: "secondary.3" }}
          _activeLink={{ color: "primary.6", backgroundColor: "#D5DADC" }}
          isDisabled
        />
        <IconButton
          aria-label="Support"
          icon={<Icon as={MdOutlineQuestionMark} boxSize={ICON_SIZE} />}
          onClick={openSupport}
          variant="outline"
          _hover={{ color: "secondary.3" }}
          color="primary.4"
          border="0px"
          _activeLink={{ color: "primary.6", backgroundColor: "#D5DADC" }}
        />
      </Flex>
    </>
  );
};

const ExpandedNav: React.FC = () => {
  const openSupport = useCallback(() => {
    const windowFeatures = "width=800,height=840,left=200,top=200";
    window.open(
      "https://nextcreate.com/kontakt/",
      "newwindow",
      windowFeatures,
    );
  }, []);

  return (
    <>
      <Box
        as={ReachLink}
        to="/"
        w="100%"
        h="90px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Image
          boxSize="60px"
          objectFit="contain"
          alt="nextCreate logo"
          src={`${process.env.PUBLIC_URL}/icons/next.png`}
        />
      </Box>
      <Divider color="gray.300" borderBottomWidth="2px" />
      <Flex
        mt="2.5rem"
        justify="center"
        flexDirection="column"
        align="center"
        gap="15"
      >
        <NavButton icon={MdOutlineDashboard} label="My Projects" to="/" />
        <NavButton icon={IoBarChartSharp} label="Analytics" to="/analytics" />
        <Tooltip label="Coming soon" fontSize="md">
          <NavButton icon={FiUsers} label="Users" to="/users" />
        </Tooltip>
        <NavButton icon={BiLibrary} label="Library" to="/library" />
        <Tooltip label="Coming soon" fontSize="md">
          <NavButton
            icon={IoSettingsOutline}
            label="Settings"
            to="#"
            isDisabled
          />
        </Tooltip>
        <NavButton
          icon={MdOutlineQuestionMark}
          label="Support"
          to="#"
          onClick={openSupport}
        />
      </Flex>
    </>
  );
};

export const Navigator: React.FC = () => {
  const [isLargerThan1620] = useMediaQuery("(min-width: 1620px)");
  const [minimSideBar, setMinimSideBar] = useState(!isLargerThan1620);

  useEffect(() => {
    setMinimSideBar(!isLargerThan1620);
  }, [isLargerThan1620]);

  const toggleSidebar = () => {
    setMinimSideBar((prev) => !prev);
  };

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      w={minimSideBar ? "90px" : "270px"}
      transition="width 0.125s ease-in"
    >
      <Box
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="25px"
        height="40px"
        backgroundColor="blackAlpha.50"
        right={minimSideBar ? "-25px" : "0"}
        top="25px"
        borderRadius={"30% 0% 0% 30%"}
        _hover={{ cursor: "pointer" }}
        transform={minimSideBar ? "rotate(180deg)" : "rotate(0)"}
        transition="transform 0.125s ease-in, right 0.125s ease-in"
        onClick={toggleSidebar}
        aria-label={minimSideBar ? "Expand sidebar" : "Collapse sidebar"}
      >
        <Icon as={BsChevronLeft} />
      </Box>
      {minimSideBar ? <CollapsedNav /> : <ExpandedNav />}
    </Box>
  );
};
