import { FC, useEffect, useState, useRef, useCallback } from "react";
import { Box, Button, Flex, Spacer, useToast } from "@chakra-ui/react";
import { createReactEditorJS } from "react-editor-js";
import { EDITOR_JS_TOOLS } from "./reactEditorJSTools";
import {
  useMutationCreateContent,
  useMutationUpdateContent,
  useMutationCreateQRCodeForChapter,
  useQueryProject,
} from "state/remoteState";
import { useProjectChapterId } from "state/localState";
import { AnchorType, Content, ContentType } from "api/model";
import { translateText } from "../../../../../../../layout/translatePage";
import { useTranslation } from "../../../../../../../layout/TranslationContext";

interface Props {
  content?: Content;
  mode: "create" | "update";
  onClose: () => void;
  selectedType: ContentType | undefined;
}

export const TabWebEditor: FC<Props> = ({
  content,
  mode,
  onClose,
  selectedType,
}) => {
  const ReactEditorJS = createReactEditorJS();
  const [data, setData] = useState(
    content?.configurations?.editorJS?.data || {},
  );
  const { projectId, chapterId } = useProjectChapterId();
  const editorCore = useRef<any>(null);
  const mutationCreateContent = useMutationCreateContent();
  const mutationUpdateContent = useMutationUpdateContent();
  const mutationCreateQRCodeForChapter = useMutationCreateQRCodeForChapter();
  const toast = useToast();
  const { data: project } = useQueryProject(projectId);
  const { language } = useTranslation();

  const showTranslatedToast = async ({
    title,
    message,
  }: {
    title: string;
    message: string;
  }) => {
    const translatedTitle = await translateText(title, language);
    const translatedMessage = await translateText(message, language);
    toast({
      title: translatedTitle,
      description: translatedMessage,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const [isEditorEmpty, setIsEditorEmpty] = useState<boolean>(true);

  const checkEditorContent = async () => {
    setIsEditorEmpty(false);
    // if (!editorCore.current || typeof editorCore.current.save !== "function") {
    //   toast({
    //     status: "warning",
    //     title: "Editor not ready",
    //     description:
    //       "Editor instance is not initialized yet. Please try again later.",
    //     duration: 3000,
    //     isClosable: true,
    //   });
    //   return;
    // }
    // try {
    //   const savedData = await editorCore.current.save();
    //   setIsEditorEmpty(!savedData?.blocks || savedData.blocks.length === 0);
    // } catch (error) {
    //   console.error("Error saving editor content 1:", error);
    //   toast({
    //     status: "error",
    //     title: "Error saving content",
    //     description: "An error occurred while trying to save editor content.",
    //     duration: 5000,
    //     isClosable: true,
    //   });
    // }
  };

  useEffect(() => {
    if (
      content?.configurations?.editorJS?.data &&
      content.configurations.editorJS.data !== data
    ) {
      setData(content.configurations.editorJS.data);
    }
  }, [content]);

  const handleInitialize = useCallback((instance) => {
    if (instance) {
      editorCore.current = instance;
    } else {
      console.warn("Failed to initialize the editor instance.");
    }
  }, []);

  const handleCreateContent = async () => {
    if (!projectId || !chapterId || !editorCore.current) return;
    try {
      const savedData = await editorCore.current.save();

      const newContent: Content = {
        name: "Web-Editor",
        configurations: {
          editorJS: { data: savedData },
        },
        type: ContentType.EditorJs,
      };

      mutationCreateContent.mutate(
        {
          projectId,
          chapterId,
          content: newContent,
        },
        {
          onSuccess: () => {
            if (project?.anchorType === AnchorType.QrCode) {
              mutationCreateQRCodeForChapter.mutate({
                projectId,
                chapterId,
              });
            }
            showTranslatedToast({
              title: "Content added",
              message: `Website was added`,
            });
            onClose();
          },
          onError: (error) => {
            console.error("Creation failed:", error);
            showTranslatedToast({
              title: "Content creation failed",
              message: `Website could not be created`,
            });
          },
        },
      );
    } catch (error) {
      console.error("Error saving editor content 2:", error);
    }
  };

  const handleUpdateContent = async () => {
    if (!content || !content.id) {
      throw new Error("Content is not defined");
    }
    if (!editorCore.current) {
      throw new Error("Editor is not initialized");
    }

    try {
      const savedData = await editorCore.current.save();

      content.configurations = {
        editorJS: { data: savedData },
      };
      // Make sure that the content has a proper type
      content.name = "Web-Editor";
      content.description = "";
      content.sources = {};
      content.sourceObjectId = "";
      content.type = ContentType.EditorJs;
      mutationUpdateContent.mutate(
        {
          projectId,
          chapterId,
          contentId: content.id,
          content,
        },
        {
          onSuccess: () => {
            showTranslatedToast({
              title: "Content updated",
              message: `Website "${content.name ?? "unnamed"}" has been updated`,
            });
            onClose();
          },
          onError: (error) => {
            console.error("Update failed:", error);
            toast({
              status: "error",
              title: "Error updating content",
              description: error.message || "Website could not be updated",
            });
          },
        },
      );
    } catch (error) {
      console.error("Error saving editor content 4:", error);
      toast({
        status: "error",
        title: "Error saving content",
        description: "An error occurred while saving editor content.",
      });
    }
  };

  return (
    <>
      <Box
        border="1px solid"
        borderColor="gray.200"
        overflowY="auto"
        minHeight="250px"
        width="100%"
        p="4"
      >
        <ReactEditorJS
          tools={EDITOR_JS_TOOLS}
          onInitialize={handleInitialize}
          defaultValue={data}
          onChange={checkEditorContent}
          placeholder="Click the + icon on the left to add new Items or just copy & paste your content below"
        />
      </Box>
      <Flex width="100%" justifyContent="flex-end">
        <Spacer />
        {mode === "create" && (
          <Button
            isDisabled={isEditorEmpty}
            isLoading={mutationCreateContent.isPending}
            _hover={{ backgroundColor: "primary.3" }}
            variant="primary"
            mt="4"
            onClick={handleCreateContent}
          >
            Add Content
          </Button>
        )}
        {mode === "update" && (
          <Button
            isDisabled={isEditorEmpty}
            isLoading={mutationUpdateContent.isPending}
            _hover={{ backgroundColor: "primary.3" }}
            variant="primary"
            mt="4"
            onClick={handleUpdateContent}
          >
            Update Content
          </Button>
        )}
      </Flex>
    </>
  );
};
