import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  Grid,
  GridItem,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Content, ContentType } from "api/model";
import {
  useMutationCreateContent,
  useMutationUpdateContent,
} from "state/remoteState";
import { CreateOrUpdateContentProps } from "../DialogCreateOrUpdateContent";
import { useProjectChapterId } from "state/localState";
import { initialVideoURLConfigurations } from "api/Configurations";
import { translateText } from "../../../../../../../layout/translatePage";
import { useTranslation } from "../../../../../../../layout/TranslationContext";
type FormData = {
  name: string;
  url: string;
};

export const TabVideoURL: FC<CreateOrUpdateContentProps> = (props) => {
  const { projectId, chapterId } = useProjectChapterId();
  const mutationCreateContent = useMutationCreateContent();
  const mutationUpdateContent = useMutationUpdateContent();
  const [videoType, setVideoType] = useState<
    ContentType.Youtube | ContentType.Vimeo
  >();
  const [embedUrl, setEmbedUrl] = useState<string>("");

  const { register, watch, formState } = useForm<FormData>({
    defaultValues: {
      name: props.content?.name ?? "",
      url: props.content?.configurations?.videoURL?.url ?? "",
    },
  });
  const formData = watch();
  const toast = useToast();

  const { language } = useTranslation();
  const showTranslatedToast = async (message: string) => {
    const translatedMessage = await translateText(message, language);
    toast({
      title: translatedMessage,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };
  const mode = props.content ? "update" : "create";
  const handleCreateOrUpdateContent = () => {
    const content: Content = props.content ?? {
      configurations: {
        ...initialVideoURLConfigurations(),
        videoURL: { url: "", embedUrl: "" },
      },
    };

    content.configurations!.videoURL = {
      url: formData.url,
      embedUrl: embedUrl,
    };

    content.name = formData.name;
    content.type = videoType;

    const onSuccess = () => {
      showTranslatedToast(
        `Video content "${content.name}" has been ${
          mode === "create" ? "added" : "updated"
        }`,
      );
      props.onClose();
    };

    const onError = async (error: any) => {
      const translatedTitle = await translateText(
        `Error ${mode === "create" ? "adding" : "updating"} content`,
        language,
      );
      const translatedDescription = await translateText(
        error.message ||
          `An error occurred while ${
            mode === "create" ? "adding" : "updating"
          } the video.`,
        language,
      );
      toast({
        status: "error",
        title: translatedTitle,
        description: translatedDescription,
      });
    };

    const contentId = content.id!;
    switch (mode) {
      case "create":
        mutationCreateContent.mutate(
          { projectId, chapterId, content },
          { onSuccess, onError },
        );
        break;
      case "update":
        mutationUpdateContent.mutate(
          { projectId, chapterId, contentId, content },
          { onSuccess, onError },
        );
        break;
    }
  };

  return (
    <>
      <Grid templateColumns="1fr 1fr" gap="2" minHeight="250px">
        <GridItem>
          <Button
            position="relative"
            width="100%"
            height="100%"
            backgroundColor="primary.0"
            borderRadius="6"
            display="flex"
            alignItems="center"
            justifyContent="center"
            _hover={{ backgroundColor: "primary.0" }}
          >
            <Flex direction="column" align="center">
              <VideoURLPreview
                url={formData.url}
                aspectRatio={{ x: 16, y: 9 }}
                setVideoType={setVideoType}
                setEmbedUrl={setEmbedUrl}
              />
            </Flex>
          </Button>
        </GridItem>
        <Flex direction="column" gap="4" pl="2">
          <Alert
            status="info"
            color="secondary.2"
            backgroundColor="rgba(77, 136, 140, 0.05)"
            border="1px solid #4D888C"
            borderRadius="8"
          >
            <AlertIcon color="secondary.2" />
            <Text fontWeight="500" fontSize="xs">
              You can use any video link from YouTube or Vimeo. Please make sure
              that the video is public.
            </Text>
          </Alert>
          <Flex align="center" gap="2">
            <Text fontSize="sm" fontWeight="600" mr="2" color="primary.4">
              Content&nbsp;Name
            </Text>
            <Input
              placeholder="Name"
              borderRadius="32"
              size="sm"
              {...register("name")}
            />
          </Flex>
          <Flex align="center" gap="2">
            <Text fontSize="sm" fontWeight="600" mr="2" color="primary.4">
              URL
            </Text>
            <Input
              placeholder="Video URL"
              borderRadius="32"
              size="sm"
              {...register("url", { required: true })}
            />
          </Flex>
        </Flex>
      </Grid>
      <Flex width="100%" justifyContent="flex-end">
        <Button
          mt="4"
          isLoading={
            mode === "update"
              ? mutationUpdateContent.isPending
              : mutationCreateContent.isPending
          }
          isDisabled={!formState.isValid || !embedUrl}
          onClick={handleCreateOrUpdateContent}
        >
          {mode === "create" ? "Add Content" : "Update Content"}
        </Button>
      </Flex>
    </>
  );
};

interface VideoURLPreviewProps {
  url: string;
  aspectRatio: { x: number; y: number };
  setEmbedUrl: (embedUrl: string) => void;
  setVideoType: (type: ContentType.Youtube | ContentType.Vimeo) => void;
}

const VideoURLPreview: FC<VideoURLPreviewProps> = ({
  url,
  aspectRatio,
  setVideoType,
  setEmbedUrl: setParentEmbedUrl,
}) => {
  const [embedUrl, setEmbedUrl] = useState("");
  useEffect(() => {
    let newEmbedUrl = "";
    if (url.includes("youtube.com") || url.includes("youtu.be")) {
      const videoIdMatch = url.match(
        /(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[^/\n\s])|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
      );
      if (videoIdMatch) {
        newEmbedUrl = `https://www.youtube.com/embed/${videoIdMatch[1]}`;
        setVideoType(ContentType.Youtube);
      }
    } else if (url.includes("vimeo.com")) {
      const videoIdMatch = url.match(/vimeo\.com\/(\d+)/);
      if (videoIdMatch) {
        newEmbedUrl = `https://player.vimeo.com/video/${videoIdMatch[1]}`;
        setVideoType(ContentType.Vimeo);
      }
    }
    setEmbedUrl(newEmbedUrl);
    setParentEmbedUrl(newEmbedUrl);
  }, [url, setVideoType, setParentEmbedUrl]);

  return embedUrl ? (
    <div
      style={{
        width: "100%",
        aspectRatio: aspectRatio.x + " / " + aspectRatio.y,
      }}
    >
      <iframe src={embedUrl} style={{ width: "100%", height: "100%" }}></iframe>
    </div>
  ) : (
    <Text color="primary.4" lineHeight="1.5" textAlign="center">
      Please specify a valid <br /> YouTube or Vimeo video URL.
    </Text>
  );
};
