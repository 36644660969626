import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { Chapters } from "./Chapters";
import { AnchorType, ProjectType, QrCodeConfig } from "api/model";
import { useProjectId } from "state/localState";
import { useDebouncedCallback, useDebounce } from "use-debounce";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Image,
  Textarea,
  useOutsideClick,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  useBreakpointValue, MenuOptionGroup,
} from "@chakra-ui/react";

import { CustomTag } from "./components/CustomTag";
import { IoAddSharp, IoArrowForwardSharp } from "react-icons/io5";
import { BsChevronDown } from "react-icons/bs";
import { BiShuffle } from "react-icons/bi";
import { FiCopy, FiDownload, FiMoreVertical } from "react-icons/fi";
import {
  useMutationCreateQRCode,
  useMutationUpdateProject,
  useMutationUpdateProjectThumbnail,
  useQueryProject,
  useQueryMe,
  useQueryUsersGroups,
} from "state/remoteState";
import { AccessType, ProjectBean, Status } from "api/model";
import { PublishPopover } from "./PublishPopover";
import { useForm } from "react-hook-form";
import { HelpIconButton } from "pages/HelpIconButton";
import { useTranslation } from "../../layout/TranslationContext";
import { translateText } from "../../layout/translatePage";
import { ThumbnailUploader } from "./components/ThumbnailUploader";

interface ProjectForm {
  name: string;
  accessType: AccessType;
  categories: string[];
  description: string;
  type: ProjectType;
  userGroupsIds?: string[];
}

export const Project = () => {
  
  const projectId = useProjectId();
  const {
    data: project,
    isSuccess: isSuccessProject,
  } = useQueryProject(projectId);
  const mutationUpdateProject = useMutationUpdateProject();
  const mutationUpdateProjectThumbnail = useMutationUpdateProjectThumbnail();

  const [projectQrCodes, setProjectQrCodes] = useState<QrCodeConfig[]>([]);
  const { data: me } = useQueryMe();
  const { data: userGroups } = useQueryUsersGroups(
    me?.subscriptions?.[0]?.id ?? "",
  );

  const filteredUserGroups = useMemo(() => {
    return (
      userGroups
        ?.filter((ug) => project?.userGroupsIds?.includes(ug.id!) && ug.name)
        .map((ug) => ug.name as string) || []
    );
  }, [userGroups, project]);

  const [array, setArray] = useState<string[]>(filteredUserGroups);

  const accessGroups = useMemo(() => {
    return userGroups
      ? [...userGroups.map((group) => group.name ?? "")]
      : ["You do not have groups yet!"];
  }, [userGroups]);

  const mutationCreateQRCode = useMutationCreateQRCode();
  const [categoryMenuOpen, setCategoryMenuOpen] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [isPublished, setIsPublished] = useState(
    project?.status === Status.Published,
  );
  const [debouncedNewCategoryName] = useDebounce(newCategoryName, 1000);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const categoryRef = useRef<HTMLInputElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [selectedValue, setSelectedValue] = useState("");
  //const mutationPublishProject = useMutationPublishProject();
  const [changes, setChanges] = useState(false);

  const { language, setLanguage } = useTranslation();

  const iconSize = useBreakpointValue({ base: "sm", md: "md" });

  const showTranslatedToast = useCallback(
    async (
      title: string,
      description: string,
      status: "success" | "error" | "info" | "warning",
      language: string,
    ) => {
      try {
        const translatedTitle = await translateText(title, language);
        const translatedDescription = await translateText(
          description,
          language,
        );
        toast({
          title: translatedTitle,
          description: translatedDescription,
          status: status,
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Error translating toast messages:", error);
        toast({
          title: title,
          description: description,
          status: status,
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [toast],
  );

  const mutationUpdateProjectDebounced = useDebouncedCallback(
    (project: ProjectBean) => {
      return mutationUpdateProject.mutate(project, {
        onSuccess: () => {
          showTranslatedToast(
            "Project saved",
            "Project changes were saved successfully",
            "success",
            language,
          );
        },
        onError: () => {
          showTranslatedToast(
            "Error saving project",
            "Could not save project changes. Please try again.",
            "error", // Добавлен параметр status
            language,
          );
        },
      });
    },
    1000,
  );
  

  useEffect(() => {
    if (isSuccessProject && project) {
      let newValue = "";
      if (project.userGroupsIds && project.userGroupsIds.length >= 1) {
        newValue = filteredUserGroups.join("-");
      } else if (project.accessType === AccessType.All) {
        newValue = "Anyone with the App Link QR Code";
      } else if (
        (project.accessType === AccessType.MembersOnly &&
          project.userGroupsIds?.length === 0) ||
        array.length === 0
      ) {
        newValue = "All registered users";
      }
      setSelectedValue(newValue);
    }
  }, [
    isSuccessProject,
    project?.userGroupsIds,
    project?.accessType,
    filteredUserGroups,
    array.length,
    project,
  ]);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  const { register, setValue, watch } = useForm<ProjectForm>({
    values: {
      name: project?.name ?? "Untitled Project",
      accessType: project?.accessType ?? AccessType.All,
      categories: project?.categories ?? [],
      description: project?.description ?? "",
      type: project?.type ?? ProjectType.Multiple,
      userGroupsIds: project?.userGroupsIds ?? [],
    },
    defaultValues: {
      name: project?.name ?? "Untitled Project",
      accessType: project?.accessType ?? AccessType.All,
      categories: project?.categories ?? [],
      description: project?.description ?? "",
      type: project?.type ?? ProjectType.Multiple,
      userGroupsIds: project?.userGroupsIds ?? [],
    },
  });

  const formData = watch();

  useEffect(() => {
    const subscription = watch((data) => {
      if (!project || data.name!.length < 5 || data.name!.length > 300) return;
      console.log(data.userGroupsIds);
      const userGroupsIds: string[] = data.userGroupsIds!.map(x => x == undefined ? "" : x);
      const projectToSave: ProjectBean = {
        ...project,
        name: data.name!,
        accessType: data.accessType!,
        categories: data.categories!.filter(
          (category): category is string => category !== undefined,
        ),
        description: data.description!,
        type: data.type!,
        //userGroupsIds: data.userGroupsIds!.filter(() => true),
        userGroupsIds: userGroupsIds,
        configurations: {
          // isOfflineProject: data.offline ?? false,
        },
      };
      mutationUpdateProjectDebounced(projectToSave);
      setChanges(true);
    });
    return () => subscription.unsubscribe();
  }, [watch, project]);

  const [localImagePreview, setLocalImagePreview] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (!project) return;
    setProjectQrCodes(project.qrCodeConfigs ?? []);
    setIsPublished(project.status === Status.Published);
  }, [project]);

  useOutsideClick({
    ref: dropdownRef,
    handler: () => {
      setCategoryMenuOpen(false);
    },
  });
  

  const [isCategoryVisible, setIsCategoryVisible] = useState<boolean>(false);

  const handleAddCategory = useCallback(() => {
    setIsCategoryVisible(true);
  }, []);

  const addCategory = useCallback(() => {
    setValue("categories", [...formData.categories, debouncedNewCategoryName], {
      shouldDirty: true,
    });
    setCategoryMenuOpen(false);
    setNewCategoryName("");
    setIsCategoryVisible(false);
  }, [formData.categories, debouncedNewCategoryName, setValue]);

  const onDeleteCategory = useCallback(
    (name: string) => {
      setValue(
        "categories",
        formData.categories.filter((category) => category !== name),
        { shouldDirty: true },
      );
    },
    [formData.categories, setValue],
  );

  const handleImageUpload = useCallback(
    (file: File) => {
      if (!project) return;
      const previewUrl = URL.createObjectURL(file);
      setLocalImagePreview(previewUrl);
      console.log("Local preview set:", previewUrl);

      mutationUpdateProjectThumbnail.mutate(
        { projectId: project.id!, thumbnail: file },
        {
          onSuccess: (updatedProject) => {
            console.log(
              "Thumbnail updated on server:",
              updatedProject.thumbnailUri,
            );
            showTranslatedToast(
              "Content added",
              `Thumbnail for project ${project.name} was updated`,
              "success",
              language,
            );
            URL.revokeObjectURL(previewUrl);
            setLocalImagePreview(null);
          },
          onError: () => {
            console.error("Failed to upload thumbnail");
            URL.revokeObjectURL(previewUrl);
            setLocalImagePreview(null);
            showTranslatedToast(
              "Error",
              "Could not upload the thumbnail. Please try again.",
              "error",
              language,
            );
          },
        },
      );
    },
    [project, mutationUpdateProjectThumbnail, showTranslatedToast, language],
  );

  const handleDeleteImage = useCallback(() => {
    if (!project) return;
    const updatedProject: ProjectBean = {
      ...project,
      thumbnailObjectId: "",
      thumbnailUri: "",
    };
    mutationUpdateProject.mutate(updatedProject, {
      onSuccess: () => {
        showTranslatedToast(
          "Image deleted",
          "Thumbnail was successfully deleted",
          "success",
          language,
        );
      },
      onError: () => {
        showTranslatedToast(
          "Error deleting image",
          "Could not delete thumbnail. Please try again.",
          "error",
          language,
        );
      },
    });
  }, [project, mutationUpdateProject, showTranslatedToast, language]);

  const loadQrCode = useCallback(() => {
    mutationCreateQRCode.mutate(projectId);
  }, [mutationCreateQRCode, projectId]);

  const downloadQrCode = useCallback((qrCodeUrl: string) => {
    const link = document.createElement("a");
    link.href = qrCodeUrl;
    link.download = "QRCode.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  const copyQrLinkToClipboard = useCallback(
    (qrLink: string) => {
      navigator.clipboard
        .writeText(qrLink)
        .then(() => {
          showTranslatedToast(
            "Copied",
            "QR link copied to clipboard!",
            "success",
            language,
          );
        })
        .catch((err) => {
          console.error("Failed to copy QR link to clipboard", err);
          showTranslatedToast(
            "Error",
            "Failed to copy QR link to clipboard",
            "error",
            language,
          );
        });
    },
    [showTranslatedToast, language],
  );

  if (!project) return null;

  return (
    <Flex direction="column" pt="6" mb="20">
      <Grid
        gridTemplateRows="auto 1fr"
        gap="1"
        alignItems="stretch"
        mt="2"
        width="100%"
      >
        <Flex
          justifyContent="flex-end"
          pb="2"
          color="primary.3"
          alignItems="center"
        >
          {mutationUpdateProject.status === "pending" ? "Saving..." : "Saved"}
        </Flex>

        <GridItem justifySelf="end">
          {/* Temporarily disabled. TODO: enable when needed */}
          <Button variant="outline" onClick={onOpen} display="none">
            Preview
          </Button>

          <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent width="80%" maxWidth="800px" p="10">
              <ModalCloseButton />
              <ModalBody>
                <Flex direction="column" align="center" justify="center">
                  <Text textAlign="center" fontSize="4xl" fontWeight="700">
                    Your Preview is active!
                  </Text>
                  <Text
                    textAlign="center"
                    my="4"
                    fontSize="lg"
                    fontWeight="400"
                  >
                    You can find your project preview in the mobile app. For
                    quick access you can use the QR Code below.
                  </Text>
                  <Box flex="0 0 auto">
                    {projectQrCodes.length === 0 ? (
                      <IconButton
                        aria-label="Add QR code"
                        icon={<IoAddSharp />}
                        variant="outline"
                        size="sm"
                        onClick={loadQrCode}
                      />
                    ) : (
                      projectQrCodes.map((config) => (
                        <Box key={config.id}>
                          <Image
                            src={config.sasUri!}
                            width="200px"
                            height="200px"
                            objectFit="cover"
                          />
                        </Box>
                      ))
                    )}
                  </Box>
                </Flex>
              </ModalBody>
              <ModalFooter justifyContent="center">
                <Button onClick={onClose} px="10">
                  Okay
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <div ref={modalRef}>
            <PublishPopover changes={changes} />
          </div>
        </GridItem>

        <GridItem mb="8">
          <Grid templateColumns="1fr 2fr" gap="10" alignItems="stretch">
            <ThumbnailUploader
              onImageUpload={handleImageUpload}
              currentThumbnail={project.thumbnailUri}
              localImagePreview={localImagePreview}
              onDeleteImage={handleDeleteImage}
            />
            <GridItem>
              <Flex direction="column" gap="4">
                {/* Project Title and Status */}
                <Box>
                  <Input
                    variant="unstyled"
                    fontSize="3xl"
                    fontWeight="bold"
                    placeholder="Title"
                    data-nontranslatable={project.name}
                    {...register("name")}
                  />
                  <Flex alignItems="center" gap="3" mt="2">
                    {!isPublished ? (
                      <Box
                        width="16px"
                        height="16px"
                        borderRadius="full"
                        bg="primary.1"
                      />
                    ) : (
                      <Box
                        width="16px"
                        height="16px"
                        borderRadius="full"
                        bg="green.400"
                      />
                    )}

                    <Text color="primary.4" fontWeight="600" fontSize="md">
                      {project.status === "Published"
                        ? "Published"
                        : project.status === "Draft"
                          ? "Not Published"
                          : null}
                    </Text>
                  </Flex>
                </Box>

                {/* Access Control */}
                <Grid
                  templateColumns="200px minmax(200px, 70%)"
                  alignItems="center"
                >
                  <Text color="primary.4" fontWeight="600" fontSize="md">
                    Access
                  </Text>
                  <Menu>
                    <MenuButton
                      as={Button}
                      variant="outline"
                      rightIcon={<BsChevronDown />}
                      borderRadius="10"
                    >
                      <Flex align="center" gap="2">
                        <Text fontWeight="400" fontSize="sm">
                          {selectedValue}
                        </Text>
                      </Flex>
                    </MenuButton>
                    <MenuList width="100%" style={{ position: "relative" }}>
                      <MenuOptionGroup title="Access">
                        <MenuItem
                            onClick={() => {
                              setValue("accessType", AccessType.All, {
                                shouldDirty: true,
                              });
                              setValue("userGroupsIds", [], {
                                shouldDirty: true,
                              });
                              setArray([]);
                              setSelectedValue("Anyone with the App Link QR Code");
                            }}
                        >
                          <Flex direction="column">
                            <Flex align="center" gap="2">
                              <Text fontSize="sm">
                                Anyone with the App Link QR Code
                              </Text>
                            </Flex>
                          </Flex>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                              setValue("accessType", AccessType.MembersOnly, {
                                shouldDirty: true,
                              });
                              setValue("userGroupsIds", [], {
                                shouldDirty: true,
                              });
                              setArray([]);
                              setSelectedValue("All registered users");
                              //mutationUpdateProjectDebounced(updatedProject);
                              //mutationPublishProject.mutate(updatedProject);
                            }}
                        >
                          <Flex direction="column">
                            <Flex align="center" gap="2">
                              <Text fontSize="sm">All registered users</Text>
                            </Flex>
                          </Flex>
                        </MenuItem>
                      </MenuOptionGroup>
                      { accessGroups.length > 0 ? (
                      <MenuOptionGroup title="User Groups">
                        {accessGroups.map((group, index) => (
                            <MenuItem
                                key={index}
                                onClick={() => {
                                  const groupId = userGroups?.find(
                                      (ug) => ug.name === group,
                                  );
                                  const updatedGroups = [
                                    ...filteredUserGroups,
                                    group,
                                  ];
                                  const ugs = [
                                    ...(project.userGroupsIds || []),
                                    groupId?.id!,
                                  ];
                                  setValue("accessType", AccessType.MembersOnly, {
                                    shouldDirty: true,
                                  });
                                  setValue("userGroupsIds", ugs, {
                                    shouldDirty: true,
                                  });
                                  setArray(updatedGroups);
                                  setSelectedValue(updatedGroups.join("-"));
                                  //mutationPublishProject.mutate(updatedProject);
                                }}
                            >
                              <Flex direction="column">
                                <Flex align="center" gap="2">
                                  <Text fontSize="sm" className="doNotTranslate">{group}</Text>
                                </Flex>
                              </Flex>
                            </MenuItem>
                        ))}
                      </MenuOptionGroup>) : null}
                    </MenuList>
                  </Menu>
                </Grid>

                {/* Project Category */}
                <Grid
                  templateColumns="200px minmax(200px, 70%)"
                  alignItems="center"
                >
                  <Text color="primary.4" fontWeight="600" fontSize="md">
                    Project Category
                  </Text>
                  <Flex ref={dropdownRef} gap={2}>
                    {formData.categories.map((category, i) => (
                      <CustomTag
                        key={i}
                        name={category}
                        menuItem={false}
                        onDelete={onDeleteCategory}
                      />
                    ))}
                    <Menu isOpen={categoryMenuOpen}>
                      <MenuButton width="0px" visibility="hidden" />
                      {isCategoryVisible && (
                        <Input
                          ref={categoryRef}
                          placeholder="Type in a category"
                          value={newCategoryName}
                          width="140px"
                          size="sm"
                          borderRadius="10"
                          onClick={() => setCategoryMenuOpen(true)}
                          onChange={(e) => setNewCategoryName(e.target.value)}
                          onBlur={() => {
                            if (
                              debouncedNewCategoryName &&
                              !formData.categories.includes(
                                debouncedNewCategoryName,
                              )
                            ) {
                              addCategory();
                            } else {
                              setIsCategoryVisible(false);
                            }
                          }}
                        />
                      )}
                      <MenuList>
                        <Text
                          fontSize="xs"
                          color="primary.4"
                          fontWeight="600"
                          ml="4"
                          my="1"
                        >
                          Select or create Category
                        </Text>
                        {debouncedNewCategoryName &&
                          !formData.categories.includes(
                            debouncedNewCategoryName,
                          ) && (
                            <MenuItem onClick={() => addCategory()}>
                              <Flex align="center" gap="2" ml="1" mt="2">
                                <Text
                                  fontSize="xs"
                                  fontWeight="600"
                                  color="primary.4"
                                >
                                  Create
                                </Text>
                                <CustomTag
                                  name={debouncedNewCategoryName}
                                  menuItem={true}
                                />
                              </Flex>
                            </MenuItem>
                          )}
                        {formData.categories.map((category, i) => (
                          <MenuItem key={i}>
                            <Flex align="center">
                              <Flex justify="center">
                                <Icon as={FiMoreVertical} />
                                <Icon as={FiMoreVertical} ml="-2" />
                              </Flex>
                              <CustomTag name={category} menuItem={true} />
                            </Flex>
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                    <IconButton
                      aria-label="Add"
                      size="sm"
                      variant="outline"
                      icon={<IoAddSharp />}
                      onClick={handleAddCategory}
                      borderRadius="10"
                    />
                  </Flex>
                </Grid>

                {/* Description */}
                <Grid templateColumns="200px minmax(200px, 70%)">
                  <Text color="primary.4" fontWeight="600" fontSize="md">
                    Description
                  </Text>
                  <Textarea
                    fontWeight="400"
                    fontSize="sm"
                    placeholder="What is the project about?"
                    {...register("description")}
                  />
                </Grid>

                {/* Navigation Mode (if AnchorType.Image) */}
                {project.anchorType === AnchorType.Image && (
                  <Grid templateColumns="200px minmax(200px, 70%)">
                    <Text color="primary.4" fontWeight="600" fontSize="md">
                      Navigation Mode
                    </Text>
                    <Menu>
                      <MenuButton
                        as={Button}
                        variant="outline"
                        borderRadius="10"
                        rightIcon={<BsChevronDown />}
                      >
                        <Flex align="center" gap="2">
                          <Icon as={BiShuffle} />
                          <Text fontWeight="400" fontSize="sm">
                            {formData.type === ProjectType.Ordered
                              ? "Step by step"
                              : "Random"}
                          </Text>
                        </Flex>
                      </MenuButton>
                      <MenuList width="100%">
                        <MenuItem
                          onClick={() =>
                            setValue("type", ProjectType.Ordered, {
                              shouldDirty: true,
                            })
                          }
                          width="100%"
                        >
                          <Flex direction="column">
                            <Flex align="center" gap="2">
                              <Icon as={IoArrowForwardSharp} />
                              <Text
                                fontSize="sm"
                                fontWeight="600"
                                color="black"
                              >
                                Step by step
                              </Text>
                            </Flex>
                            <Text
                              color="primary.4"
                              fontSize="sm"
                              fontWeight="500"
                            >
                              Users can view chapters only in a certain order
                            </Text>
                          </Flex>
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            setValue("type", ProjectType.Multiple, {
                              shouldDirty: true,
                            })
                          }
                        >
                          <Flex direction="column">
                            <Flex align="center" gap="2">
                              <Icon as={BiShuffle} />
                              <Text
                                fontSize="sm"
                                fontWeight="600"
                                color="black"
                              >
                                Random
                              </Text>
                            </Flex>
                            <Text
                              color="primary.4"
                              fontSize="sm"
                              fontWeight="500"
                            >
                              Users can view chapters in any order
                            </Text>
                          </Flex>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Grid>
                )}

                {/* App Link (if AnchorType.Image) */}
                {project.anchorType === AnchorType.Image && (
                  <Grid templateColumns="200px minmax(200px, 70%)">
                    <Text color="primary.4" fontWeight="600" fontSize="md">
                      App Link
                    </Text>

                    <Flex
                      borderRadius="10"
                      border="1px solid #E2E8F0"
                      p="3"
                      align="center"
                      width="100%"
                      gap="4"
                    >
                      <Box flex="0 0 auto" alignSelf="center">
                        {projectQrCodes.length === 0 ? (
                          <IconButton
                            aria-label="Add QR code"
                            icon={<IoAddSharp />}
                            variant="outline"
                            size={iconSize}
                            borderRadius="10"
                            onClick={loadQrCode}
                          />
                        ) : (
                          projectQrCodes.map((config) => (
                            <Image
                              key={config.id}
                              src={config.sasUri!}
                              width="100px"
                              height="100px"
                              objectFit="cover"
                            />
                          ))
                        )}
                      </Box>

                      {projectQrCodes.length > 0 && (
                        <>
                          <Flex
                            direction={["column", "row"]}
                            alignItems={["stretch", "center"]}
                            gap={4}
                            w="100%"
                          >
                            <Box flex="1" minWidth="0" mr={[0, 4]} mb={[4, 0]}>
                              <Text
                                color="primary.6"
                                fontWeight="600"
                                fontSize="sm"
                              >
                                Download QR Code or copy link
                              </Text>
                              <Text
                                color="primary.4"
                                fontWeight="600"
                                fontSize="sm"
                              >
                                Available for everybody who has access
                              </Text>
                              <Box
                                borderRadius="10"
                                border="1px solid #E2E8F0"
                                p="2"
                                mt="2"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                <Text
                                  color="primary.4"
                                  fontWeight="400"
                                  fontSize="sm"
                                  whiteSpace="nowrap"
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                >
                                  {projectQrCodes[0].qrLink}
                                </Text>
                              </Box>
                            </Box>

                            <Flex
                              justifyContent="center"
                              flexDirection="column"
                              alignItems="center"
                            >
                              {projectQrCodes.map((config) => (
                                <IconButton
                                  key={config.id}
                                  aria-label="Download QR code"
                                  icon={<FiDownload />}
                                  variant="outline"
                                  size={iconSize}
                                  onClick={() =>
                                    config.sasUri &&
                                    downloadQrCode(config.sasUri)
                                  }
                                  mr={2}
                                />
                              ))}
                              <IconButton
                                aria-label="Copy QR code link"
                                icon={<FiCopy />}
                                variant="outline"
                                size={iconSize}
                                onClick={() =>
                                  projectQrCodes[0]?.qrLink &&
                                  copyQrLinkToClipboard(
                                    projectQrCodes[0].qrLink,
                                  )
                                }
                              />
                            </Flex>
                          </Flex>
                        </>
                      )}
                    </Flex>
                  </Grid>
                )}
                {/* The offline options field should not be visible on production till it is fully working:
                <Grid
                  templateColumns="200px minmax(200px, 70%)"
                  alignItems="center"
                >
                  <Text color="primary.4" fontWeight="600" fontSize="md">
                    Offline Option
                  </Text>
                  <Menu>
                    <MenuButton
                      as={Button}
                      variant="outline"
                      rightIcon={<BsChevronDown />}
                      borderRadius="10"
                    >
                      <Flex align="center" gap="2">
                        <Text fontWeight="400" fontSize="sm">
                          {project.configurations?.offline
                            ? "1 Day"
                            : "Never"}
                        </Text>
                      </Flex>
                    </MenuButton>
                    <MenuList width="100%" style={{ position: "relative" }}>
                      <MenuItem
                        onClick={() => {
                          setValue("offline", false, {
                            shouldDirty: true,
                          });
                        }}
                      >
                        <Flex direction="column">
                          <Flex align="center" gap="2">
                            <Text fontSize="sm">Never</Text>
                          </Flex>
                        </Flex>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setValue("offline", true, {
                            shouldDirty: true,
                          });
                        }}
                      >
                        <Flex direction="column">
                          <Flex align="center" gap="2">
                            <Text fontSize="sm">1 Day</Text>
                          </Flex>
                        </Flex>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Grid>*/}
              </Flex>
            </GridItem>
          </Grid>

          {/* <OfflineContentWarning project={project} /> */}
        </GridItem>
        <Chapters />
        <HelpIconButton
          link="https://www.nextcreate.com/help/project-settings"
          isFloating={true}
        />
      </Grid>
    </Flex>
  );
};
