import * as React from "react";
import {
  Button,
  Card,
  Flex,
  Image,
  Grid,
  GridItem,
  Heading,
  Text,
  Box,
  CloseButton,
  useDisclosure,
  CardBody,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../layout/TranslationContext";
import { ProjectCard } from "./components/ProjectCard";
import { Categories } from "./components/Categories";
// @ts-ignore
import QuickstartThumbnail from "./components/Quickstart_thumbnail3small.jpg";
import {
  useMutationCreateProject,
  useProjectCategories,
  useQueryMe,
  useQueryProjects,
} from "state/remoteState";
import { AccessType, AnchorType, ProjectBean, ProjectType } from "api/model";
import { HelpIconButton } from "pages/HelpIconButton";
import { useEffect, useMemo, useState } from "react";
import { ProjectTypeModal } from "./components/ProjectTypeModal";

const STATIC_CATEGORIES = ["All", "Published", "Draft"];

export const Home = () => {
  const {
    isOpen: isNewProjectOpen,
    onOpen: onNewProjectOpen,
    onClose: onNewProjectClose,
  } = useDisclosure();
  const { data: me } = useQueryMe();
  const { data: projects } = useQueryProjects();
  const categories = useProjectCategories();
  const mutationCreateProject = useMutationCreateProject();
  const navigate = useNavigate();
  const [filteredProjects, setFilteredProjects] = React.useState<ProjectBean[]>(
    [],
  );
  const [activeCategory, setActiveCategory] = React.useState("all");
  const { language, setLanguage } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  React.useEffect(() => {
    if (!projects) {
      return;
    }
    setFilteredProjects(projects);
  }, [projects]);

  const [showBanner, setShowBanner] = useState(true);
  const [daysUntilExpiry, setDaysUntilExpiry] = useState<number | null>(null);

  useEffect(() => {
    if (!me) {
      return;
    }
    const expiryDate = new Date(me.subscriptions?.at(0)?.endDate!);
    const currentDate = new Date();
    const days = Math.ceil(
      (expiryDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24),
    );
    if (days <= 14) {
      setDaysUntilExpiry(days);
    }
  }, [me]);

  const handleCloseBanner = () => {
    setShowBanner(false);
  };

  const openAdminPortal = () => {
    window.open("https://admin.nextcreate.com/", "_blank");
  };

  const onCategorySelected = (category: string) => {
    setActiveCategory(category);
    if (!projects) return;

    if (category === "all") {
      setFilteredProjects(projects);
    } else if (category === "published" || category === "draft") {
      setFilteredProjects(
        projects.filter((project) => {
          if (category === "draft") {
            return project.status === "Draft" || project.status === "Created";
          }
          return project.status === "Published";
        }),
      );
    } else {
      setFilteredProjects(
        projects.filter((project) => project.categories?.includes(category)),
      );
    }
  };

  const filteredCategories = useMemo(() => {
    if (!projects) return categories;

    const usedCategories = new Set(
      projects.flatMap((project) => project.categories || []),
    );

    return categories.filter(
      (category) =>
        usedCategories.has(category) || STATIC_CATEGORIES.includes(category),
    );
  }, [projects, categories]);

  function handleCreateProject(anchorType: AnchorType): void {
    const newProject: ProjectBean = {
      type: ProjectType.Multiple,
      name: "Untitled Project",
      subscriptionId: process.env.REACT_APP_subscription_ID,
      editors: [
        {
          name: me?.name,
          email: me?.email,
        },
      ],
      accessType: AccessType.MembersOnly,
      anchorType: anchorType,
    };

    mutationCreateProject.mutate(newProject, {
      onSuccess(data) {
        navigate(`/projects/${data.id}`);
      },
      onError(error) {
        console.error(error);
      },
    });
  }

  const showQuickStartVideoInNewWindow = () => {
    const width = 700;
    const height = 620;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;
    const windowFeatures = `width=${width},height=${height},left=${left},top=${top}`;
    window.open(
      "https://nextcreate.com/hilfe/quickstart-tutorial/",
      "HelpWindow",
      windowFeatures,
    );
  };
  return (
    <>
      <Grid mt="2rem" mb="2rem" gap="8">
        {showBanner && daysUntilExpiry !== null && (
          <Box
            bg="yellow.100"
            px="6"
            py="5"
            mb="4"
            borderRadius="8"
            position="relative"
          >
            <CloseButton
              position="absolute"
              right="4"
              top="4"
              onClick={handleCloseBanner}
            />
            <Flex align="center">
              <Box flex="1">
                <Text fontWeight="bold" fontSize="lg">
                  {daysUntilExpiry <= 0
                    ? "Your subscription has expired!"
                    : `Your subscription will expire in ${daysUntilExpiry} days!`}
                </Text>
                <Text mt={2}>
                  {daysUntilExpiry <= 0
                    ? "ATTENTION: Your subscription has expired and access to your projects will be locked. Please visit your Admin Portal to renew your subscription now if you want to continue using Nextcreate."
                    : "ATTENTION: Your subscription is going to expire soon and access to your projects will be locked. Please visit your Admin Portal to renew your subscription if you want to continue using Nextcreate."}
                </Text>
                <Button colorScheme="yellow" onClick={openAdminPortal} mt={4}>
                  Admin Portal
                </Button>
              </Box>
            </Flex>
          </Box>
        )}
        <GridItem>
          <Heading size="xl">My Projects</Heading>
        </GridItem>
        <GridItem>
          <Grid
            width="100%"
            justifyContent="space-between"
            templateColumns={"1fr 150px"}
            alignItems="center"
          >
            <GridItem>
              <Categories
                categories={filteredCategories}
                onCategorySelected={onCategorySelected}
                activeCategory={activeCategory}
              />
            </GridItem>
            <Flex width="100%" justifyContent="flex-end">
              <Button onClick={onNewProjectOpen} width="152px">
                New Project
              </Button>
            </Flex>
          </Grid>
        </GridItem>
        <GridItem>
          <Grid templateColumns="repeat(auto-fill, minmax(266px,1fr))" gap="8">
            <Card
              _hover={{
                cursor: "pointer",
                transform: "scale(1.05)",
                transition: "transform .2s ease-in-out",
                zIndex: "9999",
              }}
              borderRadius="8"
              height="350px"
              width="100%"
              backgroundColor="#FAFAFA"
              position="relative"
              onClick={showQuickStartVideoInNewWindow}
            >
              <Image
                src={QuickstartThumbnail}
                alt="Card Image"
                objectFit="cover"
                height="50%"
                width="100%"
              />
              <CardBody>
                <Flex direction="column" gap="2">
                  <Text
                    fontSize="xl"
                    mt="5"
                    fontWeight="bold"
                    data-nontranslatable="Quickstart Video Tutorial"
                  >
                    Quickstart Video Tutorial
                  </Text>
                  <Text fontSize="md" mt="-2">
                    Das Wichtigste in 5 Minuten!
                  </Text>
                </Flex>
              </CardBody>
            </Card>

            {filteredProjects.map((project, i) => {
              return (
                <ProjectCard
                  data-nontranslatable={`${project.name} ${project.description}`}
                  key={i}
                  // authors={post.subscription.subscriptionName}
                  project={project}
                  type={project.type}
                  description={project.description ?? ""}
                  published={project.status === "Published"}
                  title={project.name}
                  users={
                    project.editors?.map(
                      (editor) =>
                        editor.name ?? editor.email ?? "Unknown editor",
                    ) ?? []
                  }
                  accessType={project.accessType}
                  id={project.id ?? ""}
                  thumbnail={project.thumbnailUri ?? ""}
                />
              );
            })}
          </Grid>
        </GridItem>
      </Grid>
      <ProjectTypeModal
        isOpen={isNewProjectOpen}
        onClose={onNewProjectClose}
        handleCreateProject={handleCreateProject}
      />

      <HelpIconButton
        link="https://nextcreate.com/hilfe/projektuebersicht/"
        isFloating={true}
      />
    </>
  );
};
