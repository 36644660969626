import { FC, useRef, useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { Content, ContentType } from "api/model";
import { CreateOrUpdateContentProps } from "../DialogCreateOrUpdateContent";
import { useTranslation } from "../../../../../../../layout/TranslationContext";
import { translatePage } from "../../../../../../../layout/translatePage";
import { ResourceLibrary } from "../ResourceLibrary";
import { ResourceCategory } from "pages/Library/resourceCategoryDetails";
import { TabSubtitlesUpload } from "./TabSubtitlesUpload";

export const contentEditorSubtitlesSupportedContentTypes = [
  ContentType.Subtitles,
];

export const DialogContentEditorSubtitles: FC<CreateOrUpdateContentProps> = (
  props,
) => {
  const onClose = props.onClose;
  const content = props.content ?? ({ type: ContentType.Subtitles } as Content);
  const mode = props.content ? "update" : "create";
  const { language, setLanguage } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  useEffect(() => {
    setModalIsOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    if (modalIsOpen && modalRef.current) {
      translatePage(modalRef.current, language);
    }
  }, [modalIsOpen, language]);

  return (
    <Modal {...props} size="3xl" initialFocusRef={modalRef}>
      <ModalOverlay />
      <ModalContent p="2" ref={modalRef}>
        <ModalHeader fontSize="36px" fontWeight="700" pt="10">
          {mode === "create" ? "Add Subtitle" : "Update Subtitle"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs variant="soft-rounded" defaultIndex={0}>
            <TabList
              backgroundColor="primary.0"
              p="1"
              borderRadius="50"
              gap="4"
              width="fit-content"
              height="40px"
            >
              <Tab
                _selected={{ backgroundColor: "primary.6", color: "white" }}
                width="150px"
              >
                Upload
              </Tab>
              <Tab
                _selected={{ backgroundColor: "primary.6", color: "white" }}
                width="150px"
              >
                Library
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel px="0" py="6">
                <TabSubtitlesUpload {...props} />
              </TabPanel>
              <TabPanel px="0" py="6">
                <ResourceLibrary
                  content={content}
                  category={ResourceCategory.Subtitles}
                  onSuccess={onClose}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
