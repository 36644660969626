import { AnchorType, ContentType } from "api/model";
import { BiCube } from "react-icons/bi";
import { BsImage } from "react-icons/bs";
import { IoTextSharp } from "react-icons/io5";
import {
  MdLibraryMusic,
  MdOutlineQuestionMark,
  MdOutlineVideoLibrary,
} from "react-icons/md";
import { IconType } from "react-icons";

export enum ResourceCategory {
  All = "All",
  Images = "Images",
  AudioFiles = "Audio Files",
  Videos = "Videos",
  ThreeDObjects = "3D Objects",
  Subtitles = "Subtitles",
  SpatialAnchors = "Spatial Anchors",
  Url = "Url",
}

interface ResouceCategoryDetails {
  category: ResourceCategory;
  icon: IconType;
  extensions: string[];
  contentOrAnchorType: ContentType | AnchorType | undefined;
}

export const ResourceCategoryData: Record<
  ResourceCategory,
  ResouceCategoryDetails
> = {
  [ResourceCategory.All]: {
    category: ResourceCategory.All,
    icon: MdOutlineQuestionMark,
    extensions: [],
    contentOrAnchorType: undefined,
  },
  [ResourceCategory.Images]: {
    category: ResourceCategory.Images,
    icon: BsImage,
    extensions: [".jpg", ".png", ".jpeg", ".gif"],
    contentOrAnchorType: ContentType.Image,
  },
  [ResourceCategory.AudioFiles]: {
    category: ResourceCategory.AudioFiles,
    icon: MdLibraryMusic,
    extensions: [".mp3", ".wav", ".flac", ".m4a"],
    contentOrAnchorType: ContentType.Audio,
  },
  [ResourceCategory.Videos]: {
    category: ResourceCategory.Videos,
    icon: MdOutlineVideoLibrary,
    extensions: [".mp4", ".avi", ".mkv", ".webm"],
    contentOrAnchorType: ContentType.Video,
  },
  [ResourceCategory.ThreeDObjects]: {
    category: ResourceCategory.ThreeDObjects,
    icon: BiCube,
    extensions: [".obj", ".fbx", ".gltf", ".glb"],
    contentOrAnchorType: ContentType.Value3DModel,
  },
  [ResourceCategory.Subtitles]: {
    category: ResourceCategory.Subtitles,
    icon: IoTextSharp,
    extensions: [".srt", ".vtt"],
    contentOrAnchorType: ContentType.Subtitles,
  },
  [ResourceCategory.SpatialAnchors]: {
    category: ResourceCategory.SpatialAnchors,
    icon: BiCube,
    extensions: ["plane"],
    contentOrAnchorType: AnchorType.Environment,
  },
  [ResourceCategory.Url]: {
    category: ResourceCategory.Url,
    icon: IoTextSharp,
    extensions: [".website"],
    contentOrAnchorType: ContentType.UrlEndpoint,
  },
};

export const allSupportedExtensions = Object.values(ResourceCategoryData)
  .map((details) => details.extensions)
  .flat();

export const resourceCategoryDetailsByContentType = (
  contentType: ContentType,
): ResouceCategoryDetails => {
  const categoryDetails = Object.values(ResourceCategoryData).find(
    (details) => details.contentOrAnchorType === contentType,
  );
  if (categoryDetails) {
    return categoryDetails;
  }
  return ResourceCategoryData[ResourceCategory.All];
};

export const resourceCategoryDetailsByExtension = (
  extension: string | undefined | null,
): ResouceCategoryDetails => {
  if (!extension) {
    return ResourceCategoryData[ResourceCategory.All];
  }

  const categoryDetails = Object.values(ResourceCategoryData).find((details) =>
    details.extensions.includes(extension.toLowerCase()),
  );

  return categoryDetails || ResourceCategoryData[ResourceCategory.All];
};
