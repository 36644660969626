import { ResourceCategory } from "pages/Library/resourceCategoryDetails";
import { Anchor, ContentLibraryBean } from "api/model";
import { FC, useState } from "react";
import { Button, Flex, Spacer, useToast } from "@chakra-ui/react";
import {
  useMutationCreateAnchor,
  useMutationUpdateAnchor,
} from "state/remoteState";
import { useProjectChapterId } from "state/localState";
import { initialConfigurationsFromResource } from "api/Configurations";
import { ResourceLibrarySelector } from "pages/Library/components/ResourceLibrarySelector";

interface ResourceLibraryProps {
  anchor: Anchor;
  category: ResourceCategory;
  onSuccess: () => void;
}

export const ResourceLibrary: FC<ResourceLibraryProps> = ({
  anchor,
  category,
  onSuccess,
}) => {
  const mutationCreateAnchor = useMutationCreateAnchor();
  const mutationUpdateAnchor = useMutationUpdateAnchor();
  const { projectId, chapterId } = useProjectChapterId();
  const toast = useToast();

  const [resource, setResource] = useState<ContentLibraryBean>();

  const mode = anchor.id ? "update" : "create";
  const handleSelect = (resource: ContentLibraryBean) => {
    setResource(resource);
  };

  const handleCreateAnchor = async () => {
    if (!resource) {
      return;
    }

    let configurations = await initialConfigurationsFromResource(resource);

    mutationCreateAnchor.mutate(
      {
        projectId,
        chapterId,
        anchor: {
          ...anchor,
          name: resource.name,
          sourceObjectId: resource.id,
          configurations: { ...anchor.configurations, ...configurations },
        },
      },
      {
        onSuccess: () => {
          toast({
            status: "success",
            title: "Marker created.",
            description: `Marker "${anchor.name ?? anchor.description ?? resource.name ?? resource.filename ?? "unnamed"}" has been created.`,
          });
          onSuccess();
        },
      },
    );
  };

  const handleUpdateMarker = async () => {
    console.log("Updating marker", anchor);
    if (!resource) {
      return;
    }
    // Fetch the image from the server using resource.sasUri, create a hiddent image element and set the src to the sasUri
    // then calculate size x: img.naturalWidth, y: img.naturalHeight amd calculate configurations using the initial2DConfigurations(size).

    let configurations = await initialConfigurationsFromResource(resource);

    mutationUpdateAnchor.mutate(
      {
        projectId,
        chapterId,
        anchor: {
          ...anchor,
          name: resource.name,
          sourceObjectId: resource.id,
          configurations: { ...anchor.configurations, ...configurations },
        },
      },
      {
        onSuccess: () => {
          toast({
            status: "success",
            title: "Marker updated.",
            description: `Marker "${anchor.name ?? anchor.description ?? resource.name ?? resource.filename ?? "unnamed"}" has been updated.`,
          });
          onSuccess();
        },
      },
    );
  };

  return (
    <>
      <ResourceLibrarySelector category={category} onSelect={handleSelect} />
      <Flex width="100%" justifyContent="flex-end" mt="4">
        <Spacer />
        {mode === "create" && (
          <Button
            isDisabled={!resource}
            _hover={{ backgroundColor: "primary.3" }}
            variant="primary"
            mt="4"
            onClick={handleCreateAnchor}
            isLoading={mutationCreateAnchor.isPending}
          >
            Create Marker
          </Button>
        )}
        {mode === "update" && (
          <Button
            isDisabled={!resource}
            _hover={{ backgroundColor: "primary.3" }}
            variant="primary"
            mt="4"
            onClick={handleUpdateMarker}
            isLoading={mutationCreateAnchor.isPending}
          >
            Update Marker
          </Button>
        )}
      </Flex>
    </>
  );
};
