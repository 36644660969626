import React, { useEffect, useRef } from "react";
import { Navigator } from "layout/Navigator";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  GridItem,
  Flex,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  useBreakpointValue,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { useQueryMe } from "state/remoteState";
import { MdTranslate, MdLogout } from "react-icons/md";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "./TranslationContext";
import { translatePage } from "./translatePage";

export const Layout: React.FC = () => {
  const { data: user } = useQueryMe();
  const { instance } = useMsal();
  const { language, setLanguage } = useTranslation();
  const hasTranslated = useRef(false);
  const marginX = useBreakpointValue({ base: "20px", md: "30px" });

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  useEffect(() => {
    const sessionKey = `translated_${language}`;
    if (!sessionStorage.getItem(sessionKey)) {
      translatePage(document.body, language);
      sessionStorage.setItem(sessionKey, "true");
      hasTranslated.current = true;
    }
  }, [language]);

  const handleLanguageChange = async (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const newLanguage = e.target.value;
    setLanguage(newLanguage);
    await translatePage(document.body, newLanguage);
    localStorage.setItem("language", newLanguage);
    sessionStorage.setItem(`translated_${newLanguage}`, "true");
  };

  if (!user) {
    return null;
  }

  return (
    <Grid h="100%" templateColumns={"auto 1fr"}>
      <GridItem bgColor="primary.1">
        <Navigator />
      </GridItem>
      <GridItem>
        <Flex
          justify="flex-end"
          align="center"
          px={marginX}
          py="4"
          minHeight="60px"
        >
          <Popover>
            <PopoverTrigger>
              <Avatar
                data-nontranslatable={user.name}
                name={user.name ?? ""}
                width="40px"
                height="40px"
                cursor="pointer"
              />
            </PopoverTrigger>
            <PopoverContent
              borderRadius="8"
              p="2"
              boxShadow="md"
              style={{ zIndex: 1100 }}
            >
              <PopoverCloseButton />
              <PopoverBody fontWeight="600">
                <HStack>
                  <Avatar
                    data-nontranslatable={user.name}
                    name={user.name ?? ""}
                    size="lg"
                    mr="2"
                  />
                  <VStack align="start">
                    <Grid templateRows="repeat(2, auto)" rowGap="0">
                      <Box
                        fontWeight="700"
                        fontSize="lg"
                        data-nontranslatable={user.name}
                        className="doNotTranslate"
                      >
                        {user.name}
                      </Box>
                      <Box
                        data-nontranslatable={user.email}
                        className="doNotTranslate"
                      >
                        {user.email}
                      </Box>
                    </Grid>
                  </VStack>
                </HStack>
                <VStack mt="4" spacing="3" align="start">
                  <Box>Language</Box>
                  <HStack spacing="2">
                    <MdTranslate />
                    <select value={language} onChange={handleLanguageChange}>
                      <option value="es" data-nontranslatable="Spanish">
                        Spanish
                      </option>
                      <option value="fr" data-nontranslatable="French">
                        French
                      </option>
                      <option value="de" data-nontranslatable="German">
                        German
                      </option>
                      <option value="en" data-nontranslatable="English">
                        English
                      </option>
                      <option value="it" data-nontranslatable="Italian">
                        Italian
                      </option>
                    </select>
                  </HStack>
                  <Divider />
                  <HStack spacing="2">
                    <MdLogout />
                    <Box
                      as="button"
                      fontWeight="600"
                      _hover={{ color: "primary.3" }}
                      cursor="pointer"
                      onClick={() =>
                        instance.logoutRedirect({ postLogoutRedirectUri: "/" })
                      }
                    >
                      Logout
                    </Box>
                  </HStack>
                </VStack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
        <Divider color="gray.300" />
        <Box px={marginX}>
          <Outlet />
        </Box>
      </GridItem>
    </Grid>
  );
};
