import { FC, useMemo, useState } from "react";
import { useQueryResources } from "state/remoteState";
import {
  ResourceCategory,
  resourceCategoryDetailsByExtension,
} from "pages/Library/resourceCategoryDetails";
import { Flex, Grid, Icon, Text, Box, Spinner } from "@chakra-ui/react";
import { ContentLibraryBean } from "api/model";

type ResourceLibrarySelectorProps = {
  category: ResourceCategory;
  onSelect: (resource: ContentLibraryBean) => void;
};

export const ResourceLibrarySelector: FC<ResourceLibrarySelectorProps> = ({
  category,
  onSelect,
}) => {
  const { data, isLoading } = useQueryResources();
  const [resource, setResource] = useState<ContentLibraryBean>();

  const resources = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.filter(
      (item) =>
        resourceCategoryDetailsByExtension(item.filetype?.toLowerCase())
          .category === category,
    );
  }, [data]);

  const handleSelect = (resource: ContentLibraryBean) => {
    setResource(resource);
    onSelect(resource);
  };

  return (
    <>
      <Flex
        direction="column"
        align="center"
        w="100%"
        maxHeight="234px"
        overflowY="auto"
      >
        {isLoading ? (
          <Flex h="250px" justifyContent="center" alignItems="center">
            <Spinner
              thickness="5px"
              speed="1.5s"
              emptyColor="gray.200"
              color="primary.4"
              size="xl"
              opacity="0.8"
            />
          </Flex>
        ) : (
          <Grid templateColumns="repeat(5, 1fr)" gap="2" w="100%">
            {resources.map((item, index) => (
              <Box
                key={index}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                w="100%"
                h="100px"
                borderRadius="8"
                border={
                  item.id === resource?.id
                    ? "2px solid black"
                    : "1px solid  #E2E8F0"
                }
                padding="10px"
                maxWidth="120px"
                onClick={() => handleSelect(item)}
              >
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  backgroundColor="primary.0"
                  w="100%"
                  h="82px"
                  mb={1}
                >
                  <Preview resource={item} category={category} />
                </Flex>
                <Text
                  fontWeight="400"
                  fontSize="xs"
                  color="primary.4"
                  textAlign="center"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  maxWidth="100%"
                >
                  {item.name}
                </Text>
              </Box>
            ))}
          </Grid>
        )}
      </Flex>
    </>
  );
};

import { Image } from "@chakra-ui/react";

const Preview: FC<{
  resource: ContentLibraryBean;
  category: ResourceCategory;
}> = ({ resource, category }) => {
  if (category === ResourceCategory.Images) {
    return (
      <Image
        src={resource.sasUri ?? undefined}
        alt={resource.name ?? resource.filename ?? "Image"}
        objectFit="cover"
        w="100%"
        h="100%"
        loading="lazy"
        fallback={
          <Icon
            as={
              resourceCategoryDetailsByExtension(
                resource.filetype?.toLowerCase(),
              ).icon
            }
            color="primary.6"
            w="22px"
            h="22px"
          />
        }
      />
    );
  } else {
    const icon = resourceCategoryDetailsByExtension(
      resource.filetype?.toLowerCase(),
    ).icon;
    return <Icon as={icon} color="primary.6" w="22px" h="22px" />;
  }
};
